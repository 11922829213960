import { useEffect, useState } from 'react';
import uuidv1 from 'uuid/v1';


const listeners = {}
const que = []
let downloadingCount = 0
const QUE_SIZE = 30;

function addUrlListener(listener): void {
    const id = uuidv1();
    que.push(id);
    listeners[id] = listener;
    updateQue();
}

function updateQue(): void {
    if (QUE_SIZE > downloadingCount && que.length > 0) {
        downloadingCount = downloadingCount + 1
        notifyListeners(que[0])
        que.splice(0, 1);
        updateQue();
    }
}

function notifyListeners(id): void {
    listeners[id]()
}

function downloadDone(): void {
    downloadingCount = downloadingCount - 1;
    updateQue();
}

export function useUrlQueForDownload(url: string): [string, () => void] {
    const [resultUrl, setResultUrl] = useState<string>('');

    useEffect(() => {
        addUrlListener(() => {
            setResultUrl(url);
        });
        return () => {
            downloadingCount = 0;
            que.splice(que.indexOf(url), 1);
        }
    }, [])

    return [resultUrl, () => {
        downloadDone()
    }]
}
