/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 49.2.0 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * PEDING
 * @export
 * @interface ImageFileResource
 */
export interface ImageFileResource {
    /**
     * 
     * @type {string}
     * @memberof ImageFileResource
     */
    image_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageFileResource
     */
    mime_type: string;
    /**
     * 
     * @type {number}
     * @memberof ImageFileResource
     */
    size: number;
}

export function ImageFileResourceFromJSON(json: any): ImageFileResource {
    return ImageFileResourceFromJSONTyped(json, false);
}

export function ImageFileResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageFileResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image_url': json['image_url'],
        'mime_type': json['mime_type'],
        'size': json['size'],
    };
}

export function ImageFileResourceToJSON(value?: ImageFileResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image_url': value.image_url,
        'mime_type': value.mime_type,
        'size': value.size,
    };
}


