import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { FILTERS_MINUS_DATES as allowedFilters } from '../../../base/config';
import { getLocationEntries } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setFiltersVisible } from '../filtersActions';
import { getFiltersVisibleSelector } from '../selectors';

type SelectedFiltersProps = {
    hideEmpty?: boolean;
    showCompact?: boolean;
    hideDate?: boolean;
};

const SelectedFilters = (props: SelectedFiltersProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const showAll = useSelector(getFiltersVisibleSelector);
    const toggleFilters = (): void => {
        dispatch(setFiltersVisible(!showAll));
    };
    const currentFilters = getLocationEntries(location);
    const { hideEmpty = true, showCompact = true, hideDate = false } = props;
    if (currentFilters) {
        const filterTypes = Object.keys(currentFilters).filter((key) => {
            return hideDate ? !key.includes('date') && allowedFilters.includes(key) : allowedFilters.includes(key);
        });
        if (filterTypes.length > 0) {
            return <div onClick={(): void => toggleFilters()} className='flex space-x-2 md:w-auto justify-end p-3 md:p-2 text-base md:text-base bg-surface-plain self-end md:self-center rounded-bl md:rounded-none'>
                {filterTypes.length > 0 && <div className='label link'>{filterTypes.length === 1 ?
                    <FormattedMessage id='filters.selected_filter.label.active_filter' /> : <FormattedMessage id='filters.selected_filter.label.active_filters' />}:</div>}
                {filterTypes.length === 1 && <div><FormattedMessage id={`filters.selected_filter.${filterTypes[0]}`} /></div>}
                {!showCompact && filterTypes.length > 1 && filterTypes.map((t) => <div key={t}><FormattedMessage id={`filters.selected_filter.${t}`} /></div>)}
                {showCompact && filterTypes.length > 1 && <Popup trigger={<span><FormattedMessage id={`filters.selected_filter.${filterTypes[0]}`} /><span className='plusSome'> + {filterTypes.length - 1}</span></span>}>
                    <Popup.Header><FormattedMessage id={'filters.popup.header.selected_filters'} /> ({filterTypes.length})</Popup.Header>
                    <Popup.Content>
                        {filterTypes.map((l: any, i: 0) => {
                            return <div key={i}><FormattedMessage id={`filters.selected_filter.${filterTypes[i]}`} /></div>;
                        })}
                    </Popup.Content>
                </Popup>}
            </div>;
        }
    }
    return hideEmpty ? null : <div className='flex space-x-2 items-center md:w-auto justify-end p-3 md:p-2 text-lg md:text-base bg-surface-plain '><div className='link' onClick={(): void => toggleFilters()}><FormattedMessage id='filters.action.toggle_filters' /></div></div>;
};

export default SelectedFilters;
