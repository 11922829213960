import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form, Modal, ModalHeader, Radio, TextArea } from 'semantic-ui-react';
import uuidv1 from 'uuid/v1';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import Button from '../../../base/components/basic/Button';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getProductionUnitStatusList, getProductionUnitStatusListSelector } from '../slice/productionUnitsSlice';
import { DetailedProductionUnitStatus } from '../../../backend_api/models';


type Props = {
    status: DetailedProductionUnitStatus;
    className?: string;
    trigger: React.ReactElement | string;
    commentMandatory?: boolean;
    handleUpdate?(statusId: string, comment: string): void;
    disabled?: boolean;
};

const ProductionUnitChangeStatusDialog = (props: Props): React.ReactElement => {
    const { trigger, handleUpdate, commentMandatory = true, disabled = false } = props;
    const modalOpen = useSelector(getShowModalSelector);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [status, setStatus] = useState<DetailedProductionUnitStatus>(props.status);
    const [comment, setComment] = useState<string>(undefined);
    const [modalId] = useState<string>('pucd_' + uuidv1());
    const [commentSet, setCommentSet] = useState(false);
    const statusList = useSelector(getProductionUnitStatusListSelector);

    const close = (): void => {
        setStatus(props.status);
        setComment('');
        setCommentSet(false);
        dispatch(showModal({ show: false, id: modalId }));
    };
    const save = () => {
        handleUpdate(status.id, comment);
        close();
    };
    const isDisabled = () => {
        if (commentMandatory && !commentSet) {
            return true;
        }
        return false;
    };

    const updateComment = (comment: string) => {
        setComment(comment);
        setCommentSet(!isUndefinedOrNullOrEmptyString(comment));
    };

    useEffect(() => {
        isDisabled();
        setStatus(props.status);
    }, [props.status]);

    useEffect(() => {
        dispatch(getProductionUnitStatusList());
    }, []);

    return <Modal
        trigger={trigger}
        onOpen={() => dispatch(showModal({ show: true, id: modalId }))}
        onClose={() => close()}
        closeIcon={true}
        open={!disabled && modalOpen[modalId] && modalOpen[modalId].show}
    >
        <ModalHeader><FormattedMessage id='production_units.set_status_dialog.header' /></ModalHeader>
        <Modal.Content>
            <div className='flex-col flex  space-y-8'>
                <div className='flex-col w-full'>
                    <h5><FormattedMessage id='production_units.set_status_dialog.list_header' /></h5>
                    {statusList.length > 0 && statusList.map((_status) => {
                        return <div key={'auditConclusionDialog_' + _status.id} className='flex items-center space-x-2 my-3 cursor-pointer' onClick={() => setStatus(_status)}>
                            <Radio className='self-start pt-2' checked={status && status.id === _status.id} />
                            <div className='w-2/12 p-2 rounded text-sm justify-center self-start items-baseline flex' style={{ backgroundColor: _status.background_color, color: _status.text_color }}>{_status.name.text}</div>
                        </div>
                    })}
                </div>
                <div className='w-2/3'>
                    <h5 className='space-x-1'><span><FormattedMessage id='audits.change_conclusion.label.reason' /></span>{commentMandatory && <span className='text-alert'>*</span>}</h5>
                    <Form>
                        <TextArea rows={5} name='reason' value={comment} onChange={(evt, item): void => updateComment(item.value as string)} placeholder={intl.formatMessage({ id: 'audits.change_conclusion.label.enter_text' })} ></TextArea>
                    </Form>
                </div>
            </div>
        </Modal.Content>
        <Modal.Actions className='flex justify-end'>
            <Button onClick={() => save()} disabled={isDisabled()} primary><FormattedMessage id='globals.save' /></Button>
            <Button onClick={() => close()}><FormattedMessage id='globals.cancel' /></Button>
        </Modal.Actions>
    </Modal >
}
export default ProductionUnitChangeStatusDialog;
