import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import uuid from 'uuid/v4';
import { BasicUser } from '../../../backend_api_2';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import { Sizes } from '../../../base/components/types';
import Button from '../../../base/ui/components/buttons/Button';
import Icon from '../../../base/ui/components/icons/Icon';
import { ListItem, ListItems } from '../../../base/ui/components/listitems/ListItem';
import ComponentsSeparatedBy from '../../../base/ui/components/util/ComponentsSeparatedBy';
import { getLocationEntries, preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import { OrganizedProductRequiredDocument, OrganizedProductRequiredDocumentStateEnum } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import UserPopup from '../../users/components/UserPopup';
import { CreateNewDocumentType, complianceClearDocument, complianceCreateDocument, getComplianceDetailedDocument } from '../complianceSlice';
import { getComplianceDetailedDocumentSelector } from '../selectors/ComplianceSelectors';
import ComplianceIgnoreRequirement from './ComplianceIgnoreRequirement';
import ComplianceProductRequirementDocumentState from './ComplianceProductRequirementDocumentState';
import ComplianceDocumentViewer from './document-viewer/ComplianceDocumentViewer';

type Props = {
    className?: string;
    requiredDocument: OrganizedProductRequiredDocument;
    requirementId: string;
    productId: string;
    supplierId: string;
    orderId: string;
    index: number;
};

const ComplianceProductRequirementDocument = (props: Props): React.ReactElement => {
    const { className, requiredDocument, productId, supplierId, requirementId, index } = props;
    const location = useLocation();
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const _d = useSelector(getComplianceDetailedDocumentSelector);
    const [document, setDocument] = useState(_d);
    const [modalId, setModalId] = useState(requiredDocument.deadline_id + index);
    const [load, setLoad] = useState(false);
    const [showDocument, setShowDocument] = useState(false);
    const modalData = useSelector(getShowModalSelector);
    const modal = modalData && modalData['compliance_document_viewer_' + modalId]
    const modalOpen = modal && modal.show || false;
    const isIgnored = requiredDocument.document_type === 'ignored';
    const documentIdParam = getLocationEntries(location).docId && getLocationEntries(location).docId[0] || null;
    const andLabel = intl.formatMessage({ id: 'globals.and_lower_case' });

    useEffect(() => {
        setDocument(_d);
    }, [_d]);

    useEffect(() => {
        // console.log('index ', index, documentIdParam, requiredDocument.document_id)
        /* if (documentIdParam && (modal && modal.data && modal.data !== requiredDocument.deadline_id) && documentIdParam === requiredDocument.document_id) {
            getDocument(documentIdParam);
        } */
    }, [index])
    const getDocument = (id: string) => {
        console.log('getDocument ', id)
        if (!modalOpen) {
            dispatch(showModal({ id: 'compliance_document_viewer_' + modalId, show: true, data: requiredDocument.deadline_id }))
        }

        let _id;
        if (id) {
            _id = id;
            dispatch(getComplianceDetailedDocument({ id }));
        } else {
            const newId = uuid();
            _id = newId;
            let timeframe;
            let applies_to;
            let order_id;
            if (requiredDocument.required_of.type === 'required_of_supplier') {
                applies_to = {
                    supplier_id: requiredDocument.required_of.supplier_id,
                    type: 'document_applies_to_suppliers',
                }
            }
            if (requiredDocument.required_of.type === 'required_of_product') {
                applies_to = {
                    product_id: requiredDocument.required_of.product_id,
                    type: 'document_applies_to_product',
                }
            }
            if (requiredDocument.required_at.type === 'order') {
                order_id = requiredDocument.required_at.order_id;
                timeframe = {
                    type: 'time_frame_order',
                    order_id,
                }
            }
            if (requiredDocument.required_at.type === "no_expiration") {
                timeframe = {
                    type: 'time_frame_no_expiration',
                    from: null
                }
            }
            if (requiredDocument.required_at.type === 'expiration_from_file') {
                timeframe = {
                    type: 'time_frame_interval',
                    from: null,
                    until: null,
                }
            }
            const req: CreateNewDocumentType = {
                applies_to,
                document_id: newId,
                document_specification_id: requiredDocument.document_template_id,
                requirement_specification_id: requiredDocument.requirement_id,
                supplier: {
                    supplier_id: supplierId,
                    type: 'supplier',
                },
                productId,
                timeframe,
            }
            dispatch(complianceCreateDocument(req));
            // return newId;
        }
        return _id;
    }

    const closeDocument = () => {
        dispatch(complianceClearDocument());
        dispatch(showModal({ id: 'compliance_document_viewer_' + modalId, show: false }))
        setDocument(null);
        setShowDocument(false);
        // history.push(location.pathname);
    }

    const displayIgnoreRequirement = () => {
        dispatch(showModal({ id: 'complianceIgnoreRequirement', show: true, data: { requirementId: requiredDocument.requirement_id } }));
    }
    const dv = useMemo(() => {
        return <ComplianceDocumentViewer
            documentId={requiredDocument.document_id}
            productId={productId}
            products={null}
            document={document}
            name={requiredDocument.name}
            show={(modalOpen)}
            onClose={closeDocument}
            orderId={props.orderId}
        />
    }, [document, modalData]);
    const actionClass = 'w-[105px] py-2 px-2 text-sm items-center justify-center ';
    return <div className={twMerge('', className)}>
        {dv}
        <ListItems>
            <ListItem className='cursor-pointer flex'
                onClick={(e) => {
                        if (isIgnored) {
                            displayIgnoreRequirement();
                            return false;
                        }
                        const id = getDocument(requiredDocument.document_id);
                        history.push('?docId=' + id);
                        preventDefaultAndStopPropagation(e);
                    }}
                icon={<ComplianceProductRequirementDocumentState state={requiredDocument.state} hasFiles={requiredDocument.document_has_files} isIgnored={isIgnored}/>}>
                <div className='flex w-full'>
                    <div className='w-5/12 flex items-center'>
                        {requiredDocument.name?.text}
                    </div>
                    <div className='w-5/12  flex flex-col'>
                        <div>{requiredDocument.responsible.supplier?.supplier_name}</div>
                        <ComponentsSeparatedBy components={requiredDocument.responsible.users.map((user) => <UserPopup user={user as BasicUser} />)} separateBy=',' endWithLabel={andLabel} />
                    </div>
                    <div className='w-2/12 h-f flex text-sm justify-end items-center'>
                    {!isIgnored && <>
                        {requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.ReadyForApproval && <Button context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.review' /></Button>}
                        {requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval && !requiredDocument.document_has_files && <Button context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.add_files' /></Button>}
                        {requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval && requiredDocument.document_has_files && <Button context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.submit' /></Button>}
                        {requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.Approved && <span className={twMerge(actionClass, 'flex gap-1 text-secondary')}><Icon name='status_approved-circle' size={Sizes.XSmall} /><FormattedMessage id='compliance.requirement_document.state.done' /></span>}
                        {requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.Rejected && <Button context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.replace_files' /></Button>}
                        </>}
                    </div>
                    {/* !isIgnored && <div className='border-l-1'
                            onClick={(e) => preventDefaultAndStopPropagation(e)}>
                            <ContextMenu
                                trigger={<Button ghost className='p-1 outline-none'><Icon name='microinteraction_context-menu' /></Button>}
                                overflowItems={[{
                                    label: 'Ignore requirement',
                                    icon: 'toggle_visibility-off',
                                    onPress: displayIgnoreRequirement,
                                }]}
                            />
                        </div> */}
                        <ComplianceIgnoreRequirement
                            products={[]}
                            supplierId={supplierId}
                            documentSpecificationId={requiredDocument && requiredDocument.document_template_id}
                            documentName={requiredDocument.name.text}
                            isIgnored={isIgnored}
                        />
                </div>
            </ListItem>
        </ListItems>
    </div >;
}
export default ComplianceProductRequirementDocument;
