import { DocRenderer } from '@cyntler/react-doc-viewer';
import React, { useState } from 'react';
import { Page, Document as PdfDocument, pdfjs } from 'react-pdf';
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';

export const PdfRenderer: DocRenderer = ({
    mainState: { currentDocument, rendererRect },
}) => {
    const Transform = (item) => {
        return (
            <TransformWrapper
                initialScale={1}
            /* initialPositionX={200}
            initialPositionY={100} */
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                        <TransformComponent>
                            {item}
                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
        );
    };


    const [numPages, setNumPages] = useState<number>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [dimensions, setDimensions] = useState(null);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <PdfDocument className={''} file={currentDocument.uri} onLoadSuccess={onDocumentLoadSuccess} onSourceSuccess={() => console.log('loaded pdf succesfully ', rendererRect)} >
            {[...Array(numPages).keys()].map(i => {
                return <><div className='bg-yellow-200 p-1 text-xs inline rounded'>Page {i + 1}</div><Page pageNumber={i + 1} className={''} width={rendererRect.width} /></>
            })}
        </PdfDocument>
    )
}
PdfRenderer.fileTypes = ['application/pdf'];
PdfRenderer.weight = 0

