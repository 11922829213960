/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 49.2.0 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * PEDING
 * @export
 * @interface FileFileResource
 */
export interface FileFileResource {
    /**
     * 
     * @type {string}
     * @memberof FileFileResource
     */
    download_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileFileResource
     */
    mime_type: string;
    /**
     * 
     * @type {number}
     * @memberof FileFileResource
     */
    size: number;
}

export function FileFileResourceFromJSON(json: any): FileFileResource {
    return FileFileResourceFromJSONTyped(json, false);
}

export function FileFileResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileFileResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'download_url': json['download_url'],
        'mime_type': json['mime_type'],
        'size': json['size'],
    };
}

export function FileFileResourceToJSON(value?: FileFileResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'download_url': value.download_url,
        'mime_type': value.mime_type,
        'size': value.size,
    };
}


