import * as React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Dropdown, FormField, Modal, Popup } from "semantic-ui-react";
import { Audit } from "../../../../backend_api/models";
import Button from '../../../../base/components/basic/Button';
import Icon from '../../../../base/components/basic/Icon';
import { FieldActionSize, TextArea, TextAreaHeight } from '../../../../base/ui/components/TextArea';
import { showSnackbar } from '../../../../base/ui/uiSlice';
import { replaceAll } from '../../../../base/utils';
import { getLocalTranslationsIntl } from '../../../../containers/ConnectedIntlProvider';
import { useAppDispatch } from "../../../../store";
import history from '../../../../store/history';
import { getLocale } from '../../../globals/selectors';
import { getOrganisationNameSelector, getProfileSelector } from "../../../users/selectors";
import { sendAuditInvitation, updateAuditExternalAuditor } from "../../slices/auditSlice";

export const EMAIL_OPENING_KEY = 'email-opening';
export const EMAIL_CLOSING_KEY = 'email-closing';

export function SendEmailPreviewModal(props: {
    audit: Audit,
    showing: boolean,
    closeModal: () => void,
    isResending: boolean,
    email?: string
}) {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const locale = useSelector(getLocale);
    const locales = locale && locale.locales || [];

    const {showing, audit, closeModal, isResending, email} = props;
    const organisationName: string = useSelector(getOrganisationNameSelector);
    const auditType = audit?.audit_type;
    const assignedEmail = audit?.assigned_user?.email;
    const emailToSend = isResending ? assignedEmail : email;

    const loggedInUser = useSelector(getProfileSelector)?.profile;
    const senderName = loggedInUser?.firstname + " " + loggedInUser.lastname;

    const englishLocale = locales?.filter(l => l.language == "en")[0];

    const [selectedLocaleId, setSelectedLocaleId] = React.useState(englishLocale?.id);
    const selectedLocale = locales?.filter(l => l.id == selectedLocaleId)[0];
    const [hasMadeSomeEdits, setHasMadeSomeEdits] = React.useState(false);
    
    const localIntl = getLocalTranslationsIntl(selectedLocale?.language);

    const openingTranslation = localIntl.formatMessage({id: 'audits.edit.email_preview.opening'}, {organisationName: organisationName});
    const closingTranslation = localIntl.formatMessage({id: 'audits.edit.email_preview.closing'}, {senderName: senderName});

    const [editting, setEditting] = React.useState(false);
    
    const [emailOpening, setEmailOpening] = React.useState(openingTranslation);
    const [emailClosing, setEmailClosing] = React.useState(closingTranslation);


    const savedEmailOpening = sessionStorage.getItem(EMAIL_OPENING_KEY) || "";
    const canChangeToSavedOpening = savedEmailOpening && savedEmailOpening != emailOpening;
    const savedEmailClosing = sessionStorage.getItem(EMAIL_CLOSING_KEY) || "";
    const canChangeToSavedClosing = savedEmailClosing && savedEmailClosing != emailClosing;

    React.useEffect(() => {
        if(!hasMadeSomeEdits) {
            setEmailOpening(openingTranslation);
        }
    }, [openingTranslation]);

    React.useEffect(() => {
        if(!hasMadeSomeEdits) {
            setEmailClosing(closingTranslation)
        }
    }, [closingTranslation]);

    const changeEmailOpening = canChangeToSavedOpening || canChangeToSavedClosing ? changeToSavedEdits : (hasMadeSomeEdits ? changeToDefault : undefined);

    function changeToDefault() {
        setEmailOpening(openingTranslation);
        setEmailClosing(closingTranslation);
        setHasMadeSomeEdits(false)
    }

    function changeToSavedEdits() {
        setEmailOpening(savedEmailOpening);
        setEmailClosing(savedEmailClosing);
        setHasMadeSomeEdits(true);
    }

    function updateEmailOpening(value: string) {
        // const formattedValue = value.replace("\n\n", "<p></p>").replace("\n", "<br/>");
        setEmailOpening(value);
        setHasMadeSomeEdits(true);
    }

    function updateEmailClosing(value: string) {
        setEmailClosing(value);
        setHasMadeSomeEdits(true);
    }


    const options = locales.map(loc => {
        return {
            key: loc.id,
            value: loc.id,
            text: loc.name,
            id: loc.id
        }
    })

    const resendEmail = () => {
        dispatch(sendAuditInvitation({
            audit_id: audit.id,
            opening: emailOpening,
            closing: emailClosing,
            locale_id: selectedLocaleId
        }));
        closeModal();
    }

    function assignToExternalUserAndSendInvitation() {
        if(emailToSend) {
            dispatch(updateAuditExternalAuditor({
                assignTemporaryUser: {
                    email: emailToSend
                },
                sendInvitation: {
                    audit_id: audit.id,
                    opening: emailOpening,
                    closing: emailClosing,
                    locale_id: selectedLocaleId
                }
            }));
            closeModal();
            history.goBack();
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><FormattedMessage id='audits.edit.audit_has_been_sent_to'/><span className='font-bold mx-1'>{emailToSend}</span></div>,
                action: <Button onClick={goToEditAudit} className='px-3 py-1 text-sm'><FormattedMessage id='audits.edit_audit'/></Button>, 
            }))
        }
    }

    function goToEditAudit() {
        history.push('/audits/edit_audit/' + audit?.id);
    }


    function getPUName() {
        const productionUnit = audit?.target_production_unit;
        const supplier = audit?.target_supplier;

        if(productionUnit && supplier) {
            return productionUnit.name + ", " + supplier.name;
        }

        if(productionUnit) {
            return productionUnit.name;
        }

        if(supplier) {
            return supplier.name;
        }
    }

    return <Modal
        open={showing}
        closeOnDimmerClick
        closeOnEscape
        size='tiny'>
        <Modal.Header><FormattedMessage id={intl.formatMessage({id: isResending ? 'audits.edit.resend_email' : 'audits.edit.send_email'})}/></Modal.Header>
        <Modal.Content>
            <div className='flex flex-wrap'>
                <FormattedMessage
                    id='audits.edit.email_preview.confirm_to_send_email'
                    values={{email: emailToSend}}
                />
            </div>
            <div className='mb-4'><FormattedMessage id='audits.edit.email_preview.link_active_duration' /></div>
            <FormField className='mb-4'>
                <div className='flex flex-col justify-between py-1 w-full sm:w-3/4'>
                    <div className='mb-1'><label className='text-sm text-input-text-label'><FormattedMessage id={isResending ? 'audits.edit.email_preview.language_used' : 'audits.edit.email_preview.preferred_language'}/></label></div>
                    <Dropdown
                        className='flex flex-row w-full'
                        selection
                        disabled={true}
                        options={options}
                        value={selectedLocaleId}
                        multiple={false}
                        renderLabel={(item) => {
                            return ({
                                style: { color: 'white' },
                                className: 'selectLabel justify-between flex-0',
                                content: <div className='flex text-white'>{item.text}</div>,
                            });
                        }}
                        onChange={(e, option) => {
                            if(!!option?.value) {
                                setSelectedLocaleId(option?.value?.toString())
                            }
                        }}
                    />
                </div>
            </FormField>
            <div className='border flex flex-col rounded-md'>
                <FormField className='border-b px-4 py-3'>
                    <div className='flex flex-col justify-between py-1 w-full sm:w-3/4'>
                        <div className='mb-1'><label className='text-sm text-input-text-label'><FormattedMessage id='audits.edit.email_preview.subject'/></label></div>
                        <div>
                            {
                                localIntl.formatMessage({id: "audits.edit.email_preview.subject.content"}, {organisationName: organisationName, auditType: auditType?.name})
                            }
                        </div>
                    </div>
                </FormField>
                <div className={`px-4 py-4 ${editting ? '' : 'hover:bg-surface-plain cursor-pointer'}`} onClick={() => setEditting(true)}>
                    { editting ?
                        <TextArea
                            className='mb-2'
                            value={emailOpening} 
                            onChange={updateEmailOpening}
                            height={TextAreaHeight.SINGLE_LINE_AUTO_EXPAND}
                            label={intl.formatMessage({id: 'globals.message'})}
                            action={changeEmailOpening}
                            actionSize={FieldActionSize.SMALL}
                            actionLabel={intl.formatMessage({id: canChangeToSavedOpening ? 'audits.edit.email_preview.insert_former_text' : 'audits.edit.email_preview.insert_default_text'})}
                        />
                        :
                        <div className='mb-2' dangerouslySetInnerHTML={{ __html: formatTextWithLineBreak(emailOpening)}}></div>
                    }
                    
                    <div className={`${editting ? 'border py-2 px-3 bg-surface-plain rounded-md' : ''}`}>
                        <div>
                        <div>
                            { localIntl.formatMessage({id: "audits.edit.email_preview.audit_type_and_organisation_name"}, {
                                auditType: <b>{` ${auditType?.name} `}</b>,
                                supplierName: <b>{` ${getPUName()}`}</b>
                            })}
                        </div>
                        </div>
                        <Popup
                            position='top center'
                            trigger={ 
                                <Button className='flex flex-row justify-center items-center my-4 w-fit cursor-default'>
                                    <div>{localIntl.formatMessage({id: "audits.edit.email_preview.start_button"}, {auditType: auditType?.name})}</div>
                                    <Icon name="chevron_right" className='text-xl'/>
                                </Button>
                            }>
                            <FormattedMessage id='audits.edit.email_preview.button_popup' />
                        </Popup>
                        <div>{localIntl.formatMessage({id: "audits.edit.email_preview.expired_warning"})}</div>
                        { editting && 
                            <div className='flex flex-row justify-end text-xs text-secondary mt-1 italic w-full'>
                                <FormattedMessage id='audits.edit.email_preview.immutable_warning'/>
                            </div>
                        }
                    </div>
                    <br/>
                    <div className={`${!editting ? 'flex flex-row justify-between' : ''}`}>
                        {
                            editting ?
                            <TextArea 
                                value={emailClosing} 
                                onChange={updateEmailClosing}
                                height={TextAreaHeight.SINGLE_LINE_AUTO_EXPAND}
                            />
                            :
                            <div className='mb-2' dangerouslySetInnerHTML={{ __html: formatTextWithLineBreak(emailClosing)}}></div>
                        }
                        {
                            !editting &&
                            <div className='flex flex-col justify-end'>
                                <div className='flex flex-row justify-center items-center px-2 py-1 mx-1 border-0 bg-surface-plain rounded-md grow-0 cursor-pointer whitespace-nowrap' onClick={() => setEditting(true)}>
                                    <div className='text-sm text-secondary'><FormattedMessage id='audits.edit.edit_email'/></div>
                                    <Icon className='text-secondary text-base pr-1' name='edit' />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                { editting &&
                    <div className='flex flex-row px-4 pb-4'>
                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-row justify-center items-center px-2 py-1 border-0 bg-surface-plain rounded-md grow-0 cursor-pointer whitespace-nowrap' onClick={() => setEditting(false)}>
                                <div className='text-sm text-secondary'><FormattedMessage id='audits.edit.save_and_preview'/></div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Modal.Content>
        <Modal.Actions className='flex flex-row justify-end my-2'>
            <Button className='' primary onClick={isResending ? resendEmail : assignToExternalUserAndSendInvitation}><FormattedMessage id='audits.edit.send_email'/></Button>
            <Button className='' onClick={closeModal}><FormattedMessage id='globals.confirmation.cancel'/></Button>
        </Modal.Actions>
    </Modal>
}

function formatTextWithLineBreak(text: string): string {
    const replacedWithPTag = replaceAll(text, '\n\n', '<p></p>');                                
    return replaceAll(replacedWithPTag, '\n', '<br/>');
}