import { add, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import DatePicker3 from '../../../base/components/basic/DatePicker3';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { AppState, ComponentOrStringType } from '../../../base/types';
import { TextArea, TextAreaHeight } from '../../../base/ui/components/TextArea';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import InvalidFieldMessage from '../../../base/ui/components/labels/InvalidFieldMessage';
import Label from '../../../base/ui/components/labels/Label';
import { ListItem, ListItems, SelectableType } from '../../../base/ui/components/listitems/ListItem';
import QModal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { getPrettyDate, isUndefinedOrNullOrEmptyString, preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import { CreateIgnoration } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { complianceDeleteIgnoration, complianceGetIgnorations, complianceIgnorationChangeEndDate, complianceIgnorationChangeReason, complianceIgnorationChangeStartDate, complianceIgnoreRequirement } from '../complianceSlice';
import { getComplianceIgnorationByIdSelector, getComplianceProductSelector } from '../selectors/ComplianceSelectors';

type Props = {
    className?: string;
    trigger?: ComponentOrStringType;
    products: any[];
    supplierId: string;
    documentSpecificationId: string;
    documentName: string;
    isIgnored: boolean;
};

const ComplianceIgnoreRequirement = (props: Props): React.ReactElement => {
    const { className, trigger, documentSpecificationId, supplierId, isIgnored, documentName } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const productData = useSelector(getComplianceProductSelector);
    const [product, updateProduct] = useState(productData);
    const orderDate = product && product.detailed_orders.length > 0 && product.detailed_orders[0].date;
    const ignorationRule = useSelector((state: AppState) => getComplianceIgnorationByIdSelector(state, documentSpecificationId));
    const [validation, updateValidation] = useState({ reason: true, dates: true })

    const translate = (id: string) => {
        return intl.formatMessage({ id });
    }

    const addSome = (toAdd) => { // TODO: add toAdd type
        return format(add(new Date(orderDate), toAdd), DEFAULT_DATE_FORMAT2);
    }
    const rangeMap = {
        r0: [orderDate, addSome({ days: 30 })],
        r1: [orderDate, addSome({ months: 6 })],
        r2: [orderDate, addSome({ years: 1 })],
        r3: [orderDate, addSome({ years: 2 })],
        r4: [orderDate, null],
        // r_custom: ['', ''],
    }
    const [showCustomDateSelector, setShowCustomDateSelector] = useState(false);
    const [range, updateRange] = useState(rangeMap['r0']);
    const [startDate, updateStartDate] = useState(ignorationRule && ignorationRule.start_date);
    const [endDate, updateEndDate] = useState(ignorationRule && ignorationRule.end_date);
    const [reason, updateReason] = useState(ignorationRule && ignorationRule.reason);
    const [errorMessage, setErrorMessage] = useState({ reason: null, dates: null });
    const modalData = useSelector(getShowModalSelector);
    const show = modalData['complianceIgnoreRequirement'] && modalData['complianceIgnoreRequirement'].show;

    const close = () => {
        dispatch(showModal({ id: 'complianceIgnoreRequirement', show: false }))
    }
    const clearData = () => {
        updateReason(null);
        updateStartDate(null);
        updateEndDate(null);
        updateRange(rangeMap['r0']);
    }
    const setRange = (e, r) => {
        updateRange(rangeMap[r]);
        // alert('setRange')
        preventDefaultAndStopPropagation(e);
        return false;
    }
    const cancel = () => {
        close();
    }
    const stopIgnoring = () => {
        dispatch(complianceDeleteIgnoration({ ruleId: ignorationRule.ignoration_id, productId: product.product_id }));

        clearData();
        close();
    }
    const updateIgnoration = () => {
        const valid = checkValidation();
        if (valid) {
            dispatch(complianceIgnorationChangeStartDate({ from: startDate, ignoration_id: ignorationRule.ignoration_id }));
            dispatch(complianceIgnorationChangeEndDate({ to: endDate, ignoration_id: ignorationRule.ignoration_id }));
            if (reason) {
                dispatch(complianceIgnorationChangeReason({ reason, ignoration_id: ignorationRule.ignoration_id }));
            }
            showConfirmUpdate(false);
            close();
        }
    }

    const ignoreRequirement = () => {
        const valid = checkValidation();
        if (valid) {
            const data: CreateIgnoration = {
                document_specification_id: documentSpecificationId,
                from: range[0],
                to: range[1],
                product_ids: [product.product_id],
                reason,
                supplier_id: supplierId,
                supplier_type: 'supplier',
            }
            dispatch(complianceIgnoreRequirement(data));
            close();
        }
    }

    const checkValidation = (type?: 'date' | 'reason') => {
        const t = !type ? 'all' : type;
        const datesValid = areDatesValid();
        const reasonValid = isReasonValid();

        if (t === 'all') {
            setErrorMessage({
                ...errorMessage,
                reason: reasonValid ? null : translate('compliance.ignore_product.error_messages.set_reason'),
                dates: datesValid ? null : translate('compliance.ignore_product.error_messages.set_date'),
            })
        }
        if (t === 'date') {
            setErrorMessage({
                ...errorMessage,
                dates: datesValid ? null : translate('compliance.ignore_product.error_messages.set_date'),
            })
        }
        if (t === 'reason') {
            setErrorMessage({
                ...errorMessage,
                reason: reasonValid ? null : translate('compliance.ignore_product.error_messages.set_reason'),
            })
        }
        return reasonValid && datesValid;
    }
    const isReasonValid = () => {
        return !isUndefinedOrNullOrEmptyString(reason);
    }
    const areDatesValid = () => {
        return isIgnored ? !isUndefinedOrNullOrEmptyString(startDate) : !isUndefinedOrNullOrEmptyString(range && range[0])/*  && !isUndefinedOrNullOrEmptyString(range && range[1]) */;
    }

    useEffect(() => {
        if (isIgnored && product) {
            dispatch(complianceGetIgnorations(product.product_id))
        }
    }, []);
    useEffect(() => {
        if (isIgnored && product) {
            dispatch(complianceGetIgnorations(product.product_id))
        }
    }, [productData]);
    useEffect(() => {
        updateProduct(productData);
    }, [productData]);

    useEffect(() => {
        updateReason(ignorationRule && ignorationRule.reason);
        updateStartDate(ignorationRule && ignorationRule.start_date);
        updateEndDate(ignorationRule && ignorationRule.end_date);
    }, [ignorationRule]);
    useEffect(() => {
        checkValidation('date');
    }, [startDate, endDate, range]);

    const [confirmCancel, showConfirmCancel] = useState(false);
    const [confirmUpdate, showConfirmUpdate] = useState(false);

    const confirmCancelDialog = <QModal
        width={500}
        isOpen={confirmCancel}
        header={translate('compliance.ignore_product.remove_ignoration')}
        content={product && <>
            <FormattedMessage id='compliance.ignore_product.remove_ignoration.text' values={{
                docName: <span className='font-bold'>{documentName}</span>,
                productName: <span className='font-bold'>{product.product_name}</span>,
                dateStart: <span className='font-bold'>{getPrettyDate(startDate)}</span>,
                dateEnd: <span className='font-bold'>{getPrettyDate(endDate)}</span>
            }} />
        </>}
        actionItems={[
            { type: ActionTypes.Action, text: translate('compliance.ignore_product.remove_ignoration.yes'), buttonType: ButtonTypes.Danger, event: stopIgnoring },
            { type: ActionTypes.CancelAction, text: translate('globals.cancel'), buttonType: ButtonTypes.Plain, event: () => showConfirmCancel(false) },
        ]}
    />
    const confirmUpdateDialog = <QModal
        width={500}
        isOpen={confirmUpdate}
        header={translate('compliance.ignore_product.apply_changes')}
        content={product && <>
            <FormattedMessage id='compliance.ignore_product.apply_changes.text' values={{
                docName: <span className='font-bold'>{documentName}</span>,
                productName: <span className='font-bold'>{product.product_name}</span>,
                dateStart: <span className='font-bold'>{getPrettyDate(startDate)}</span>,
                dateEnd: <span className='font-bold'>{getPrettyDate(endDate)}</span>
            }} />
        </>}
        actionItems={[
            { type: ActionTypes.Action, text: translate('compliance.ignore_product.apply_changes.yes'), buttonType: ButtonTypes.Primary, event: updateIgnoration },
            { type: ActionTypes.CancelAction, text: translate('globals.cancel'), buttonType: ButtonTypes.Plain, event: () => showConfirmUpdate(false) },
        ]}
    />

    return <div className={twMerge('', className)}  onClick={(e) => preventDefaultAndStopPropagation(e)}>
        {confirmCancelDialog}
        {confirmUpdateDialog}
        <QModal
            cancelClose={true}
            onOpenChange={(open) => open ? null : close()}
            width={500}
            isOpen={show}
            trigger={trigger}
            header={translate('compliance.ignore_product.ignore_requirement')}
            subHeader={documentName}
            actionItems={[
                {
                    type: ActionTypes.Action,
                    buttonType: ButtonTypes.Primary,
                    event: () => !isIgnored ? ignoreRequirement() : showConfirmUpdate(true),
                    text: translate(isIgnored ? 'compliance.ignore_product.save_changes' : 'compliance.ignore_product.ignore_requirement'),
                },
                {
                    type: ActionTypes.CancelAction,
                    buttonType: ButtonTypes.Plain,
                    event: () => cancel(),
                    text: translate('globals.cancel'),
                },
                isIgnored ? {
                    type: ActionTypes.AltAction,
                    buttonType: ButtonTypes.Plain,
                    icon: 'operation_delete',
                    event: () => showConfirmCancel(true),
                    text: translate('compliance.ignore_product.remove_ignoration.action'),
                } : null
            ]}
        >
            <div className='flex flex-col p-6 gap-y-4' tabIndex={0}>
                <div>
                    <div className='font-bold text-lg text-default mb-2'><FormattedMessage id='compliance.ignore_product.document_not_reqired_for' /></div>
                    {product && <ListItems className={'border border-b-0'}>
                        <ListItem className='' slim thumbnail={product && product.product_image && product.product_image.image && product.product_image.image.image_url} meta={{ metaBelow: { firstItem: product && product.product_number } }} >{product.product_name}</ListItem>
                    </ListItems>}
                </div>
                <div>
                    <div className='font-bold text-lg text-default'><FormattedMessage id='compliance.ignore_product.on_any_order_shipped' /></div>
                    {isIgnored && <div className='flex gap-2'>
                        <DatePicker3 date={startDate} maxDate={new Date(endDate)}
                            handleChange={(d) => {
                                updateStartDate(!isUndefinedOrNullOrEmptyString(d) ? format(d, DEFAULT_DATE_FORMAT2) : null);

                            }} />
                        <DatePicker3 date={endDate} minDate={new Date(startDate)}
                            handleChange={(d) => {
                                updateEndDate(!isUndefinedOrNullOrEmptyString(d) ? format(d, DEFAULT_DATE_FORMAT2) : null);

                            }} />
                    </div>}
                    {!isIgnored && <ListItems className='border border-b-0'>
                        {Object.keys(rangeMap).map((k) => {
                            const labelKey = 'compliance.ignore_product.ignore_date_range.' + k;
                            return <ListItem className='flex gap-1' selectable={{
                                selected: range && (range[0] === rangeMap[k][0] && range[1] === rangeMap[k][1]),
                                onPress: () => {
                                    setRange(undefined, k);
                                    setShowCustomDateSelector(false);
                                },
                                type: SelectableType.Radio, id: 'foo'
                            }}>
                                <div className='font-bold'>{translate(labelKey)}</div>
                                {getPrettyDate(rangeMap[k][0])} - {rangeMap[k][1] && getPrettyDate(rangeMap[k][1])}
                            </ListItem>

                        })}
                        <ListItem selectable={{
                            selected: showCustomDateSelector,
                            type: SelectableType.Radio,
                            id: 'foo',
                            onPress: () => {
                                !showCustomDateSelector && setRange(undefined, [null, null]);
                                setShowCustomDateSelector(true);

                            }
                        }}>{!showCustomDateSelector && translate('compliance.ignore_product.ignore_date_range.r_custom')}
                            {showCustomDateSelector && <div className='flex gap-2'>
                                <div>
                                    <Label text={translate('compliance.ignore_product.ignore_date.from')} />
                                    <DatePicker3
                                        // isRequired={true}
                                        date={range && range[0]}
                                        maxDate={!isUndefinedOrNullOrEmptyString(range[1]) && new Date(range[1])}
                                        // maxDate={new Date(range && range[0])}
                                        handleChange={(d) => {
                                            const date = d === null ? null : format(d, DEFAULT_DATE_FORMAT2);
                                            const r = range ? [...range] : [null, null];
                                            r[0] = date;
                                            updateRange(r);
                                        }} />
                                </div>
                                <div>
                                    <Label text={translate('compliance.ignore_product.ignore_date.to')} />
                                    <DatePicker3
                                        date={range && range[1]}
                                        minDate={!isUndefinedOrNullOrEmptyString(range[0]) && new Date(range[0])}
                                        handleChange={(d) => {
                                            const date = d === null ? null : format(d, DEFAULT_DATE_FORMAT2);
                                            const r = range ? [...range] : [null, null];
                                            r[1] = date;
                                            updateRange(r);
                                        }} />
                                </div>
                            </div>}
                        </ListItem>
                    </ListItems>}
                    <InvalidFieldMessage className='pt-2' message={errorMessage.dates} />
                </div>
                <TextArea
                    label={translate('compliance.ignore_product.reason_for_ignoring')}
                    value={reason}
                    onChange={(r) => {
                        updateReason(r);
                    }}
                    onBlur={() => checkValidation('reason')}
                    height={TextAreaHeight.DEFAULT_AUTO_EXPAND}
                    errorMessage={errorMessage.reason}
                />
                <div className='text-secondary text-sm'>
                    <FormattedMessage id='compliance.ignore_product.reason_for_ignoring.text' />
                </div>
            </div>
        </QModal>

    </div >
}
export default ComplianceIgnoreRequirement;
