import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { createSelector } from 'reselect';
import { AddAttachmentsToAudit, AuditCustomConclusion, AuditHistory, AuditStatus, AuditType, FileResource, Pagination, ReportAuditRoot } from '../../../backend_api/models';
import { Audit } from '../../../backend_api/models/Audit';
import { AssignTemporaryUser, AuditApprovalFlow, AuditCheckpoint, AuditSubmit, SendInvitation, SetAuditorComment, SetAuditorConclusion, SetCustomFieldValue, SetTextInput, SetTextTable } from '../../../backend_api_2';
import { AuditWithContent, SetComment, SetMultipleChoice, SetStatusText } from '../../../backend_api_2/models';
import { request2, sendTemporaryUserRequest } from '../../../base/api';
import { setContextData, setLayoutAttribute } from '../../../base/baseSlice';
import { AppState, Context, byId } from '../../../base/types';
import { getLocationEntry, urlParamsToArray } from '../../../base/utils';
import history from '../../../store/history';
import { TEMPORARY_USER_MODE, setIsAuthenticated } from '../../authentication/authenticationSlice';
import { catchException } from '../../errorHandling/handler';
import { convertFiltersToQueryString } from '../../filters/util';
import { getCheckpointsRemarks, loadDefectsAndCheckpointsImagesForLB } from '../../inspections/actions/reportActions';
import { InspectionStatusType } from '../../inspections/types';
import { clearErrorMessage, sendErrorMessage, sendStatusMessage } from '../../messages/actions';
import { exportExcelSavingState } from '../../statistics/actions';
import { EMAIL_CLOSING_KEY, EMAIL_OPENING_KEY } from '../components/edit-audit/SendEmailPreviewModal';
import { AuditReportState, AuditState, AuditsListMeta, TemporaryUser } from '../types';

const initialState: AuditState = {
    audits: {
        audits: [],
        pagination: undefined,
    },
    loading: false,
    loadingComments: false,
    addingComment: false,
    auditReport: undefined,
    defaultDate: '',
    auditById: undefined,
    auditTypes: [],
    auditCustomConclusions: [],
    auditTypesAndConclusionsRelations: undefined,
    history: [],
    listFilterDate: undefined,
    auditWithContent: undefined,
    errorCodeFetchingAudit: undefined,
    temporaryUser: undefined,
};

export interface PaginatedAudits {
    /**
     * 
     * @type {Pagination}
     * @memberof PaginatedProductionUnits
     */
    pagination: Pagination;
    /**
     * 
     * @type {Array<ListableProductionUnit>}
     * @memberof PaginatedProductionUnits
     */
    audits: Array<Audit>;
}
export const createAudit = createAsyncThunk<Audit, Audit>(
    'createAudit',
    async (auditData: Audit, { dispatch, rejectWithValue }) => {
        if (!auditData.planned_date) {
            auditData.planned_date = new Date();
        }
        const rq = await request2('audits', { method: 'post', body: JSON.stringify(auditData) });
        if (!rq.ok) {
            console.log('There was an error creating the audit...');
            dispatch(sendErrorMessage(['error_message.audit_could_not_be_created'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.audit_was_created_succesfully'], 3000));
        const data = await rq.json()
        history.push('/audits/edit_audit/' + data.id);
        return data;
    });

type UpdateAuditConfig = {
    audit: Audit,
    notifyAssignee?: boolean
}
export const updateAudit = createAsyncThunk<Audit, UpdateAuditConfig>(
    'updateAudit',
    async (config: UpdateAuditConfig, { dispatch, rejectWithValue }) => {
        const rq = await request2(`audits/${config.audit.id}${config.notifyAssignee ? '?notify_assigned_user=true': ''}`, { method: 'put', body: JSON.stringify(config.audit) });
        if (!rq.ok) {
            console.log('There was an error updating the audit...');
            dispatch(sendErrorMessage(['error_message.audit_could_not_be_updated'], 3000));
            rejectWithValue(rq as Response);
            return;
        }
        dispatch(sendStatusMessage(['status_message.audit_was_updated_succesfully'], 3000));
        return await rq.json() as Audit;
    });

type UpdateAuditExternalAuditType = {
    assignTemporaryUser: AssignTemporaryUser,
    sendInvitation: SendInvitation
}
export const updateAuditExternalAuditor = createAsyncThunk<Audit, UpdateAuditExternalAuditType>(
    'updateAuditExternalAuditor',
    async (props, { dispatch, rejectWithValue }) => {
        const rq = await request2(`audits/${props.sendInvitation.audit_id}/assign`, { method: 'put', body: JSON.stringify(props.assignTemporaryUser) });
        if (!rq.ok) {
            console.log('There was an error updating the audit...');
            dispatch(sendErrorMessage(['error_message.audit_could_not_be_updated'], 3000));
            rejectWithValue(rq as Response);
            return;
        }
        await dispatch(sendAuditInvitation(props.sendInvitation));
        dispatch(sendStatusMessage(['status_message.audit_was_updated_succesfully'], 3000));
        return await rq.json() as Audit;
    });

type UpdateAuditAuditorType = {
    audit_id: string,
    user_id: string
}
export const updateAuditAuditor = createAsyncThunk<Audit, UpdateAuditAuditorType>(
    'updateAuditAuditor',
    async (updateData, { dispatch, rejectWithValue }) => {
        const rq = await request2(`audits/${updateData.audit_id}/reassign/${updateData.user_id}`, { method: 'post' });
        if (!rq.ok) {
            console.log('There was an error updating the audit...');
            dispatch(sendErrorMessage(['error_message.audit_could_not_be_updated'], 3000));
            rejectWithValue(rq as Response);
            return;
        }
        dispatch(sendStatusMessage(['status_message.audit_was_updated_succesfully'], 3000));
        dispatch(getAuditList({ supplierId: undefined, filters: urlParamsToArray(history.location.search), limit: 20, offset: 0 }));
        return await rq.json() as Audit;
    });

export const sendAuditInvitation = createAsyncThunk<Audit, SendInvitation>(
    'sendAuditInvitation',
    async (props, { dispatch, rejectWithValue }) => {
        const rq = await request2(`audits/${props.audit_id}/send_invitation`, { method: 'post', body: JSON.stringify(props) });
        if (!rq.ok) {
            console.log('There was an error resending the email...');
            dispatch(sendErrorMessage(['error_message.email_could_not_be_sent'], 3000));
            rejectWithValue(rq as Response);
            return;
        }
        dispatch(sendStatusMessage(['status_message.email_was_sent_succesfully'], 3000));
        sessionStorage.setItem(EMAIL_OPENING_KEY, props.opening);
        sessionStorage.setItem(EMAIL_CLOSING_KEY, props.closing);
        return await rq.json() as Audit;
    });

export const downloadAuditExcelReport = createAsyncThunk<Blob, Audit>(
    'downloadAuditExcelReport',
    async (audit: Audit, { dispatch, rejectWithValue }) => {
        const rq = await request2('audits/' + audit.id + '/excel', {});
        if (!rq.ok) {
            console.log('There was an error updating the audit...');
            dispatch(sendErrorMessage(['error_message.audit_excel_file_could_not_be_downloaded'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.audit_excel_file_was_downloaded_with_success'], 3000));
        const blob = await rq.blob();
        const fn = audit.target_supplier.name + '.xlsx';
        FileSaver.saveAs(blob, fn);
        return blob;
    });

export const getAuditList = createAsyncThunk<PaginatedAudits, { supplierId: string; filters: any, limit: number, offset: number }>(
    'audits',
    async (params, { dispatch, rejectWithValue }) => {
        const rename = (({
            supplier_id: supplier_ids,
            assigned_user: assigned_user_ids,
            production_unit_id: production_unit_ids,
            ...rest
        }) => ({
            supplier_ids,
            assigned_user_ids,
            production_unit_ids,
            ...rest
        }))
        const filters = rename(params.filters)
        const filterStr = convertFiltersToQueryString(filters);
        const supplierId = params && params.supplierId;
        const rq4 = await request2(supplierId ? 'audits?supplier_id=' + supplierId : 'audits?limit=' + params.limit + '&offset=' + params.offset + '&' + filterStr);
        if (!rq4.ok) {
            console.log('audit list not ok...', rq4.statusText);
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.audit_list_could_not_be_loaded'], 3000));
            return rejectWithValue(rq4 as Response)
        }
        const data = await rq4.json() as PaginatedAudits;
        dispatch(setContextData({ context: Context.Audits, metaData: { list: { total: data.pagination.total } } }))
        return data;
    });

export const getAuditReport = createAsyncThunk<AuditReportState, string>(
    'auditReport',
    async (auditId: string, { dispatch, rejectWithValue }) => {
        const rq = await request2('audits/' + auditId + '/report');
        if (!rq.ok) {
            console.log('audit report not ok...', rq.statusText);
            dispatch(clearErrorMessage());
            history.replace('/404?type=report_not_found');
            return rejectWithValue(rq as Response)
        }
        const data: AuditReportState = await rq.json();
        const auditImages = loadDefectsAndCheckpointsImagesForLB({ checkpoint_headers: data.checkpoint_headers });
        let remarksIds = [];
        data.imageItems = auditImages;
        data.remarks = getCheckpointsRemarks(data.checkpoint_headers, false, true);
        Object.keys(data.remarks).forEach((key) => {
            remarksIds = remarksIds.concat(data.remarks[key].checkpointsWithRemarks);
        })
        data.checkpointWithRemarksIds = remarksIds;
        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', false));
        return data;
    });

export const exportAuditsAsExcel = createAsyncThunk<Blob, { start: string; end: string }>(
    'exportAuditsAsExcel',
    async (dates, { dispatch, rejectWithValue }) => {
        const rq = await request2('audits/export/excel?date_interval_start=' + dates.start + '&date_interval_end=' + dates.end);
        dispatch(exportExcelSavingState(true));
        if (!rq.ok) {
            console.log('audit excel export not ok...', rq.statusText);
            dispatch(clearErrorMessage());
            dispatch(exportExcelSavingState(false));
            dispatch(sendErrorMessage(['error_message.audits_export_as_excel_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.blob();
        const fn = 'audits_' + dates.start + '-' + dates.end + '.xlsx';
        FileSaver.saveAs(blob, fn);
        dispatch(sendStatusMessage(['status_message.audits_exported_as_excel_with_success'], 3000));
        dispatch(exportExcelSavingState(false));
        return blob;
    });

export const unlockAudit = createAsyncThunk<Audit, { auditId: string; reassignToUserId?: string }>(
    'unlockAudit',
    async (props, { dispatch, rejectWithValue }) => {
        const isReassign = props.reassignToUserId;
        let url = 'audits/locked/' + props.auditId;
        url += isReassign ? '/reassign/' + props.reassignToUserId : '/force_unlock';
        const rq = await request2(url, { method: 'post' });
        if (!rq.ok) {
            console.log('audit unlock/reassign not ok...', rq.statusText);
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage([isReassign ? 'error_message.audits_reassign_failed' : 'error_message.audits_unlock_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json();

        dispatch(sendStatusMessage([isReassign ? 'status_message.audits_reassign_was_successful' : 'status_message.audits_unlock_was_successful'], 3000));
        dispatch(exportExcelSavingState(false));
        dispatch(getAuditList({ supplierId: undefined, filters: urlParamsToArray(history.location.search), limit: 20, offset: 0 }));
        return blob;
    });


export const getAuditById = createAsyncThunk<Audit, { auditId: string }>(
    'getAuditById',
    async (props, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + props.auditId;
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('audit getById not ok...', rq.statusText);
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.audits_getting_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json();
        return blob;
    });

export const getAuditTypes = createAsyncThunk<AuditType[], null>(
    'getAuditTypes',
    async (props, { dispatch, rejectWithValue }) => {
        const url = 'audits/audit_types';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting audit types did not go ok...', rq.statusText);
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.audits_getting_types_failed'], 3000));
            catchException('getAuditTypes', {
                endpoint: 'audits/audit_types',
                request: 'audits/audit_types',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        const types = await rq.json();
        return types;
    });

export const getAuditConclusions = createAsyncThunk<AuditCustomConclusion[], null>(
    'getAuditConclusions',
    async (props, { dispatch, rejectWithValue }) => {
        const url = 'audits/conclusions/list';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting audit conclusions did not go ok...', rq.statusText);
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.audits_getting_conclusions_failed'], 3000));
            catchException('getAuditTypes', {
                endpoint: 'audits/conclusions/list',
                request: 'audits/conclusions/list',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        const types = await rq.json();
        return types;
    });
export const getAuditTypesAndConclusionsRelations = createAsyncThunk<byId<AuditCustomConclusion[]>, null>(
    'getAuditTypesAndConclusionsRelations',
    async (props, { dispatch, rejectWithValue }) => {
        const url = 'audits/types_and_conclusions_relations';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting audit conclusions and types releations did not go ok...', rq.statusText);
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.audits_getting_conclusions_failed'], 3000));
            catchException('getAuditTypesAndConclusionsRelations', {
                endpoint: 'audits/types_and_conclusions_relations',
                request: 'audits/types_and_conclusions_relations',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        const relationsMap = await rq.json() as byId<AuditCustomConclusion[]>;
        return relationsMap;
    });

export const updateAuditConclusion = createAsyncThunk<Audit, { auditId: string; conclusionId: string, comment?: string }>(
    'updateAuditConclusion',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.auditId + '/final_conclusion/' + params.conclusionId;
        const rq = await request2(url, { method: 'put', body: JSON.stringify({ comment: params.comment || '' }) });
        if (!rq.ok) {
            console.log('updating audit conclusion did not go ok...', rq.statusText);
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.audit_updating_conclusion_failed'], 3000));
            catchException('updateAuditConclusion', {
                endpoint: 'audits/conclusions/[auditId]',
                request: 'audits/conclusions/' + params.auditId,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.audits_change_conclusion_was_successful'], 3000));
        dispatch(getAuditComments({ auditId: params.auditId }));
        const audit = await rq.json() as Audit;
        return audit;
    });

export const addAuditComment = createAsyncThunk<FileResource, { auditId: string; comment: string, attachments?: any[], eventId?: string, }>(
    'addAuditComment',
    async (params, { dispatch, rejectWithValue }) => {
        const url = params.eventId ? 'audits/' + params.auditId + '/history/' + params.eventId + '/comment' : 'audits/' + params.auditId + '/comment';
        const rq = await request2(url, { method: 'post', body: JSON.stringify({ comment: params.comment, attachments: params.attachments }) });
        if (!rq.ok) {
            console.log('adding audit comment did not go ok...', rq.statusText);
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.audit_adding_comment_failed'], 3000));
            catchException('addAuditComment', {
                endpoint: params.eventId ? 'audits/[auditId]/comment' : 'audits/:auditId/history/:eventId/comment',
                request: url,
                status: rq.status,
            }, { error: rq, params });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage(['status_message.audit_comment_was_successful_added'], 3000));
        }
        dispatch(getAuditComments({ auditId: params.auditId }));
        return await rq.json() as FileResource;
    });

export const getAuditComments = createAsyncThunk<AuditHistory, { auditId: string }>(
    'getAuditComments',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.auditId + '/history';
        const rq = await request2(url);
        if (!rq.ok) {
            console.log('getting audit history did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_history_list_failed'], 3000));
            catchException('getAuditComments', {
                endpoint: 'audits/[auditId]/history',
                request: 'audits/' + params.auditId + '/history',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        const history = await rq.json() as AuditHistory;
        return history;
    });

export const addAuditWatchers = createAsyncThunk<Audit, { auditId: string, watchers: string[], isReport?: boolean }>(
    'addAuditWatchers',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.auditId + '/watchers';
        const rq = await request2(url, { method: 'post', body: JSON.stringify({ watchers: params.watchers }) });
        if (!rq.ok) {
            console.log('Setting audit watcher did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_set_watcher_failed'], 3000));
            catchException('addAuditWatchers', {
                endpoint: 'audits/[auditId]/watchers',
                request: 'audits/' + params.auditId + '/watchers',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage(['status_message.audit_watcher_was_successfully_added'], 3000));
        }
        return await rq.json() as Audit;
    });

export const removeAuditWatchers = createAsyncThunk<Audit, { auditId: string, watchers: string[], isReport?: boolean }>(
    'removeAuditWatchers',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.auditId + '/watchers';
        const rq = await request2(url, { method: 'delete', body: JSON.stringify({ watchers: params.watchers }) });
        if (!rq.ok) {
            console.log('Deleting audit watcher did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_delete_watcher_failed'], 3000));
            catchException('removeAuditWatchers', {
                endpoint: 'audits/[auditId]/watchers',
                request: 'audits/' + params.auditId + '/watchers',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage(['status_message.audit_watcher_was_successfully_deleted'], 3000));
        }
        return await rq.json() as Audit;
    });

export const updateAuditWatchers = createAsyncThunk<Audit, { auditId: string, currentWatchers: string[], updatedWatchers: string[] }>(
    'updateAuditWatchers',
    async (params, { dispatch, rejectWithValue }) => {
        console.log('params ', params)
        const url = 'audits/' + params.auditId + '/watchers';
        const rq = await request2(url, { method: 'put', body: JSON.stringify({ current_watchers: params.currentWatchers, updated_watchers: params.updatedWatchers }) });
        if (!rq.ok) {
            console.log('Updating audit watchers did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_updated_watchers_failed'], 3000));
            catchException('updateAuditWatchers', {
                endpoint: 'audits/[auditId]/watchers',
                request: 'audits/' + params.auditId + '/watchers',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage(['status_message.audit_watchers_were_successfully_updated'], 3000));
        }
        return await rq.json() as Audit;
    });
type AuditApprovalStepApprove = { audit_id: string, approval_step_id: string, conclusion_id: string, comment: string, approval_level_index: number };
export const approveAuditApprovalStep = createAsyncThunk<Audit, AuditApprovalStepApprove>(
    'approveAuditApprovalStep',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.audit_id + '/approval_flow/approve';
        const rq = await request2(url, { method: 'put', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('Audit approve approval step did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_approve_approval_step_failed'], 3000));
            catchException('updateAuditWatchers', {
                endpoint: 'audits/[auditId]/approval_flow/approve',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
            dispatch(sendStatusMessage(['status_message.audit_approve_approval_step_succeded'], 3000));
        }
        dispatch(getAuditComments({ auditId: params.audit_id }));
        return await rq.json() as Audit;
    });

export const editAuditApprovalStep = createAsyncThunk<Audit, AuditApprovalFlow>(
    'editAuditApprovalStep',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.audit_id + '/approval_flow/edit';
        const rq = await request2(url, { method: 'put', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('Updating audit watchers did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_edit_approval_step_failed'], 3000));
            catchException('updateAuditWatchers', {
                endpoint: 'audits/:params.audit_id:/approval_flow/approve',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage(['status_message.audit_edit_approval_step_succeded'], 3000));
        }
        return await rq.json() as Audit;
    });
export const editAuditReportApprovalStep = createAsyncThunk<Audit, AuditApprovalFlow>(
    'editAuditReportApprovalStep',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.audit_id + '/approval_flow/edit_report';
        const rq = await request2(url, { method: 'put', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('Updating audit watchers did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_edit_approval_step_failed'], 3000));
            catchException('updateAuditWatchers', {
                endpoint: 'audits/:params.audit_id:/approval_flow/approve',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
            dispatch(sendStatusMessage(['status_message.audit_edit_approval_step_succeded'], 3000));
        }
        return await rq.json() as Audit;
    });

export const addAttachmentToAuditReport = createAsyncThunk<ReportAuditRoot, { auditId: string, attachments: AddAttachmentsToAudit['attachments'] }>(
    'addAttachmentToAuditReport',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.auditId + '/add_attachments_to_report';
        const rq = await request2(url, { method: 'put', body: JSON.stringify({ attachments: params.attachments }) });
        if (!rq.ok) {
            console.log('Adding audit attachment did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_add_attachment_failed'], 3000));
            catchException('addAttachmentToAuditReport', {
                endpoint: 'audits/[auditId]/add_attachments_to_report',
                request: 'audits/' + params.auditId + '/add_attachments_to_report',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(getAuditComments({ auditId: params.auditId }));
            dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
            dispatch(sendStatusMessage([params.attachments.length > 1 ? 'status_message.audit_add_attachments_was_successful' : 'status_message.audit_add_attachment_was_successful'], 3000));
        }
        return await rq.json() as ReportAuditRoot;
    });

export const addAttachmentToAudit = createAsyncThunk<Audit, { auditId: string, attachments: AddAttachmentsToAudit['attachments'] }>(
    'addAttachmentToAudit',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.auditId + '/add_attachments';
        const rq = await request2(url, { method: 'put', body: JSON.stringify({ attachments: params.attachments }) });
        if (!rq.ok) {
            console.log('Adding audit attachment did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_add_attachment_failed'], 3000));
            catchException('addAttachmentToAuditReport', {
                endpoint: 'audits/[auditId]/add_attachments_to_report',
                request: 'audits/' + params.auditId + '/add_attachments_to_report',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage([params.attachments.length > 1 ? 'status_message.audit_add_attachments_was_successful' : 'status_message.audit_add_attachment_was_successful'], 3000));
        }
        return await rq.json() as Audit;
    });

export const removeAuditAttachment = createAsyncThunk<string, { auditId: string, attachmentId: string, isReport: boolean }>(
    'removeAuditAttachment',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.auditId + '/attachments/' + params.attachmentId;
        const rq = await request2(url, { method: 'delete' });
        if (!rq.ok) {
            console.log('Removing audit attachment did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_remove_attachment_failed'], 3000));
            catchException('addAttachmentToAuditReport', {
                endpoint: 'audits/:auditId/attachments/:attachmentId',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage(['status_message.audit_remove_attachment_was_successful'], 3000));
            params.isReport ? dispatch(getAuditReport(params.auditId)) : dispatch(getAuditById({ auditId: params.auditId }));
            dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
        }
        return await rq.json() as string;
    });


export const deleteAudit = createAsyncThunk<string, { auditId: string, isReport: boolean }>(
    'deleteAudit',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + params.auditId;
        const rq = await request2(url, { method: 'delete' });
        if (!rq.ok) {
            console.log('Deleting audit  did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audit_delete_failed'], 3000));
            catchException('deleteAudit', {
                endpoint: 'audits/:auditId',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage(['status_message.audit_delete_was_successful'], 3000));
            history.goBack();
        }
        return await rq.json() as string;
    });



export const temporaryUserLogin = createAsyncThunk<TemporaryUser, string>(
    'temporaryUserLogin',
    async (sessionId, { dispatch, rejectWithValue }) => {
        const url = 'web_public/api/login/' + sessionId;
        const rq = await sendTemporaryUserRequest(url, { method: 'get' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.temporary_user_login_failed'], 3000));
            catchException('temporaryUserLogin', {
                endpoint: 'web_public/api/login/:sessionId',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(setIsAuthenticated(true));
        return await rq.json() as TemporaryUser;
    });

export type UpdateTemporaryUserConfig = {
    email: string,
    firstname: string,
    locale_id: string,
    lastname: string,
}
export const temporaryUserUpdate = createAsyncThunk<TemporaryUser, UpdateTemporaryUserConfig>(
    'temporaryUserUpdate',
    async (config, { dispatch, rejectWithValue }) => {
        const url = 'web/api/update';
        const rq = await sendTemporaryUserRequest(url, { method: 'post', body: JSON.stringify(config) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.temporary_user_update_failed'], 3000));
            catchException('temporaryUserUpdate', {
                endpoint: 'web/api/update',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        } else {
            dispatch(sendStatusMessage(['status_message.temporary_user_update_successful'], 3000));
        }
        return await rq.json() as TemporaryUser;
    });


export const getAuditContentById = createAsyncThunk<AuditWithContent, { auditId: string }>(
    'getAuditContentById',
    async (props, { dispatch, rejectWithValue }) => {
        const url = 'audits/' + props.auditId + '/with_content';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            // console.log('audit getById not ok...', rq.statusText);
            // dispatch(clearErrorMessage());
            // dispatch(sendErrorMessage(['error_message.audits_getting_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as AuditWithContent;
        return blob;
    });

export const updateProductionUnitCustomFieldCheckpoint = createAsyncThunk<any, SetCustomFieldValue>(
    'updateProductionUnitCustomFieldCheckpoint',
    async (customField, { dispatch, rejectWithValue }) => {
        const url = 'checkpoints/update_custom_field/production_unit';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(customField) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.update_checkpoint_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as AuditWithContent;
        return blob;
    });

export const updateSupplierCustomFieldCheckpoint = createAsyncThunk<any, SetCustomFieldValue>(
    'updateSupplierCustomFieldCheckpoint',
    async (customField, { dispatch, rejectWithValue }) => {
        const url = 'checkpoints/update_custom_field/supplier';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(customField) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.update_checkpoint_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as AuditWithContent;
        return blob;
    });

export const updateCheckpointTextInputs = createAsyncThunk<AuditCheckpoint, SetTextInput>(
    'updateCheckpointTextInputs',
    async (textInputs, { dispatch, rejectWithValue }) => {
        const url = 'checkpoints/update_text_inputs';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(textInputs) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.update_checkpoint_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as AuditCheckpoint;
        return blob;
    });

export const updateCheckpointTextTable = createAsyncThunk<AuditCheckpoint, SetTextTable>(
    'updateCheckpointTextTable',
    async (textTable, { dispatch, rejectWithValue }) => {
        const url = 'checkpoints/update_text_table';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(textTable) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.update_checkpoint_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as AuditCheckpoint;
        return blob;
    });

export const updateCheckpointMultipleChoice = createAsyncThunk<AuditCheckpoint, SetMultipleChoice>(
    'updateCheckpointMultipleChoice',
    async (multipleChoice, { dispatch, rejectWithValue }) => {
        const url = 'checkpoints/update_multiple_choice';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(multipleChoice) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.update_checkpoint_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as AuditCheckpoint;
        return blob;
    });

export const updateCheckpointComment = createAsyncThunk<AuditCheckpoint, SetComment>(
    'updateCheckpointComment',
    async (comment, { dispatch, rejectWithValue }) => {
        const url = 'checkpoints/update_comment';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(comment) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.update_checkpoint_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as AuditCheckpoint;
        return blob;
    });

export const updateCheckpointStatus = createAsyncThunk<AuditCheckpoint, SetStatusText>(
    'updateCheckpointStatus',
    async (status, { dispatch, rejectWithValue }) => {
        const url = 'checkpoints/update_status';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(status) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.update_checkpoint_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as AuditCheckpoint;
        return blob;
    });

export const updateAuditorComment = createAsyncThunk<any, SetAuditorComment>(
    'updateAuditorComment',
    async (auditorComment, { dispatch, rejectWithValue }) => {
        const url = 'audits/comment';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(auditorComment) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.submit_audit_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as any;
        return blob;
    });

export const updateAuditorConclusion = createAsyncThunk<any, SetAuditorConclusion>(
    'updateAuditorConclusion',
    async (auditorConclusion, { dispatch, rejectWithValue }) => {
        const url = `audits/${auditorConclusion.audit_id}/conclusion`;
        const rq = await request2(url, { method: 'put', body: JSON.stringify({ conclusion_id: auditorConclusion.conclusion_id }) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.submit_audit_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const blob = await rq.json() as any;
        return blob;
    });

export const submitAudit = createAsyncThunk<any, AuditSubmit>(
    'submitAudit',
    async (auditSubmit, { dispatch, rejectWithValue }) => {
        const url = 'audits/submit';
        const rq = await request2(url, { method: 'post', body: JSON.stringify(auditSubmit) });
        if (!rq.ok) {
            dispatch(clearErrorMessage());
            dispatch(sendErrorMessage(['error_message.submit_audit_failed'], 3000));
            return rejectWithValue(rq as Response)
        }
        const isTemporaryUserMode = sessionStorage.getItem(TEMPORARY_USER_MODE);
        if (isTemporaryUserMode) {
            history.push('/audits/completed');
        }

        const blob = await rq.json() as any;
        return blob;
    });

export const auditSlice = createSlice({
    name: 'audits',
    initialState,
    reducers: {
        setAuditListDate: (state, action: { payload: string }): void => { state.listFilterDate = action.payload },
        resetAuditWithContent: (state): void => { state.auditWithContent = undefined }
    
    },
    extraReducers: builder => {
        builder.addCase(getAuditReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAuditReport.fulfilled, (state, action) => {
            state.auditReport = action.payload;
            state.loading = false;
        });
        builder.addCase(createAudit.fulfilled, (state, action) => {
            const status: InspectionStatusType | string = getLocationEntry(history.location, 'status')[0];
            if (status === 'planned' || !status) {
                state.audits.audits = state.audits.audits.concat(action.payload);
            }
        });
        builder.addCase(updateAudit.fulfilled, (state, action) => {
            if (state.auditById?.id == action.payload.id) {
                state.auditById = action.payload;
            }
            state.auditById = action.payload;
            state.audits.audits.forEach((audit: Audit, i) => {
                if (audit.id === action.payload.id) {
                    state.audits.audits[i] = action.payload;
                }
            });

        });
        builder.addCase(getAuditList.pending, (state) => { state.loading = true; });
        builder.addCase(getAuditList.fulfilled, (state, action) => {
            if (action.meta.arg.offset > 0) {
                state.audits.audits = state.audits.audits.concat(action.payload.audits);
                state.audits.pagination = action.payload.pagination;
            } else {
                state.audits = action.payload;
            }
            state.loading = false;
        });
        builder.addCase(getAuditList.rejected, (state, action) => {
            state.audits.audits = [];
            state.loading = false;
        });
        builder.addCase(getAuditById.fulfilled, (state, action) => {
            state.auditById = action.payload
        });
        builder.addCase(getAuditTypes.fulfilled, (state, action) => {
            state.auditTypes = action.payload
        });
        builder.addCase(getAuditConclusions.fulfilled, (state, action) => {
            state.auditCustomConclusions = action.payload
        });
        builder.addCase(updateAuditConclusion.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateAuditConclusion.fulfilled, (state, action) => {
            state.auditReport.audit = action.payload;
            state.loading = false;
        });
        builder.addCase(getAuditTypesAndConclusionsRelations.fulfilled, (state, action) => {
            state.auditTypesAndConclusionsRelations = action.payload;
        });
        builder.addCase(getAuditComments.pending, (state) => {
            state.loadingComments = true;
        });
        builder.addCase(getAuditComments.fulfilled, (state, action) => {
            state.history = action.payload.history;
            state.loadingComments = false;
        });
        builder.addCase(getAuditComments.rejected, (state) => {
            state.loadingComments = false;
        });
        builder.addCase(addAuditComment.pending, (state) => {
            state.addingComment = true;
        });
        builder.addCase(addAuditComment.fulfilled, (state) => {
            state.addingComment = false;
        });
        builder.addCase(addAuditWatchers.fulfilled, (state, action) => {
            action.meta.arg.isReport ? state.auditReport.audit = action.payload : state.auditById = action.payload;
        });
        builder.addCase(removeAuditWatchers.fulfilled, (state, action) => {
            action.meta.arg.isReport ? state.auditReport.audit = action.payload : state.auditById = action.payload;
        });
        builder.addCase(addAttachmentToAuditReport.fulfilled, (state, action) => {
            state.auditReport.audit = action.payload.audit;
        });
        builder.addCase(addAttachmentToAudit.fulfilled, (state, action) => {
            state.auditById = action.payload;
        });
        builder.addCase(approveAuditApprovalStep.fulfilled, (state, action) => {
            state.auditReport.audit = action.payload;
        });
        builder.addCase(editAuditReportApprovalStep.fulfilled, (state, action) => {
            state.auditReport.audit = action.payload;
        });
        builder.addCase(editAuditApprovalStep.fulfilled, (state, action) => {
            state.auditById = action.payload;
        });
        builder.addCase(updateAuditExternalAuditor.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateAuditExternalAuditor.fulfilled, (state, action) => {
            state.loading = false;
            if (state.auditById?.id == action.payload.id) {
                state.auditById = action.payload;
            }
            state.audits.audits.forEach((audit: Audit, i) => {
                if (audit.id === action.payload.id) {
                    state.audits.audits[i] = action.payload;
                }
            });
        });
        builder.addCase(updateAuditAuditor.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateAuditAuditor.fulfilled, (state, action) => {
            state.loading = false;
            if (state.auditById?.id == action.payload.id) {
                state.auditById = action.payload;
            }
            state.audits.audits.forEach((audit: Audit, i) => {
                if (audit.id === action.payload.id) {
                    state.audits.audits[i] = action.payload;
                }
            });
        });
        builder.addCase(temporaryUserLogin.pending, (state, action) => {
            sessionStorage.setItem('session_id', action.meta.arg);
            state.loading = true;
        });
        builder.addCase(temporaryUserLogin.fulfilled, (state, action) => {
            state.temporaryUser = action.payload;
            state.loading = false;
            const token = action.payload?.user_token;
            if (token) {
                sessionStorage.setItem('id_token', token);
                sessionStorage.setItem(TEMPORARY_USER_MODE, 'true');
                if (action.payload?.user_details_updated) {
                    history.push(`/audits/perform_audit/${action.payload.reference.reference_id}`);
                }
            } else if (action.payload.reference?.reference_status == 'done') {
                history.push('/audits/completed');
            } else if (state.temporaryUser) {
                history.push('/audits/expired');
            }
        });
        builder.addCase(temporaryUserUpdate.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(temporaryUserUpdate.fulfilled, (state, action) => {
            state.loading = false;
            state.temporaryUser = {
                ...state.temporaryUser,
                user: action.payload as any
            };
            if (!window.location.pathname.includes('/audits/perform_audit/')) {
                history.push(`/audits/perform_audit/${state.temporaryUser.reference.reference_id}`);
            }
        });
        builder.addCase(getAuditContentById.fulfilled, (state, action) => {
            state.auditWithContent = action.payload;
        });
        builder.addCase(getAuditContentById.rejected, (state, action) => {
            state.loading = false;
            state.errorCodeFetchingAudit = (action.payload as any).status;
        });
        builder.addCase(submitAudit.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(submitAudit.fulfilled, (state, action) => {
            state.auditById = {
                ...state.auditById,
                status: AuditStatus.Report
            };
        });
        builder.addCase(updateCheckpointTextInputs.fulfilled, (state, action) => {
            updateStateWithUpdatedCheckpoint(state, action.payload);
        });
        builder.addCase(updateCheckpointTextTable.fulfilled, (state, action) => {
            updateStateWithUpdatedCheckpoint(state, action.payload);
        });
        builder.addCase(updateCheckpointMultipleChoice.fulfilled, (state, action) => {
            updateStateWithUpdatedCheckpoint(state, action.payload);
        });
        builder.addCase(updateSupplierCustomFieldCheckpoint.fulfilled, (state, action) => {
            updateStateWithUpdatedCheckpoint(state, action.payload);
        });
        builder.addCase(updateProductionUnitCustomFieldCheckpoint.fulfilled, (state, action) => {
            updateStateWithUpdatedCheckpoint(state, action.payload);
        });
        builder.addCase(updateCheckpointComment.fulfilled, (state, action) => {
            updateStateWithUpdatedCheckpoint(state, action.payload);
        });
        builder.addCase(updateCheckpointStatus.fulfilled, (state, action) => {
            updateStateWithUpdatedCheckpoint(state, action.payload);
        });
    }
});

function updateStateWithUpdatedCheckpoint(state: AuditState, checkpoint: AuditCheckpoint) {
    const checkpointId = checkpoint.id;
    const headerIndex = state.auditWithContent.checklist.headers.findIndex(h => h.checkpoints.some(c => c.id == checkpointId));
    const checkpointIndex = state.auditWithContent.checklist.headers[headerIndex]?.checkpoints.findIndex(c => c.id == checkpointId);
    if (headerIndex != -1 && checkpointIndex != -1) {
        state.auditWithContent.checklist.headers[headerIndex].checkpoints[checkpointIndex] = checkpoint;
    }
}

export const getListDateSelector = createSelector(
    [(state: AppState): string => state.app.audits.listFilterDate],
    (defaultDate) => defaultDate,
);

export const getAuditsListMetaDataSelector = createSelector(
    [(state: AppState): AuditsListMeta => {
        const data = state.app.audits;
        return { start: 0, per_page: data.audits.audits.length, total: data.audits.audits.length, isFetching: data.loading };
    }],
    (data) => data,
);
export const { setAuditListDate, resetAuditWithContent } = auditSlice.actions;

export default auditSlice.reducer;

