import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Audit, AuditCheckpoint, AuditCheckpointTextTable, AuditCheckpointTextTableCell, Value } from '../../../../backend_api_2';
import Icon from '../../../../base/components/basic/Icon';
import Input from '../../../../base/components/basic/Input';
import { getLocaleLanguageString } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { updateCheckpointTextTable } from '../../slices/auditSlice';
import { AuditCheckpointMedia } from './AuditCheckpointMedia';
import { doesTextTableCheckpointRequireInput } from './mandatory';
import { AuditCheckpointTitleAndActionMenu, isAuditImmutable } from './PerformAuditPage';
import { WebAuditCheckpointStatus } from './WebAuditCheckpointStatus';

export function TextTableCheckpoint(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const {audit, checkpoint, triedToSubmit} = props;
    const requiresInput = doesTextTableCheckpointRequireInput(checkpoint);

    return (
        <div>
            <AuditCheckpointTitleAndActionMenu audit={audit} checkpoint={checkpoint} />
            <WebAuditCheckpointStatus audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            {
                triedToSubmit && requiresInput &&
                <div className='text-status-rejected text-sm mb-2 flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.provide_inputs'/>
                </div>
            }
            <TextTable textTable={checkpoint.text_table} audit={audit} checkpointId={checkpoint.id} />
            <AuditCheckpointMedia audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
        </div>
    )
}

function TextTable(props: {
    audit: Audit,
    checkpointId: string,
    textTable: AuditCheckpointTextTable
}) {
    const {textTable, audit, checkpointId} = props;
    const auditId = audit.id;
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);

    return (
        <div className='overflow-x-auto mb-4'>
            <table className='ui small celled unstackable table'>
                <thead>
                    <tr>
                        <th></th>
                        {
                            textTable.columns.map((column, index) => <th key={index} className='font-bold' style={{minWidth: '200px'}}>{column[lang] || column.C}</th>)
                        }
                    </tr>
                </thead>
                {
                    textTable.rows.map((row, rIndex) => 
                    <tr key={rIndex}>
                        <td className='bg-gray-50 font-bold whitespace-nowrap'>{row[lang] || row.C}</td>
                        {
                            textTable.columns.map((column, cIndex) => {
                                return (
                                    <TextTableItem 
                                        key={`${auditId}-${checkpointId}-${rIndex}-${cIndex}}`}
                                        textTable={textTable} 
                                        audit={audit} 
                                        checkpointId={checkpointId} 
                                        colIndex={cIndex} rowIndex={rIndex} 
                                    />
                                )
                            })
                        }
                    </tr>
                    )
                }
                </table>
        </div>
        
    )
}

function TextTableItem(props: {
    textTable: AuditCheckpointTextTable,
    audit: Audit,
    checkpointId: string,
    colIndex: number,
    rowIndex: number
}) {
    const {textTable, audit, checkpointId, colIndex, rowIndex} = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [value, setValue] = React.useState(getValueByRowAndColumnIndex(textTable.values, colIndex, rowIndex));

    function updateTextTable(rowIndex: number, columnIndex: number, newValue: string) {
        const valueIndex = textTable.values.findIndex(v => v.c == columnIndex && v.r == rowIndex.toString());
        const copiedValues = JSON.parse(JSON.stringify(textTable.values)) as Value[];
        const valueToUpdate = {
            r: rowIndex,
            c: columnIndex,
            value: newValue
        }
        if(valueIndex != -1) {
            copiedValues[valueIndex] = valueToUpdate;
        } else {
            copiedValues.push(valueToUpdate);
        }
        dispatch(updateCheckpointTextTable({
            audit_id: audit.id,
            checkpoint_id: checkpointId,
            rows: textTable.rows,
            columns: textTable.columns,
            values: copiedValues,
        }))
    }
    return <td>
        <Input 
            type='text' 
            inputClass='border-none py-2' 
            value={value} 
            placeholder={colIndex == 0 && rowIndex == 0 ? intl.formatMessage({ id: 'web_based_audit.add_text' }) : ''} 
            onChange={e => setValue(e.currentTarget.value)}
            onBlur={e => updateTextTable(rowIndex, colIndex, e.currentTarget.value)}
            disabled={isAuditImmutable(audit)}
        />
    </td>
     
}

function getValueByRowAndColumnIndex(values: AuditCheckpointTextTableCell[], cIndex: number, rIndex: number): string | null {
    return values.filter(v => v.c == cIndex && v.r == rIndex.toString())[0]?.value;
}