import qs from 'query-string';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
    Dimmer,
    Divider,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    Item,
    List,
    Loader,
    Modal, Segment
} from 'semantic-ui-react';
import Button from '../../../base/components/basic/Button';
import { Language } from '../../../base/types';
import { deepCopy, moveArrayItem } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getCustomFields, getCustomTables, getProductionUnitCustomFields, getSupplierCustomFields } from '../../customFields/actions/actions';
import QarmaInput from '../../globals/components/QarmaInput';
import { MultiLanguageText } from '../../globals/types';
import { sendErrorMessage } from '../../messages/actions';
import DnDList from '../../sortableList/components/DnDList';
import { getFetching } from '../selectors';
import { ChecklistData, CheckpointData, CheckpointHeaderData, CheckpointType } from '../types';
import Checkpoint from './CheckPoint';
import CheckpointEditor from './CheckpointEditor';
import CheckpointHeader from './CheckpointHeader';
import DownloadChecklist from './DownloadChecklist';
import LanguageSelectionModal from './LanguageSelectionModal';

export type EditChecklistProps = {
    checklistData: ChecklistData;
    itemNumber: number;
    itemName: string;
    checklistId?: string;
    inspectionId?: string;
    newCheckpointText: string;
    isStandalone: boolean;
    isTemplate: boolean;
    isFetching: boolean;
    isSaving: boolean;
    doSave(checklistData: any, closeAfterSave: boolean, updateCheckpoint: boolean);
}

type ListItemMeta = {
    type: string;
    headerIndex: number;
    checkpointIndex: number;
}

type CheckedCheckPoints = {
    [checklistId: string]: boolean;
}

const EditChecklist = (props: EditChecklistProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const orderId = qs.parse(location.search).orderId;
    const formatMessage = intl.formatMessage;
    const animationRef = useRef<number>();
    const editorRef = useRef<HTMLInputElement>();
    const editorInnerRef = useRef<HTMLInputElement>();
    const checkpointRef = useRef<HTMLInputElement>();
    const arrowRef = useRef<HTMLInputElement>();
    const waitingForData: boolean = useSelector(getFetching)
    const [newListItemName, setNewListItemName] = useState<string>('');
    const [checklistData, setChecklistData] = useState<ChecklistData>({
        description: '',
        headers: [],
        type: undefined,
        revision: 0,
        languages: [],
    });

    const [language, setLanguage] = useState<Language>({ name: 'Default', code: 'C' });
    const [focusedCheckpoint, setFocusedCheckpoint] = useState<string>(null);
    const [title, setTitle] = useState(formatMessage({ id: 'page_title.edit_checklist' }, { description: checklistData.description }));
    const hasNoCheckpoints = checklistData.headers && checklistData.headers.length === 0;
    const [items, setItems] = useState<any[]>([]);
    const [itemsMeta, setItemsMeta] = useState<ListItemMeta[]>([]);
    const [headerCollapsed, setHeaderCollapsed] = useState<boolean[]>([]);
    const [checkedCheckpoints, setCheckedCheckpoints] = useState<CheckedCheckPoints>({});
    const [editTitle, setEditTitle] = useState<boolean>(false);
    const [newHeaderDialog, setNewHeaderDialog] = useState<boolean>(false);
    const [newHeaderText, setNewHeaderText] = useState<string>('');
    const [enableDrag, setEnableDrag] = useState<boolean>(true);
    const editorAnimation = (): void => {
        if (editorRef.current && checkpointRef.current) {
            //Position checkpoint editor box
            let offset = 0;
            const padding = 50;
            const slideSpan = editorInnerRef.current.getBoundingClientRect().height - (padding * 2);
            const checkpointTop = checkpointRef.current.getBoundingClientRect().top;
            const checkpointHeight = checkpointRef.current.getBoundingClientRect().height;
            const checkpointOffsetTop = checkpointRef.current.offsetTop;
            const slideboxHeight = editorRef.current.getBoundingClientRect().height;
            const editBoxHeight = editorInnerRef.current.getBoundingClientRect().height;
            const arrowHeight = arrowRef.current.getBoundingClientRect().height;
            if (checkpointTop > slideSpan + padding - checkpointHeight) {
                // Not at top of window
                offset = checkpointOffsetTop - padding - slideSpan + checkpointHeight;
            }
            else {
                //At top of window.
                if (checkpointTop > padding) {
                    offset = checkpointOffsetTop - checkpointTop;
                }
                else {
                    offset = checkpointOffsetTop - padding;
                }
            }
            if (offset < 0) {
                offset = 0;
            }
            if ((offset + editBoxHeight) > slideboxHeight) {
                offset = slideboxHeight - editBoxHeight;
            }
            editorRef.current.style.top = (offset + 'px');
            //Position arrow
            const arrowCheckpointHeightDif = ((checkpointHeight - arrowHeight) / 2) + 4;
            const arrowTop = checkpointOffsetTop - offset - editBoxHeight + arrowCheckpointHeightDif;
            arrowRef.current.style.top = (arrowTop + 'px');
        }
        animationRef.current = requestAnimationFrame(editorAnimation);
    }

    useEffect(() => {
        dispatch(getCustomTables())
        dispatch(getCustomFields())
        dispatch(getProductionUnitCustomFields())
        dispatch(getSupplierCustomFields())
        animationRef.current = requestAnimationFrame(editorAnimation);
        return (): void => cancelAnimationFrame(animationRef.current);
    }, [])

    useEffect(() => {
        setTitle(formatMessage({ id: 'page_title.edit_checklist' }, { description: checklistData.description }));
    }, [checklistData.description]);

    useEffect(() => {
        if (props.checklistData) {
            if (props.checklistData.headers && !checklistData.headers) {
                const array: boolean[] = []
                for (let i = 0; i < props.checklistData.headers.length; i++) {
                    array.push(true);
                }
                setHeaderCollapsed(array);
            }
            setChecklistData(props.checklistData);
        }
    }, [props.checklistData]);

    const toggleCollapse = (headerIndex): void => {
        if (checklistData.headers.length > 1) {
            const newCollapsed = deepCopy(headerCollapsed);
            newCollapsed[headerIndex] = !newCollapsed[headerIndex];
            setHeaderCollapsed(newCollapsed);
            if (focusedCheckpoint && headerIndex.toString() === focusedCheckpoint.split(',')[0]) {
                setFocusedCheckpoint('');
            }
        }
    }

    const closeEditor = (): void => {
        setEnableDrag(true);
        setFocusedCheckpoint('');
    }

    const updateHeaderName = (newName: MultiLanguageText, headerIndex: number): void => {
        const checklistDataCopy = deepCopy(checklistData);
        checklistDataCopy.headers[headerIndex].title = newName;
        setChecklistData(checklistDataCopy);
        props.doSave(checklistDataCopy, false, false);
    }

    const handleCheckedCheckpoints = (checkpointId: string, isChecked: boolean): void => {
        const checkedCheckpointsCopy = deepCopy(checkedCheckpoints);
        checkedCheckpointsCopy[checkpointId] = isChecked;
        setCheckedCheckpoints(checkedCheckpointsCopy);
    }
    useEffect(() => {
        setCheckedCheckpoints({});
    }, [props.checklistData])

    useEffect(() => {
        const items = [];
        const itemsMeta = [];
        checklistData.headers && checklistData.headers.map((header, headerIndex) => {
            items.push(<Fragment>
                <Divider />
                <CheckpointHeader
                    name={header.title}
                    language={language.code}
                    index={headerIndex + 1}
                    collapsed={headerCollapsed[headerIndex]}
                    nameChanged={(newName): void => updateHeaderName(newName, headerIndex)}
                    collapse={(): void => toggleCollapse(headerIndex)}
                    containsCheckpoints={header.checkpoints && header.checkpoints.length > 0}
                    delete={(): void => deleteHeader(headerIndex)}
                    duplicate={(): void => duplicateHeader(headerIndex)}
                />
            </Fragment>);
            itemsMeta.push({ type: 'header', headerIndex: headerIndex, checkpointIndex: 0 }); //Dropping a checkpoint on the haeder should place it top of the headers checkpoint list.
            if (!headerCollapsed[headerIndex]) {
                header.checkpoints.map((checkpoint, i) => {
                    items.push((<Checkpoint
                        reff={focusedCheckpoint === createFocusedHeaderString(headerIndex, i) ? checkpointRef : null}
                        language={language.code}
                        editing={focusedCheckpoint === createFocusedHeaderString(headerIndex, i)}
                        headerCnt={headerIndex}
                        checkpoint={checkpoint}
                        handleFocus={(isFocused): void => {
                            if (isFocused) {
                                setFocusedCheckpoint(createFocusedHeaderString(headerIndex, i));
                            }
                            else {
                                setFocusedCheckpoint('');
                            }
                        }}
                        dragable={(enableDrag): void => setEnableDrag(enableDrag)}
                        handleBlur={(value): void => {
                            const checklistDataCopy = deepCopy(checklistData);
                            if (checklistDataCopy.headers[headerIndex].checkpoints[i].description[language.code] !== value[language.code]) {
                                checklistDataCopy.headers[headerIndex].checkpoints[i].description = value;
                                props.doSave(checklistDataCopy, false, false);
                                setChecklistData(checklistDataCopy);
                            }
                        }}
                        hendleCheck={(isChecked: boolean): void => { handleCheckedCheckpoints(checkpoint.id, isChecked) }}
                        isChecked={checkedCheckpoints[checkpoint.id]}
                    />));
                    itemsMeta.push({ type: 'checkpoint', headerIndex: headerIndex, checkpointIndex: i });
                });
            }
        });
        setItems(items);
        setItemsMeta(itemsMeta);
    }, [checklistData, focusedCheckpoint, headerCollapsed, language, checkedCheckpoints]);


    const handleTitle = (evt, item): void => {
        const checklistDataCopy = deepCopy(checklistData);
        checklistDataCopy.description = item.value;
        setChecklistData(checklistDataCopy);
    }

    const handleTitleBlur = (): void => {
        if (checklistData.description != '') {
            doSave(false);
        }
        else {
            const checklistDataCopy = deepCopy(checklistData);
            checklistDataCopy.description = props.checklistData.description;
            setChecklistData(checklistDataCopy);
            dispatch(sendErrorMessage(['error_message.checklistname_required'], 5000));
        }
    }

    const doSave = (updateCheckpoint: boolean): void => {
        const newDataString = JSON.stringify(checklistData)
        const oldDataString = JSON.stringify(props.checklistData)
        if (newDataString != oldDataString) {
            props.doSave(checklistData, false, updateCheckpoint);
        }
    }

    const handleAdd = (evt, item): void => {
        setNewListItemName(item.value);
    }

    const newHeader = (name: string): CheckpointHeaderData => {
        return {
            sequence: checklistData.headers.length,
            checkpoints: [],
            title: { C: name },
            id: createId(),
            score: undefined
        };
    }

    const addHeader = (name: string): void => {
        const checklistDataCopy = deepCopy(checklistData);
        checklistDataCopy.headers.push(newHeader(name));
        props.doSave(checklistDataCopy, false, true);
        const collapsedCopy = deepCopy(headerCollapsed)
        collapsedCopy.push(true);
        setHeaderCollapsed(collapsedCopy);
        setFocusedCheckpoint('');
        setChecklistData(checklistDataCopy);
        setNewListItemName('');
    }


    const addCheckpoint = (): void => {
        const checklistDataCopy = deepCopy(checklistData);
        if (checklistDataCopy.headers.length < 1) {
            checklistDataCopy.headers.push(newHeader(''));
        }
        checklistDataCopy.headers[checklistDataCopy.headers.length - 1].checkpoints.push(makeEmptyCheckpoint(newListItemName));
        props.doSave(checklistDataCopy, false, true);
        setFocusedCheckpoint(createFocusedHeaderString(checklistDataCopy.headers.length - 1, checklistDataCopy.headers[checklistDataCopy.headers.length - 1].checkpoints.length - 1))
        const collapsed = deepCopy(headerCollapsed);
        collapsed[collapsed.length - 1] = false;
        setHeaderCollapsed(collapsed);
        setChecklistData(checklistDataCopy);
        setNewListItemName('');
        setFocusedCheckpoint('');
    }

    const deleteSelected = (): void => {
        const checklistDataCopy = deepCopy(checklistData);
        for (let headerIndex = 0; headerIndex < checklistDataCopy.headers.length; headerIndex++) {
            for (let checkpointIndex = 0; checkpointIndex < checklistDataCopy.headers[headerIndex].checkpoints.length; checkpointIndex++) {
                const checkpoint = checklistDataCopy.headers[headerIndex].checkpoints[checkpointIndex];
                if (checkpoint) {
                    const id = checkpoint.id;
                    if (checkedCheckpoints[id] === true) {
                        checklistDataCopy.headers[headerIndex].checkpoints.splice(checkpointIndex, 1);
                        checkpointIndex--;
                    }
                }
            }
        }
        setFocusedCheckpoint(null);
        setChecklistData(checklistDataCopy);
        props.doSave(checklistDataCopy, false, false);
        setCheckedCheckpoints({});
    }

    const deleteHeader = (headerIndex: number): void => {
        const checklistDataCopy = deepCopy(checklistData);
        checklistDataCopy.headers.splice(headerIndex, 1);
        setFocusedCheckpoint(null);
        setChecklistData(checklistDataCopy);
        props.doSave(checklistDataCopy, false, false);
    }

    const duplicateSelectedCheckpoints = (): void => {
        const checkedCheckpointsCopy = deepCopy(checkedCheckpoints)
        const checklistDataCopy = deepCopy(checklistData);
        checklistData.headers.forEach((header, headerIndex: number) => {
            header.checkpoints.forEach((checkpoint) => {
                if (checkedCheckpointsCopy[checkpoint.id]) {
                    const duplicate = duplicateCheckpoint(checkpoint);
                    checklistDataCopy.headers[headerIndex].checkpoints.push(duplicate);
                    checkedCheckpointsCopy[checkpoint.id] = false;
                }
            })
        })
        setChecklistData(checklistDataCopy);
        setCheckedCheckpoints(checkedCheckpointsCopy);
        props.doSave(checklistDataCopy, false, true);
        setCheckedCheckpoints({});
    }

    const duplicateHeader = (index: number): void => {
        const checklistDataCopy = deepCopy(checklistData);
        const duplicate = deepCopy(checklistDataCopy.headers[index]);
        duplicate.id = createId();
        duplicate.checkpoints.forEach((checkpoint) => {
            checkpoint.id = createId();
        })
        checklistDataCopy.headers.push(duplicate);
        setChecklistData(checklistDataCopy);
        props.doSave(checklistDataCopy, false, true);
    }

    const moveCheckpoint = (from: number, to: number): void => {
        const checklistDataCopy = deepCopy(checklistData);

        let focusedCheckpointId: string;
        if (focusedCheckpoint) {
            const focusHeader = parseInt(focusedCheckpoint.split(',')[0]);
            const focusPoint = parseInt(focusedCheckpoint.split(',')[1]);
            focusedCheckpointId = checklistDataCopy.headers[focusHeader].checkpoints[focusPoint].id;
        }
        if (itemsMeta[from].type === 'header') {
            //Move header to header
            checklistDataCopy.headers = moveArrayItem(checklistDataCopy.headers, itemsMeta[from].headerIndex, itemsMeta[to].headerIndex);
            const collapsed = deepCopy(headerCollapsed);
            const fromC = collapsed[itemsMeta[from].headerIndex];
            collapsed[itemsMeta[from].headerIndex] = collapsed[itemsMeta[to].headerIndex];
            collapsed[itemsMeta[to].headerIndex] = fromC;
            setHeaderCollapsed(collapsed);
        }
        else {
            if (itemsMeta[from].headerIndex === itemsMeta[to].headerIndex && itemsMeta[to].type === 'checkpoint') {
                //move checkpoint in header
                const headerIndex = itemsMeta[from].headerIndex;
                checklistDataCopy.headers[headerIndex].checkpoints = moveArrayItem(checklistDataCopy.headers[headerIndex].checkpoints, itemsMeta[from].checkpointIndex, itemsMeta[to].checkpointIndex);
            }
            else {
                //Move checkpoint from one haeder to another.
                let toHeaderIndex = itemsMeta[to].headerIndex;
                let toCheckpointIndex = itemsMeta[to].checkpointIndex;
                if (itemsMeta[to].type === 'checkpoint') {
                    toCheckpointIndex++;
                }
                if (itemsMeta[to].type === 'header' && to > 0 && from > to) {
                    toHeaderIndex = toHeaderIndex - 1;
                    toCheckpointIndex = checklistDataCopy.headers[toHeaderIndex].checkpoints.length;
                }
                const checkpoint = checklistDataCopy.headers[itemsMeta[from].headerIndex].checkpoints[itemsMeta[from].checkpointIndex];
                checklistDataCopy.headers[itemsMeta[from].headerIndex].checkpoints.splice(itemsMeta[from].checkpointIndex, 1);
                checklistDataCopy.headers[toHeaderIndex].checkpoints.splice(toCheckpointIndex, 0, checkpoint);
                const newCollapsed = deepCopy(headerCollapsed);
                newCollapsed[toHeaderIndex] = false;
                setHeaderCollapsed(newCollapsed);
            }
        }
        if (focusedCheckpoint) {
            checklistDataCopy.headers.forEach((header, headerIndex) => {
                header.checkpoints.forEach((checkpoint, checkpointIndex) => {
                    if (checkpoint.id === focusedCheckpointId) {
                        setFocusedCheckpoint(createFocusedHeaderString(headerIndex, checkpointIndex));
                    }
                })
            })
        }
        props.doSave(checklistDataCopy, false, false);
        setChecklistData(checklistDataCopy);
    }

    const updateCheckpoint = (updatededCheckpoint, update: boolean): void => {
        const checklistDataCopy = deepCopy(checklistData);
        checklistDataCopy.headers[focusedCheckpoint.split(',')[0]].checkpoints[focusedCheckpoint.split(',')[1]] = updatededCheckpoint;
        setChecklistData(checklistDataCopy);
        props.doSave(checklistDataCopy, false, update);
    }

    const anyCheckpointsChecked = (): number => {
        const keys = Object.keys(checkedCheckpoints);
        let returnValue = 0;
        if (keys.length > 0) {
            keys.forEach((key) => {
                if (checkedCheckpoints[key]) {
                    returnValue++;
                }
            })
        }
        return returnValue;
    }

    const isTranslating = language.code !== 'C';
    const width = focusedCheckpoint ? '50%' : '100%';
    const resetNewHeaderDialog = (): void => {
        setNewHeaderDialog(false);
        setNewHeaderText('');
    }
    const handleNewHeader = (): void => {
        addHeader(newHeaderText);
        resetNewHeaderDialog();
    }

    const openNewHeaderDialog = (): void => {
        setNewHeaderDialog(true)
    }

    const auditOrInspectionChecklist = checklistData && checklistData.type

    const backLink = props.isTemplate ? '/checklists' : orderId ? '/inspections/edit_inspection/' + orderId : '/inspections/';
    return (
        <Fragment>
            <div className='editChecklistContainer'>
                <Fragment><Header as='h1'><FormattedMessage id='checklists.edit_checklist' /></Header>
                    <DocumentTitle title={title} />
                </Fragment>
                <Segment.Group raised>
                    <Segment>
                        <Form size='large'>
                            <Form.Group style={{ width: '100%', margin: '0px' }}>
                                <Form.Group style={{ width: '50%' }}>
                                    {editTitle && checklistData.description != null &&
                                        <Form.Field >
                                            <Input
                                                key={props.checklistId}
                                                type='text'
                                                className='title'
                                                onChange={handleTitle}
                                                onBlur={handleTitleBlur}
                                                value={checklistData.description}
                                            />
                                        </Form.Field>}
                                    {!editTitle &&
                                        <Form.Field>
                                            <Header data-test-id="checklist-name">
                                                {checklistData.description}
                                            </Header>
                                        </Form.Field>
                                    }
                                    {checklistData.description != null && <Form.Field>
                                        <Icon name={'pencil'} onClick={(): void => setEditTitle(!editTitle)} />
                                    </Form.Field>}
                                    {props.checklistData.revision && <Form.Field>
                                        <FormattedMessage id='checklists.editor.revision' />{props.checklistData.revision}
                                    </Form.Field>}
                                </Form.Group>
                                <Form.Field style={{ width: '50%' }}>
                                    <Form.Field style={{ float: 'right' }}>
                                        <List.Item>
                                            {isTranslating && <Fragment><FormattedMessage id='checklists.editor.translating' />{language.name}</Fragment>}
                                            <LanguageSelectionModal
                                                languageSelected={(langauge): void => setLanguage(langauge)}
                                            />
                                        </List.Item>
                                    </Form.Field>
                                </Form.Field>
                            </Form.Group>
                            {editTitle && checklistData.description == '' && <Form.Field ><Icon name='exclamation triangle' color='yellow' />
                                <FormattedMessage id='checklists.empty_description_warning' /></Form.Field>}
                            {props.isTemplate &&
                                <Form.Group inline>
                                    <Form.Field style={{ marginBottom: '15px' }} className='flex'>
                                        <Icon name='info circle' color='blue' />
                                        {
                                            auditOrInspectionChecklist === 'inspection'
                                                ? <div><FormattedMessage id='checklists.changes_to_checkpoints_will_only_affect' /></div>
                                                : null
                                        }
                                        {
                                            auditOrInspectionChecklist === 'audit'
                                                ? <div><FormattedMessage id='checklists.changes_to_checkpoints_will_only_affect_audit' /></div>
                                                : null
                                        }
                                    </Form.Field>
                                </Form.Group>
                            }
                            <Form.Group inline>
                                <Form.Field style={{ width: '100%', paddingRight: '0px' }}>
                                    <Button disabled={language.code !== 'C'} data-test-id="create-header-button" onClick={openNewHeaderDialog}><FormattedMessage id='checklists.add_header' /></Button>
                                    {anyCheckpointsChecked() > 0 &&
                                        <Fragment>
                                            <Button onClick={deleteSelected} style={{ float: 'right' }}><FormattedMessage id='checklists.delete_selected' />{' (' + anyCheckpointsChecked() + ')'}</Button>
                                            <Button onClick={duplicateSelectedCheckpoints} style={{ float: 'right' }}><FormattedMessage id='checklists.duplicate_selected' />{' (' + anyCheckpointsChecked() + ')'}</Button>
                                        </Fragment>
                                    }
                                </Form.Field>
                            </Form.Group>
                        </Form>
                        <Grid>
                            <Form className='editChecklist' style={{ width: width }}>
                                <DnDList
                                    items={items}
                                    itemMoved={moveCheckpoint}
                                    enableDrage={enableDrag}
                                />
                            </Form>
                            {focusedCheckpoint &&
                                <CheckpointEditor
                                    checklistType={checklistData.type}
                                    key={findFocusedCheckpoint(checklistData, focusedCheckpoint) && findFocusedCheckpoint(checklistData, focusedCheckpoint).id}
                                    arrowRef={arrowRef}
                                    reffInner={editorInnerRef}
                                    reffOuter={editorRef}
                                    checkpoint={findFocusedCheckpoint(checklistData, focusedCheckpoint)}
                                    save={(data): void => updateCheckpoint(data, false)}
                                    saveAndUpdate={(data): void => updateCheckpoint(data, true)}
                                    language={language.code}
                                    close={closeEditor}
                                />}
                        </Grid>
                        {!props.isFetching && hasNoCheckpoints &&
                            <Grid style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <Item>
                                    <Item.Content className='noCheckpoints'><FormattedMessage id='checklists.this_checklist_has_no_checkpoints' /></Item.Content>
                                </Item>
                            </Grid>
                        }
                        <Dimmer active={waitingForData} inverted>
                            <Loader className='dimLoader'><FormattedMessage id='checklists.loading_data' /></Loader>
                        </Dimmer>
                        <Divider style={{ width: width }} />
                        <Form className='addCheckpoint' onSubmit={(event): boolean => {
                            event.preventDefault();
                            addCheckpoint();
                            return false;
                        }}
                        >
                            <List.Item>
                                <Form.Group>
                                    <Form.Field width={12}>
                                        <Input type='text'
                                            placeholder={formatMessage({ id: 'checklists.enter_checkpoint_text' })}
                                            ref={props.newCheckpointText}
                                            onChange={handleAdd}
                                            value={newListItemName} />{/*TODO: Thor select correct language*/}
                                    </Form.Field>
                                    <Form.Field>
                                        <Button disabled={newListItemName === '' || language.code !== 'C'} data-test-id='create-checkpoint-button'><FormattedMessage id='checklists.add_checkpoint' /></Button>
                                    </Form.Field>
                                </Form.Group>
                            </List.Item>
                        </Form>
                        <div className='flex'>
                            <Button className='text-base py-2' primary onClick={(): void => doSave(true)}><FormattedMessage id='checklists.done' /></Button>
                            {props.isTemplate && <DownloadChecklist checklistId={props.checklistId} checklistDescription={checklistData.description} checklistLanguages={checklistData.languages || ['C']} skipTranslation={checklistData.languages.length < 2} />}
                        </div>
                        <Divider hidden />
                        <Link to={backLink} ><Icon name='chevron left' /><FormattedMessage id='checklists.back_to_checklists' /></Link>
                    </Segment>
                </Segment.Group>
            </div>
            <Modal
                open={newHeaderDialog}
                closeIcon={true}
                onClose={resetNewHeaderDialog}
                size='tiny'
            >
                <Modal.Header>
                    <FormattedMessage id='checklist.add_header_dialog.header' />
                </Modal.Header>
                <Modal.Content>
                    <QarmaInput type='text'
                        onKeyDown={(e): void => {
                            if (e.keyCode === 13) {
                                handleNewHeader();
                            }
                        }}
                        autoHeight
                        placeholder={formatMessage({ id: 'checklist.add_header_dialog.placeholder' })}
                        onChange={(evt, item): void => setNewHeaderText(item.value)}
                        value={newHeaderText}
                        focus={true}
                        data-test-id='create-header-dialog-input'
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleNewHeader} data-test-id='create-header-dialog-ok-button'><FormattedMessage id='inspections.header_dialog.create' /></Button>
                    <Button onClick={resetNewHeaderDialog}><FormattedMessage id='inspections.cancel' /></Button>
                </Modal.Actions>
            </Modal>
        </Fragment >
    );
}

function duplicateCheckpoint(checkpoint: any): any {
    const checkpointCopy = deepCopy(checkpoint);
    checkpointCopy.id = createId();
    return checkpointCopy;
}


function findFocusedCheckpoint(checklistData: any, focusedCheckpoint: string): CheckpointData {
    return checklistData.headers[focusedCheckpoint.split(',')[0]].checkpoints[focusedCheckpoint.split(',')[1]]
}

function makeEmptyCheckpoint(description: string): CheckpointData {
    return {
        description: createLanguageText('C', description),
        id: createId(),
        checkpoint_type: CheckpointType.Default,
        instruction_details: { C: '' },
        instruction_photos: [],

        measurement_tables: undefined,
        barcode_table: undefined,
        multiple_choice: undefined,
        production_unit_custom_fields: { custom_fields: [] },
        supplier_custom_fields: { custom_fields: [] },
        instruction_files: [],

        mandatory: false,
        is_picture_mandatory: false,
        comments_enabled: true,
        pictures_enabled: true,
        status_enabled: true,
        maximum_score: undefined,
        text_inputs: undefined,
        has_spacer: false,
        text_table: undefined,
    };
}

function createLanguageText(languageCode, content): MultiLanguageText {
    return {
        [languageCode]: content
    }
}

function createId(): string {
    const h = randomHex;
    // This patterns matches the UUID's from the backend. Note that the ID's we create here will be discarded by the backend, and new id's will be generated.
    return `${h()}${h()}${h()}${h()}${h()}${h()}${h()}${h()}-${h()}${h()}${h()}${h()}-${h()}${h()}${h()}${h()}-${h()}${h()}${h()}${h()}-${h()}${h()}${h()}${h()}${h()}${h()}${h()}${h()}${h()}${h()}${h()}${h()}`;
}

function randomHex(): string {
    return (Math.floor(Math.random() * 16)).toString(16);
}

function createFocusedHeaderString(headerIndex, checkpointIndex): string {
    return headerIndex.toString() + ',' + checkpointIndex.toString()
}

export default EditChecklist;
