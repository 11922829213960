import DocViewer, { BMPRenderer, CSVRenderer, DocViewerRef, HTMLRenderer, IDocument, JPGRenderer, MSDocRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, VideoRenderer } from '@cyntler/react-doc-viewer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';
import Measure from 'react-measure';
import { useLocation } from 'react-router';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { FileResource } from '../../../../backend_api/models';
import FileCounter from '../../../../base/components/basic/files/FileCounter';
import FileToolBar from '../../../../base/components/basic/files/FileToolBar';
import Button from '../../../../base/ui/components/buttons/Button';
import Icon from '../../../../base/ui/components/icons/Icon';
import { twMerge } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { HeicRenderer } from './renders/HeicRenderer';
import { PdfRenderer } from './renders/PdfRenderer';

type Props = {
    className?: string;
    singleView?: boolean;
    documents?: IDocument[];
    onAddFiles(files: FileResource[]);
    onDeleteFile(fileId: string);
};

const Transform = (item) => {
    return (
        <TransformWrapper

            initialScale={1}
        /* initialPositionX={200}
        initialPositionY={100} */
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <div>
                    <TransformComponent>
                        {item}
                    </TransformComponent>
                </div>
            )}
        </TransformWrapper>
    );
}

const renderers =
    [
        VideoRenderer,
        JPGRenderer,
        CSVRenderer,
        TXTRenderer,
        TIFFRenderer,
        MSDocRenderer,
        HTMLRenderer,
        BMPRenderer,
        PNGRenderer,
        HeicRenderer,    
        PdfRenderer,
        ];

const ComplianceDocumentsViewerDocument = (props: Props): React.ReactElement => {
    const { className, documents, singleView = false, onAddFiles, onDeleteFile } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const docViewerRef = useRef<DocViewerRef>(null);
    const [activeDocument, setActiveDocument] = useState(documents[0]);
    const [currentFileNumber, setCurrentFileNumber] = useState<number>(0);
    const [dimension, setDimension] = useState(null);

    const deleteFile = () => {
        onDeleteFile(documents[currentFileNumber].uri);
    }

    useEffect(() => {
        setActiveDocument(documents[0]);
    }, [documents]);

    const documentCount = documents.length;

    const NoRenderer = ({ document, fileName }) => {
        const fileText = fileName || document?.fileType || "";
        const f = fileText.split('/');
        const fn = f[f.length -1];
        if (fileText) {
            return <div className='w-full flex justify-center flex-col items-center gap-4 pt-48'>
                <Icon name='object_file' className='text-6xl'/>
                <a className='link' href={fileText}>{decodeURIComponent(fn)}</a>
                <Button  primary icon='operation_download'><a className='text-white hover:text-white' href={fileText}>Download file</a></Button>
                </div>;
        }

        return <div>No Renderer Error!</div>;
    };
    const docViewer = useMemo(() => {
        return <DocViewer
            className=''
            ref={docViewerRef}
            activeDocument={documents[0]}
            onDocumentChange={(doc) => setActiveDocument(doc)}
            documents={documents}
            pluginRenderers={renderers}
            config={{
                noRenderer: {
                    overrideComponent: NoRenderer,
                },
                header: {
                    disableHeader: true,
                },
            }}

        />
    }, [documents]);

    useHotkeys('left', () => {
        if (currentFileNumber > 0) {
            setCurrentFileNumber(currentFileNumber - 1);
            // setActiveDocument[currentFileNumber - 1];
            docViewerRef.current && docViewerRef.current.prev()
        }
    });
    useHotkeys('right', () => {
        if (currentFileNumber < (documents.length - 1)) {
            setCurrentFileNumber(currentFileNumber + 1)
            // setActiveDocument[currentFileNumber + 1];
            docViewerRef.current && docViewerRef.current.next();
        }
    });


    return <div className={twMerge('bg-white border-x', className)}>
        <TransformWrapper
            wheel={{
                activationKeys: ['Control', 'Meta'],
            }}
            initialScale={1}
        // zoomAnimation={{disabled: true, animationTime: 0}}
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
                useHotkeys("*", event => {
                    if (event.key === "+") {
                        zoomIn();
                    }
                });
                useHotkeys('-', () => {
                    zoomOut();
                });
                useHotkeys('shift+=', () => {
                    resetTransform();
                });


                return <>
                    <Measure

                        bounds
                        onResize={contentRect => {
                            setDimension(contentRect.bounds);
                        }}
                    >{({ measureRef }) => (

                        <div className='flex items-center py-4 px-4 border-b space-x-1 z-10 bg-white sticky top-0 w-full gap-x-4' ref={measureRef}>

                            {documentCount > 1 && <>
                                <Icon name='operation_view-gallery' />
                                <FileCounter
                                    onCountChanged={(n) => {
                                        setCurrentFileNumber(n);
                                    }}
                                    fileCount={documentCount}
                                    currentFileNumber={currentFileNumber}
                                    nextFile={docViewerRef.current && docViewerRef.current.next}
                                    previousFile={docViewerRef.current && docViewerRef.current.prev}
                                /></>}
                            <FileToolBar
                                fileName={decodeURIComponent(activeDocument.fileName)}
                                onAddFiles={onAddFiles}
                                onDeleteFile={deleteFile}
                                onZoomIn={() => zoomIn()}
                                onZoomOut={() => zoomOut()}
                            />
                        </div>
                    )}
                    </Measure>
                    {/* {state.documentLoading && <div className='w-full h-96 bg-purple-300'>LOADING...</div>} */}

                    <TransformComponent>
                        <div className='w-full p-4'>
                            <div className='pos' style={{ width: dimension && (dimension.width - 30) }}>
                                {docViewer}
                            </div>
                        </div>

                    </TransformComponent>
                </>

            }}
        </TransformWrapper>
    </div >
}
export default ComplianceDocumentsViewerDocument;
