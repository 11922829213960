import { format } from 'date-fns';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormField, Modal } from 'semantic-ui-react';
import { AuditStatus, AuditType, ListUsersUser } from '../../../../backend_api/models';
import { Audit, AuditFeaturesEnum } from '../../../../backend_api/models/Audit';
import Loader from '../../../../base/components/Loader';
import Button from '../../../../base/components/basic/Button';
import { Radio } from '../../../../base/components/basic/CheckboxRadio';
import DatePicker from '../../../../base/components/basic/DatePicker3';
import DeleteConfirm from '../../../../base/components/basic/DeleteConfirm';
import Icon from '../../../../base/components/basic/Icon';
import Input from '../../../../base/components/basic/Input';
import { getFeaturesSelector } from '../../../../base/selectors';
import { AppState } from '../../../../base/types';
import { showSnackbar } from '../../../../base/ui/uiSlice';
import { getPrettyDate, validateEmail } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import history from '../../../../store/history';
import { getAuditChecklists } from '../../../checklists/actions/actions';
import AuditChecklistsSelector from '../../../checklists/components/AuditChecklistsSelector';
import { getAuditChecklistsSelector } from '../../../checklists/selectors';
import PageContainer from '../../../globals/components/PageContainer';
import { useHasIndependentProductionUnits } from '../../../productionUnits/hooks/useHasIndependentProductionUnits';
import UsersSelector from '../../../users/components/UsersSelector';
import { getProfileSelector, getUsersById } from '../../../users/selectors';
import { getAuditByIdSelector, getAuditCustomConclusionsSelector, getAuditTypesSelector, getAuditsLoadingSelector } from '../../selectors/auditSelectors';
import { deleteAudit, getAuditById, updateAudit } from '../../slices/auditSlice';
import { NewAuditAttachments } from './NewAuditAttachments';
import NewAuditEditApprovalFlow from './NewAuditEditApprovalFlow';
import NewAuditWatchers from './NewAuditWatchers';
import { SendEmailPreviewModal } from './SendEmailPreviewModal';
import { StartSelfAssignedAuditModal } from './StartSelfAssignedAuditModal';
import { SupplierAndProductionUnitSelector } from './SupplierAndProductionUnitSelector';
import ContextMenu from '../../../../base/ui/components/contextMenu/ContextMenu';

type CreateAuditProps = {
    auditId: string;
};

const AuditEditPage = (props: CreateAuditProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const enterEmailTranslation = intl.formatMessage({ id: 'audits.edit.enter_email_address' });
    const loggedInUserId = useSelector(getProfileSelector).profile.id;

    const independentProductionUnits = useHasIndependentProductionUnits();

    const [triedToSave, setTriedToSave] = React.useState(false);
    const [showExistingUserModal, setShowExistingUserModal] = React.useState(false);
    const [showExternalUserModal, setShowExternalUserModal] = React.useState(false);
    const [showResendEmailModal, setShowResendEmailModal] = React.useState(false);
    const [showStartAuditModal, setShowStartAuditModal] = React.useState(false);

    const auditChecklists = useSelector(getAuditChecklistsSelector);
    const auditTypes = useSelector(getAuditTypesSelector);
    const isLoading = useSelector(getAuditsLoadingSelector);

    const features = useSelector(getFeaturesSelector);
    const users = useSelector(getUsersById);
    const auditFromBackend = useSelector(getAuditByIdSelector);
    const isAuditImmutable = auditFromBackend?.locked || auditFromBackend?.status != AuditStatus.Planned;
    const isUserSelfAssigned = auditFromBackend?.assigned_user?.id == loggedInUserId;

    const userHasEditFeature = (auditFromBackend && auditFromBackend.features.includes(AuditFeaturesEnum.Edit)) || false;
    const userHasDeleteFeature = (auditFromBackend && auditFromBackend.features.includes(AuditFeaturesEnum.Delete)) || false;
    const canAssignAuditViaLink = auditFromBackend?.features?.includes(AuditFeaturesEnum.AssignAuditViaLink);

    const conclusions = useSelector((state: AppState) => getAuditCustomConclusionsSelector(state, auditFromBackend && auditFromBackend.audit_type && auditFromBackend.audit_type.id));
    const hasConclusions = conclusions.length > 0;

    const hasAssignedUser = !!auditFromBackend?.assigned_user;
    const isAssignedUserExternal = hasAssignedUser && !users[auditFromBackend?.assigned_user?.id];

    const selectedSupplierId = auditFromBackend?.target_supplier?.id;
    const selectedProductionUnitId = auditFromBackend?.target_production_unit?.id;
    const [selectedAuditTypeId, setSelectedAuditTypeId] = React.useState(auditFromBackend?.audit_type?.id);
    const [selectedAssignedUser, setSelectedAssignedUser] = React.useState(auditFromBackend?.assigned_user?.id);
    const [assigningToExternalUser, setAssigningToExternalUser] = React.useState(false);

    const [externalEmail, setExternalEmail] = React.useState(auditFromBackend?.assigned_user?.email);
    const [reassigning, setReassigning] = React.useState(false);
    const existingUser = Object.keys(users).map(k => users[k])?.filter(user => user.email == externalEmail)[0];
    const isValidEmail = externalEmail && validateEmail(externalEmail);

    const [showEmailError, setShowEmailError] = React.useState(false);

    const plannedDate = auditFromBackend?.planned_date;
    const formattedPlannedDate = getPrettyDate(plannedDate);
    const formattedExpiredDate = calculateExpireTime(plannedDate);

    function doUpdateAudit(audit: Audit) {
        dispatch(updateAudit({audit}));
    }

    function initializeParametersWhenAuditIsLoaded() {
        if (auditFromBackend && auditFromBackend.id == props.auditId) {
            setSelectedAuditTypeId(auditFromBackend?.audit_type?.id);
            setSelectedAssignedUser(auditFromBackend.assigned_user?.id || null);
            if (isAssignedUserExternal) {
                setExternalEmail(auditFromBackend?.assigned_user?.email);
                setAssigningToExternalUser(true);
            }
        }
    }

    useEffect(() => {
        dispatch(getAuditChecklists());
        dispatch(getAuditById({ auditId: props.auditId }))
    }, []);

    useEffect(initializeParametersWhenAuditIsLoaded, [auditFromBackend]);

    useEffect(() => {
        const beforeUnload = (ev) => {
            ev.preventDefault();
            return ev.returnValue = true;
        }

        if(externalEmail) {
            window.addEventListener('beforeunload', beforeUnload)
        }

        return () => window.removeEventListener('beforeunload', beforeUnload);
    }, [externalEmail])

    function onAuditDateChanged(d: Date) {
        if (d) {
            const newDate = format(d, 'yyyy-MM-dd');
            doUpdateAudit({
                ...auditFromBackend,
                planned_date: new Date(newDate)
            })
        } else {
            doUpdateAudit({
                ...auditFromBackend,
                planned_date: null
            })
        }
    }

    function onUserWantsToSave() {
        setTriedToSave(true);
        setReassigning(false);
        if (isSaveable()) {
            doUpdateAudit(auditFromBackend);
            if (isUserSelfAssigned && features?.includes('t_web_based_audits')) {
                setShowStartAuditModal(true);
            } else {
                saveAndGoBack();
            }
        }
    }

    function saveAndNotifyAssignee() {
        setTriedToSave(true);
        setReassigning(false);
        if (auditFromBackend?.checklist_id && !!selectedAssignedUser) {
            dispatch(updateAudit({
                audit: auditFromBackend,
                notifyAssignee: true
            }));
            saveAndGoBack();
        }
    }

    function showEditAuditSnackbar() {
        dispatch(showSnackbar({
            hideAfter: 5000,
            content: <FormattedMessage id='audits.edit.audit_has_been_updated' />,
            action: <Button onClick={goToEditAudit} className='px-3 py-1 text-sm'><FormattedMessage id='audits.edit_audit' /></Button>,
        }))
    }

    function goToEditAudit() {
        history.push('/audits/edit_audit/' + auditFromBackend?.id);
    }

    function previewEmail() {
        setTriedToSave(true);
        setReassigning(false);
        setShowEmailError(!isValidEmail);
        if (isSaveable() && externalEmail && isValidEmail) {
            if (existingUser) {
                setShowExistingUserModal(true);
            } else {
                setShowExternalUserModal(true);
            }
        }
    }


    function isSaveable() {
        if (!auditFromBackend?.checklist_id) {
            return false;
        }

        return !isSupplierIdMissing();
    }

    function isSupplierIdMissing() {
        if (independentProductionUnits) {
            return false
        } else {
            return !selectedSupplierId
        }
    }

    function isChecklistInputDisabled() {
        return isAuditImmutable || !userHasEditFeature || isSupplierIdMissing();
    }

    function isAuditorInputDisabled() {
        return isAuditImmutable || !userHasEditFeature || isSupplierIdMissing();
    }

    function isDatePickerInputDisabled() {
        return isAuditImmutable || !userHasEditFeature || isSupplierIdMissing();
    }

    function isAuditTypeInputDisabled() {
        return isAuditImmutable || !userHasEditFeature || isSupplierIdMissing();
    }

    function isAuditAttachmentsInputDisabled() {
        return isSupplierIdMissing() || !userHasEditFeature;
    }

    function isWatcherInputDisabled() {
        // Watchers is an online feature. Even for a locked audit, the auditor will be able to see watchers.
        return isSupplierIdMissing() || !userHasEditFeature;
    }

    function findCurrentChecklist() {
        return auditChecklists && auditChecklists.find((c) => c.id == auditFromBackend?.checklist_id)
    }

    function checklistReferencesSupplier() {
        const checklist = findCurrentChecklist()
        if (checklist) {
            return checklist.references_supplier_custom_fields
        }
        return false
    }

    function checklistReferencesProductionUnit() {
        const checklist = findCurrentChecklist()
        if (checklist) {
            return checklist.references_production_unit_custom_fields
        }
        return false
    }

    function auditHasTargetSupplier(): boolean {
        return !!selectedSupplierId
    }

    function auditHasTargetProductionUnit(): boolean {
        return !!selectedProductionUnitId
    }

    function updateAuditType(typeId: string) {
        if (!isAuditTypeInputDisabled()) {
            setSelectedAuditTypeId(typeId);
            dispatch(updateAudit({
                audit: {
                    ...auditFromBackend,
                    approval_flow: { audit_id: auditFromBackend.id, levels: [] },
                    audit_type: auditTypes?.find(t => t.id == typeId)
                }
            }));
        }
    }

    function startAudit() {
        history.push('/audits/perform_audit/' + auditFromBackend?.id);
        setShowStartAuditModal(false);
    }

    function saveAndGoBack() {
        history.goBack();
        setShowStartAuditModal(false);
        showEditAuditSnackbar();
    }

    const assignExistingUser = <div>
        <FormField>
            <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                <label className='text-sm text-secondary'><FormattedMessage id='audits.edit.assignee' /></label>
            </div>
            {
                triedToSave && !selectedAssignedUser &&
                <div className='flex flex-row inline text-sm text-danger mb-1'>
                    <Icon name='error' />
                    <span className='pl-1'><FormattedMessage id='audits.edit.warning.select_assignee' /></span>
                </div>
            }
            <UsersSelector
                className='py-1 w-full sm:w-2/3'
                disabled={isAuditorInputDisabled()}
                users={users}
                placeholder={intl.formatMessage({ id: 'audits.select_inspector' })}
                showUnassigned
                showExpanded
                showEmailInSelector
                allowMultipleSelection={false}
                dataTestId={'audits-add-inspector'}
                value={[selectedAssignedUser]}
                fluid
                handleChange={({ value }): void => {
                    doUpdateAudit({
                        ...auditFromBackend,
                        assigned_user: users[value] || auditFromBackend.assigned_user
                    })
                }} />
        </FormField>
        <FormField className='mt-4'>
            <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                <label className='text-sm text-secondary' htmlFor='audit-edit-date'><FormattedMessage id='audits.edit.date' /></label>
            </div>
            <div className='py-1 w-full sm:w-2/3'>
                <DatePicker
                    id='audit-edit-date'
                    date={auditFromBackend?.planned_date || null}
                    handleChange={onAuditDateChanged}
                    pastDates={true}
                    name={'planned_date'}
                    disabled={isDatePickerInputDisabled()}
                />
            </div>
        </FormField>
    </div>
    const assignExternalUser = <div>
        <FormField>
            <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                <label className='text-sm text-secondary' htmlFor='audit-edit-external-user-email'><FormattedMessage id='email' /></label>
            </div>
            {
                showEmailError && !isValidEmail &&
                <div className='flex flex-row inline text-sm text-danger mb-1'>
                    <Icon name='error' />
                    <span className='pl-1'><FormattedMessage id='audits.edit.warning.invalid_email' /></span>
                </div>
            }

            <div className='w-full sm:w-2/3 pt-1'>
                <Input
                    id='audit-edit-external-user-email'
                    type="email"
                    name="email"
                    onChange={(e) => setExternalEmail(e.target.value)}
                    onBlur={(e) => {
                        setShowEmailError(!validateEmail(e.target.value));
                    }}
                    value={externalEmail}
                    iconPosition="left"
                    iconClass="text-brand w-12 justify-center flex"
                    placeholder={enterEmailTranslation}
                    disabled={isAuditImmutable}
                />
            </div>
        </FormField>
    </div>

    const beforeEmailIsSent = <div>
        {
            canAssignAuditViaLink &&
            <div className='mb-4'>
                <fieldset className='p-0'>
                    <legend className='visually-hidden'><FormattedMessage id='audits.edit.assignee_type' /></legend>
                    <div className={'flex flex-row px-1 py-2 items-center w-full sm:w-2/3 cursor-pointer'}>
                        <div className='flex justify-start items-center bg-white' >
                            <Radio 
                                checked={!assigningToExternalUser} 
                                disabled={isAuditImmutable} 
                                onChange={() => setAssigningToExternalUser(false)} 
                                label={<FormattedMessage id='audits.edit.existing_user'/>}
                            />
                        </div>
                    </div>
                    <div className={'flex flex-row px-1 py-2 items-center w-full sm:w-2/3 cursor-pointer'}>
                        <div className='flex justify-start items-center bg-white' >
                            <Radio 
                                checked={assigningToExternalUser} 
                                disabled={isAuditImmutable} 
                                onChange={() => setAssigningToExternalUser(true)} 
                                label={<FormattedMessage id='audits.edit.external_user'/>}
                                labelExplanation={<FormattedMessage id='audits.edit.external_user.description'/>}
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
        }
        {
            assigningToExternalUser ? assignExternalUser : assignExistingUser
        }
    </div>

    const afterEmailIsSent = <div>
        <div className='bg-gray-100 px-4 py-4 mb-4 flex flex-wrap justify-start items-start w-full md:w-1/2 rounded-md'>
            <Icon name='forward_to_inbox' className='self-start text-2xl mr-2' />
            <div className='flex flex-col'>
                <FormattedMessage id='audits.edit.email_assigned_to_external_user' />
                <div className='font-bold'>{externalEmail}</div>
            </div>
        </div>
        <div className='bg-gray-100 px-4 py-4 flex flex-row justify-start items-start w-full md:w-1/2 rounded-md'>
            <Icon name='calendar_month' className='self-start  text-2xl mr-2' />
            <div>
                <FormattedMessage
                    id='audits.edit.email_sent_with_expired_time'
                    values={{
                        sentDate: <b>{` ${formattedPlannedDate} `}</b>,
                        expireDate: <b>{` ${formattedExpiredDate} `}</b>
                    }}
                />
            </div>
        </div>
    </div>

    const sidePanel = <div>
        <div className="border bg-white py-4 px-4 rounded mb-2">
            <div className='sm:text-base text-lg font-bold mb-1'><FormattedMessage id='watchers.watchers' /></div>
            <NewAuditWatchers
                watchers={auditFromBackend?.watchers || []}
                auditId={auditFromBackend?.id}
                noContentSection={true}
                isReport={false}
                className='py-0'
                disabled={isWatcherInputDisabled()} />
        </div>
        {
            hasConclusions &&
            <div className="border bg-white py-4 px-4 rounded mb-2">
                <div className='sm:text-base text-lg font-bold mb-1'><FormattedMessage id='approval.approvers' /></div>
                <NewAuditEditApprovalFlow
                    audit={auditFromBackend}
                />
            </div>
        }
        <NewAuditAttachments
            className='w-full mb-2'
            isReport={false}
            auditId={auditFromBackend?.id}
            attachments={auditFromBackend?.attachments}
            disabled={isAuditAttachmentsInputDisabled()} />
    </div>

    return <PageContainer
        header={<FormattedMessage id='audits.edit_audit' />}
    >
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.edit_audit' })} />
        <Loader active={isLoading}>
            <div className='flex flex-wrap-reverse sm:flex-row space-x-0'>
                <div className='sm:w-2/3 w-full sm:pr-6'>
                    <SupplierAndProductionUnitSelector auditId={props.auditId} />
                    <div className="audit-settings border bg-white py-4 px-4 rounded my-2">
                        <div className='text-lg font-bold mb-4'><FormattedMessage id='audits.edit.audit_settings' /></div>
                        <fieldset className='p-0'>
                            <legend className='text-sm text-secondary w-full sm:w-2/3 mb-1'><FormattedMessage id='audits.audit_type' /></legend>
                            {
                                sortAuditTypes(auditTypes).map((auditType, index) => {
                                    const chosen = selectedAuditTypeId == auditType.id;
                                    return <div key={index} className={'flex flex-row items-center w-full sm:w-2/3 px-1 py-2'}>
                                        <Radio 
                                            checked={chosen} 
                                            disabled={isAuditTypeInputDisabled()} 
                                            onChange={() => updateAuditType(auditType.id)} 
                                            label={auditType.name}
                                        />
                                    </div>
                                })
                            }
                        </fieldset>

                        <FormField className='mt-4'>
                            <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                                <label className='text-sm text-secondary'><FormattedMessage id='audit_list.header.checklist' /></label>
                            </div>
                            {
                                triedToSave && !auditFromBackend?.checklist_id &&
                                <div className='flex flex-row inline text-sm text-danger mb-1'>
                                    <Icon name='error' />
                                    <span className='pl-1'><FormattedMessage id='audits.edit.warning.select_checklist' /></span>
                                </div>
                            }

                            <AuditChecklistsSelector
                                className='w-full sm:w-2/3 py-1'
                                checklists={auditChecklists}
                                checklistId={auditFromBackend?.checklist_id}
                                onChecklistIdChanged={(checklistId) => {
                                    doUpdateAudit({
                                        ...auditFromBackend,
                                        checklist_id: checklistId
                                    });
                                }}
                                showUnassigned
                                disabled={isChecklistInputDisabled()}
                                dataTestId={'audits-add-checklist'}
                            />
                            {
                                checklistReferencesProductionUnit() && !auditHasTargetProductionUnit() ? <NoProductionUnitWarning /> : null
                            }
                            {
                                checklistReferencesSupplier() && !auditHasTargetSupplier() ? <NoSupplierWarning /> : null
                            }
                        </FormField>

                    </div>
                    <div className='sm:w-2/3 w-full sm:pr-6 sm:hidden'>
                        {sidePanel}
                    </div>
                    <div className="assignee-and-date border bg-white py-4 px-4 rounded my-2">
                        <div className='flex flex-row justify-between items-center mb-4'>
                            <div className='text-lg font-bold'><FormattedMessage id='audits.edit.assignee_and_date' /></div>
                            { isAssignedUserExternal && 
                                <ContextMenu
                                    className='h-fit-content'
                                    actions={
                                        reassigning ? [
                                            {
                                                label: 'globals.confirmation.cancel',
                                                labelLeft: true,
                                                onPress: () => setReassigning(false)
                                            }
                                        ] :
                                        [
                                            {
                                                label: 'audits.edit.resend_email',
                                                labelLeft: true,
                                                onPress: () => setShowResendEmailModal(true)
                                            },
                                            {
                                                label: 'audits.edit.edit_assignee',
                                                labelLeft: true,
                                                icon: 'operation_edit',
                                                onPress: () => setReassigning(true)
                                            },
                                        ]
                                    }
                                />
                            }
                        </div>

                        {isAssignedUserExternal && !reassigning ? afterEmailIsSent : beforeEmailIsSent}
                    </div>
                    
                </div>
                <div className='sm:w-1/3 sm:block w-full hidden mb-6'>
                    {sidePanel}
                </div>
            </div>
            <div className='sm:w-2/3 sm:pr-6 w-full flex flex-row py-4 w-full justify-between'>
                {
                    assigningToExternalUser ?
                        <Button className={`justify-center ${isValidEmail ? 'text-white' : 'bg-gray-100 text-gray'}`} primary={isValidEmail} onClick={previewEmail}><FormattedMessage id='audits.edit.preview_and_send_email' /></Button> :
                        <div className='flex flex-row justify-start gap-3'>
                            <Button className="justify-center text-white" primary onClick={onUserWantsToSave}><FormattedMessage id='audits.edit.save_audit' /></Button>
                            <Button className={`justify-center ${!!selectedAssignedUser ? 'text-white' : 'text-secondary hover:text-secondary'}`} secondary={!!selectedAssignedUser} onClick={saveAndNotifyAssignee}><FormattedMessage id='audits.edit.save_and_notify_assignee' /></Button>
                        </div>
                }

                {userHasDeleteFeature &&
                    <DeleteConfirm
                        deleteFunction={() => dispatch(deleteAudit({ auditId: props.auditId, isReport: false }))}
                        alert
                        trigger={
                            <Button className="flex flex-row justify-center items-center bg-white" onClick={null}>
                                <Icon name='delete' className='text-lg' />
                                <span><FormattedMessage id='audits.edit_audit.delete_audit' /></span>
                            </Button>
                        }
                    />
                }
                <AssignToExistingUserModal showing={showExistingUserModal} user={existingUser} cancel={() => setShowExistingUserModal(false)} doAssign={onUserWantsToSave} />
                <SendEmailPreviewModal showing={showResendEmailModal} closeModal={() => setShowResendEmailModal(false)} audit={auditFromBackend} isResending={true}/>
                {
                    canAssignAuditViaLink &&
                    <SendEmailPreviewModal
                        showing={showExternalUserModal}
                        email={externalEmail}
                        audit={auditFromBackend}
                        closeModal={() => setShowExternalUserModal(false)}
                        isResending={false}
                    />
                }
                <StartSelfAssignedAuditModal
                    showing={showStartAuditModal}
                    start={startAudit}
                    cancel={saveAndGoBack}
                />
            </div>
        </Loader>
    </PageContainer>
}

function AssignToExistingUserModal(props: {
    showing: boolean,
    doAssign: () => void,
    cancel: () => void,
    user: ListUsersUser
}) {
    const { showing, doAssign, cancel, user } = props;

    return <Modal
        open={showing}
        closeOnDimmerClick
        closeOnEscape
        size='tiny'>
        <Modal.Header><FormattedMessage id='audits.edit.assign_to_existing_user' /></Modal.Header>
        <Modal.Content>
            <div className='mb-4'><FormattedMessage id='audits.edit.assign_to_existing_user_confirm' /></div>
            <div className='font-bold'>{user?.firstname} {user?.lastname}</div>
            <div>{user?.email}</div>
        </Modal.Content>
        <Modal.Actions className='flex flex-row justify-end my-2'>
            <Button className='' primary onClick={doAssign}><FormattedMessage id='audits.edit.assign_to_user' /></Button>
            <Button className='' onClick={cancel}><FormattedMessage id='globals.confirmation.cancel' /></Button>
        </Modal.Actions>
    </Modal>
}

export default AuditEditPage;

function NoSupplierWarning() {
    return <div className='pt-1'><div className='inline mt-2 bg-attention text-sm py-0 pl-1'><FormattedMessage id='audits.edit.audit_missing_supplier_required_by_checklist' /></div></div>
}

function NoProductionUnitWarning() {
    return <div className='pt-1'><div className='inline mt-2 bg-attention text-sm py-0 pl-1'><FormattedMessage id='audits.edit.audit_missing_production_unit_required_by_checklist' /></div></div>
}

function sortAuditTypes(auditTypes: AuditType[]): AuditType[] {
    const copiedAuditTypes = JSON.parse(JSON.stringify(auditTypes)) as AuditType[];
    if (!copiedAuditTypes) {
        return [];
    }

    return copiedAuditTypes.sort((a, b) => a.name >= b.name ? 1 : -1);
}

export function calculateExpireTime(startTime: Date): string {
    const linkExpiredDate = new Date();
    linkExpiredDate.setDate(new Date(startTime).getDate() + 14);
    return getPrettyDate(linkExpiredDate);
}

