import React from 'react';
import { Button, FileTrigger } from 'react-aria-components';
import { Sizes } from '../../../base/components/types';
import { ComponentOrStringType } from '../../../base/types';
import Icon from '../../../base/ui/components/icons/Icon';
import { Icons } from '../../../base/ui/components/icons/iconTypes';
import { twMerge } from '../../../base/utils';

type FileSelectorProps = {
    className?: string;
    iconClassName?: string;
    label?: ComponentOrStringType;
    multiple?: boolean;
    disabled?: boolean;
    showIcon?: boolean;
    customIcon?: Icons;
    acceptedFileTypes?: string[];
    uploadFiles?(f: FileList): void;
    ref?: React.MutableRefObject<any>;
    id?: string;
    showButton?: boolean
};

const FileSelector = (props: FileSelectorProps): React.ReactElement => {
    const { className, acceptedFileTypes, multiple = true, disabled = false, showIcon, customIcon, ref, label, iconClassName, id, showButton } = props;
    return (<FileTrigger
        allowsMultiple={multiple}
        acceptedFileTypes={acceptedFileTypes && acceptedFileTypes}
        onSelect={(fileList) => {
            props.uploadFiles(fileList);
        }}>
        {showButton &&
            <Button className={twMerge('flex items-center', className)} ref={ref} isDisabled={disabled} id={id}>
                {showIcon && !customIcon && <Icon name='operation_upload' className='mr-1' />}
                {customIcon && <Icon size={Sizes.Small} name={customIcon} className={twMerge('mr-1', iconClassName)} />}
                {label}</Button>
        }
    </FileTrigger>);
}
export default FileSelector;
