import React from 'react';
import { useLocation } from 'react-router';
import TabsMenu from '../../../base/components/TabsMenu';
import { Breakpoints } from '../../../base/config';
import { getLocationEntry, useMinWidthBreak } from '../../../base/utils';
import { DateFilter } from '../../filters/components';
import SelectedFilters from '../../filters/components/SelectedFilters';
import { setCorrectiveActionListDate } from '../correctiveActionsSlice';
import CreateCorrectiveActionOnSupplier from './CreateCorrectiveActionOnSupplier';

type Props = {
    className?: string;
    hideNewButton?: boolean;
};

const CorrectiveActionsSharedTabsMenu = (props: Props): React.ReactElement => {
    const { className, hideNewButton = false } = props;
    const location = useLocation();
    const isMinMD = useMinWidthBreak(Breakpoints.MD);
    const isMinLG = useMinWidthBreak(Breakpoints.LG);
    const preset = getLocationEntry(location, 'preset')[0] || 'all';
    return <div className='flex flex-col md:flex-row md:space-x-4 md:items-center mb-4'>
        <div className='flex justify-between items-center w-full'>
            <div className='flex items-center space-x-4'>
                <TabsMenu
                    active={preset}
                    path=''
                    items={[
                        { labelId: 'corrective_actions.filter_tabs.all', query: { preset: 'all' }, id: 'all' },
                        { labelId: 'corrective_actions.filter_tabs.overdue', query: { preset: 'overdue' }, id: 'overdue' },
                    ]} />
                {isMinMD && <DateFilter onChange={setCorrectiveActionListDate} />}
                <SelectedFilters hideDate={isMinLG} showCompact={true} hideEmpty={false} />
            </div>
            {!hideNewButton && <div className='h-fit-content'>
                <CreateCorrectiveActionOnSupplier />
            </div>}
        </div>
    </div>
}
export default CorrectiveActionsSharedTabsMenu;
