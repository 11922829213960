import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Image, Popup } from 'semantic-ui-react';
import { TranslateText } from '../../globals/components';
import { Comment as CommentType } from '../types';

interface CommentTextProps {
    isFUT: boolean;
    isRoot: boolean;
    showTranslatedCommentsInitialMessage: boolean;
    translationVisible: boolean;
    comment: CommentType;
    children?: ReactNode;
    translateOption: boolean;
    showTranslation(): void;
    hideTranslation(): void;
    handleTranslateOption(translate: boolean): void;
    storeTranslateOption(store: boolean): void;
}
const CommentText = (props: CommentTextProps): React.ReactElement => {
    const { isFUT, isRoot, comment, translationVisible } = props;
    const intl = useIntl();
    const yesPleaseLabel = intl.formatMessage({ id: 'comments.translate_yes_please' });
    const noThanksLabel = intl.formatMessage({ id: 'comments.translate_no_thanks' });
    return (
            <div className='break-words whitespace-pre-wrap my-2 text-gray-800 commentText'>
                {isFUT && isRoot && <div className='font-semibold'>{comment.follow_up_task.title}</div>}
                <Popup
                    open={props.showTranslatedCommentsInitialMessage}
                    trigger={<span><TranslateText
                        showTranslatedComments={translationVisible}
                        translateObj={comment.translated_comment}
                        originalLanguage={comment.comment_language}
                        originalText={comment.comment}
                        showToggleActions={true}
                        showTranslation={props.showTranslation}
                    /></span>}
                    className='commentsTranslationsPopup'
                    position='left center'
                >
                    <Popup.Header><FormattedMessage id='comments.translate_translate_header' /></Popup.Header>
                    <Popup.Content><p><FormattedMessage id='comments.translate_automatically' /></p>
                        <Button onClick={(): void => props.storeTranslateOption(true)} positive>{yesPleaseLabel}</Button>
                        <Button onClick={(): void => props.storeTranslateOption(false)} >{noThanksLabel}</Button>
                        <p className='profile'><FormattedMessage
                            id='comments.translate_can_be_changed_on_settings_page'
                            values={{ profilePageLink: <Link to={'/profile'}><FormattedMessage id='comments.translate_profile_page' /></Link> }}
                        /></p>
                    </Popup.Content>
                </Popup>
                {!comment.comment && <span><FormattedMessage id='comments.no_comment_text' /></span>}
                {comment.comment && comment.image_url && <div className='py-2 flex'><Link to={['?imageURL=', comment.image_url].join('')}><Image size='mini' floated='left' src={comment.image_url} rounded /></Link></div>}
                <div>
                    {comment.attachments && comment.attachments.map((attachment, i: number) => {
                        return (<div className='' key={'commentAttachment_' + i}>
                            <a title={attachment.name} href={attachment.url} className='text-gray-600 text-sm underline'>
                                {attachment.name}
                            </a>
                        </div>);
                    })}
                </div>
                {props.children}
            </div>
    );
};

export default CommentText;
