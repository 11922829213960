import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Comment } from 'semantic-ui-react';
import { CorrectiveActionEvent as CorrectiveActionEventType, EventType } from '../../../backend_api/models';
import { getPrettyDatetime, preventDefaultAndStopPropagation } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { addCommentToEvent } from '../correctiveActionsSlice';
import ApproveEventDetails from './ApproveEventDetails';
import AuditsAddedEvent from './AuditsAddedEvent';
import CommentEventDetails from './CommentEventDetails';
import CommentInput from './CommentInput';
import CreatedEventDetails from './CreatedEventDetails';
import DescriptionOfResloutionEvnet from './DescriptionOfResolutionEvent';
import EditEventDetails from './EditEventDetails';
import EvidenceAddedEvent from './EvidenceAddedEvent';
import EvidenceDeletedEvent from './EvidenceDeletedEvent';
import NotifiedOnOverdueEvent from './NotifiedOnOverdueEvent';
import ReopenEvent from './ReopenEvent';
import ResolveEventDetails from './ResolveEventDetails';
import ResponsibleNotifiedEvent from './ResponsibleNotifiedEvent';

type Props = {
    event: CorrectiveActionEventType;
}

const CorrectiveActionEvent = (props: Props) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [reply, setReply] = useState<boolean>(false);
    const getTypeObject = () => {
        if (props.event.event_type == EventType.EditEvent) {
            return <EditEventDetails event={props.event.edit_event} />
        }
        if (props.event.event_type == EventType.CommentEvent) {
            return <CommentEventDetails comment={props.event.comment_event} />
        }
        if (props.event.event_type == EventType.ResolvedEvent) {
            return <ResolveEventDetails event={props.event} />
        }
        if (props.event.event_type == EventType.ApprovedEvent) {
            return <ApproveEventDetails event={props.event} />
        }
        if (props.event.event_type == EventType.CreatedEvent) {
            return <CreatedEventDetails event={props.event} />
        }
        if (props.event.event_type == EventType.EvidenceAddedEvent) {
            return <EvidenceAddedEvent event={props.event.evidence_event} />
        }
        if (props.event.event_type == EventType.EvidenceDeletedEvent) {
            return <EvidenceDeletedEvent event={props.event.evidence_deleted_event} />
        }
        if (props.event.event_type == EventType.ReopenEvent) {
            return <ReopenEvent />
        }
        if (props.event.event_type == EventType.DescriptionOfResolutionEvent) {
            return <DescriptionOfResloutionEvnet event={props.event.description_of_resolution_event} />
        }
        if (props.event.event_type == EventType.ResponsibleNotifiedEvent) {
            return <ResponsibleNotifiedEvent event={props.event.responsible_notified_event} />
        }
        if (props.event.event_type == EventType.NotifiedOnOverdueEvent) {
            return <NotifiedOnOverdueEvent event={props.event.notified_on_overdue_event} />
        }
        if (props.event.event_type == EventType.AuditsAddedEvent) {
            return <AuditsAddedEvent event={props.event.audits_added_event} />
        }
        if (props.event.event_type == EventType.NotifiedEvent) {
            return null;
        }
        else {
            return null; //throw 'Unknown event type: ' + props.event.event_type // This should be a sentry entry and not an exception that breaks the page
        }
    }
    const addComment = (comment: string) => {
        dispatch(addCommentToEvent({
            correctiveActionId: props.event.corrective_action_id,
            requestData: {
                comment: comment,
                event_id: props.event.id,
            }
        }));
        setReply(false);
    }

    const event = getTypeObject();

    return <Fragment>
        {event && <Comment.Group>
            <div className='flex flex-row justify-between mb-2'>
                <Comment.Author as='a' className='text-secondary font-medium'>{props.event.caller.firstname || '-'} {props.event.caller.lastname || '-'}</Comment.Author>
                <Comment.Metadata className='text-sm text-gray-500'>
                    <div>{getPrettyDatetime(props.event.timestamp, intl.locale || null)}</div>
                </Comment.Metadata>
            </div>
            {event}
            <Comment.Actions className='my-1'>
                <Comment.Action className='link noUnderline text-sm'
                    data-test-id='btn-comment-reply'
                    onClick={(e): void => {
                        preventDefaultAndStopPropagation(e);
                        setReply(!reply);
                    }}>
                    <FormattedMessage id='comments.reply' />
                </Comment.Action>
            </Comment.Actions>
            {reply && <CommentInput handleNewComment={addComment} />}
            <Comment.Group className='pl-8 border-l' >
                {props.event.events.map((event) => {
                    return <CorrectiveActionEvent key={event.id} event={event} />
                })}
            </Comment.Group>
        </Comment.Group>}
    </Fragment>
}

export default CorrectiveActionEvent;