import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Image, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../base/components/basic/Button';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import Icon from '../../../base/components/basic/Icon';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { FileResource, FileResourceTypeEnum } from '../../../compliance_api/models';
import LightBox from '../../../lib/ril';

type Props = {
    file: FileResource;
    disabled?: boolean;
    basic?: boolean;
    className?: string;
    deleteItem: (file: FileResource) => void
}

const ComplianceFileItem = (props: Props) => {
    const { file, disabled = false, basic = false, className } = props;
    const [showLB, setShowLB] = useState(false);
    const [deleteFile, setDelete] = useState<FileResource>(undefined);
    
    const getDeleteAction = (type: 'icon' | 'button'): React.ReactElement => {
        return <DeleteConfirm trigger={
            <div className='w-4 text-secondary cursor-pointer items-center' onClick={() => setDelete(file)}>
                {type === 'icon' ?
                    <RemoveIcon className='text-gray-400 hover:text-secondary flex items-center' /> :
                    <Button onClick={(e) => e.preventDefault()} className='text-xs p-1 border-0 hover:text-secondary bg-gray-200'>
                        <FormattedMessage id='globals.confirmation.delete' />
                    </Button>
                }
            </div>}
            deleteFunction={() => props.deleteItem(props.file)} />
    }
    return <Fragment>
        {file.type == FileResourceTypeEnum.Image &&
            <div className={twMerge('flex m-2 flex-col border rounded p-2 pt-4  hover:border-gray-500 hover:shadow-md hover:cursor-pointer w-1/4', className)}>
                <div onClick={() => setShowLB(true)} className='flex justify-center'>
                    <Popup hoverable trigger={<Image src={file.preview_url + '?w=185&h=185'} />}>
                        <div className='link noUnderline'>{file.name}</div>
                        <div className='text-sm'><FormattedMessage id='corrective_actions.evidence.click_to_open_large_view' /></div>
                    </Popup>
                </div>
                {showLB && <LightBox
                    mainSrc={file.preview_url}
                    onCloseRequest={() => setShowLB(false)}
                />}
                <div className='flex justify-between items-center'>
                    <div className='text-secondary text-sm pt-1 truncate' title={file.name}>
                        {file.name}
                    </div>
                    {(!disabled && !basic) && getDeleteAction('icon')}
                </div>
            </div>
        }
        {props.file.type == FileResourceTypeEnum.File &&
            <a className={twMerge('flex items-center w-full h-full space-x-2 justify-between cursor-pointer', className, basic && 'link')} href={props.file.file.download_url}>
                <span className='items-center flex'>
                    {!basic && <Icon className='text-3xl border-0 py-1 pr-1 text-primary' name='description' />}
                    <span className={!basic ? 'link' : 'link'} title={decodeURIComponent(props.file.name)}>
                        {decodeURIComponent(props.file.name)}
                    </span>
                </span>

                {(!disabled && !basic) && <div className='flex w-12'>{getDeleteAction('button')}</div>}
            </a>
        }
    </Fragment >
}

export default ComplianceFileItem;
