/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 92.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FileFileResource,
    FileFileResourceFromJSON,
    FileFileResourceFromJSONTyped,
    FileFileResourceToJSON,
    ImageFileResource,
    ImageFileResourceFromJSON,
    ImageFileResourceFromJSONTyped,
    ImageFileResourceToJSON,
    TextWithTranslation,
    TextWithTranslationFromJSON,
    TextWithTranslationFromJSONTyped,
    TextWithTranslationToJSON,
    VideoFileResource,
    VideoFileResourceFromJSON,
    VideoFileResourceFromJSONTyped,
    VideoFileResourceToJSON,
} from './';

/**
 * PEDING
 * @export
 * @interface FileResource
 */
export interface FileResource {
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof FileResource
     */
    description?: TextWithTranslation | null;
    /**
     * 
     * @type {FileFileResource}
     * @memberof FileResource
     */
    file?: FileFileResource | null;
    /**
     * 
     * @type {string}
     * @memberof FileResource
     */
    id?: string | null;
    /**
     * 
     * @type {ImageFileResource}
     * @memberof FileResource
     */
    image?: ImageFileResource | null;
    /**
     * 
     * @type {string}
     * @memberof FileResource
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileResource
     */
    preview_url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileResource
     */
    type: FileResourceTypeEnum;
    /**
     * 
     * @type {VideoFileResource}
     * @memberof FileResource
     */
    video?: VideoFileResource | null;
}

/**
* @export
* @enum {string}
*/
export enum FileResourceTypeEnum {
    File = 'file',
    Image = 'image',
    Video = 'video'
}

export function FileResourceFromJSON(json: any): FileResource {
    return FileResourceFromJSONTyped(json, false);
}

export function FileResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : TextWithTranslationFromJSON(json['description']),
        'file': !exists(json, 'file') ? undefined : FileFileResourceFromJSON(json['file']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'image': !exists(json, 'image') ? undefined : ImageFileResourceFromJSON(json['image']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'preview_url': !exists(json, 'preview_url') ? undefined : json['preview_url'],
        'type': json['type'],
        'video': !exists(json, 'video') ? undefined : VideoFileResourceFromJSON(json['video']),
    };
}

export function FileResourceToJSON(value?: FileResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': TextWithTranslationToJSON(value.description),
        'file': FileFileResourceToJSON(value.file),
        'id': value.id,
        'image': ImageFileResourceToJSON(value.image),
        'name': value.name,
        'preview_url': value.preview_url,
        'type': value.type,
        'video': VideoFileResourceToJSON(value.video),
    };
}


