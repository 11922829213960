import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Label } from 'semantic-ui-react';
import { CloseIcon } from 'yet-another-react-lightbox';
import { ReportDefect } from '../../../../backend_api/models';
import { Severity } from '../../../../backend_api/models/Severity';
import { setLayoutAttribute } from '../../../../base/baseSlice';
import Button from '../../../../base/components/basic/Button';
import Icon from '../../../../base/components/basic/Icon';
import { Breakpoints } from '../../../../base/config';
import { byId } from '../../../../base/types';
import { twMerge, useIsMobileDevice, useIsTabletDevice, useMaxWidthBreak } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import Comments from '../../../comments/components/Comments';
import WriteComment from '../../../comments/components/WriteComment';
import { showTranslatedCommentsSelector } from '../../../comments/selectors';
import { Comment, CommentTypes } from '../../../comments/types';
import { TranslateText } from '../../../globals/components';
import { SeverityLabel } from '../../../globals/components/Severity';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { DefectType, Inspection } from '../../types';
import { getDefectType } from './DefectsItem';
import ItemsAffected from './ItemsAffected';
import InspectionReportCorrectiveActionEvidenceMarker from './defects/InspectionReportCorrectiveActionEvidenceMarker';

type ImageCommentProps = {
    type: string;
    description: string;
    inspection: Inspection;
    imageComment?: Comment;
    id?: string;
    quantity_affected?: number;
    translated_comment2?: byId<string>;
    whole_lot?: boolean;
    comment2?: string;
    comment?: string;
    severity?: Severity;
    imageUrl?: string;
    defectType?: DefectType;
    defect?: ReportDefect;
    isEvidence?: boolean;
    evidenceImage?: any;
    mediaType?: 'image' | 'video';
};
export type ImageCommentType = {
    type: 'checkpoint' | 'defect';
    description: string;
    inspection: Inspection;
    imageComment?: Comment;
    id?: string;
    quantity_affected?: number;
    translated_comment2?: byId<string>;
    whole_lot?: boolean;
    comment2?: string;
    comment?: string;
    severity?: Severity;
    imageUrl?: string;
    defectType?: DefectType;
    defect?: ReportDefect;
    isEvidence?: boolean;
    evidenceImage?: any;
    mediaType?: 'image' | 'video';
};

export const getImageComment = (item: ImageCommentType, inspection: Inspection) => {
    const meta = <ImageComment
        type={item && item.type || 'defect'}
        description={item && item.description || ''}
        severity={item && item.severity}
        imageUrl={item && item.imageUrl || ''}
        imageComment={item && item.imageComment}
        quantity_affected={item && item.quantity_affected}
        whole_lot={item && item.whole_lot}
        id={item && item.id}
        defectType={item && item.defectType}
        inspection={inspection}
        defect={item && item.defect}
        isEvidence={item && item.isEvidence}
        evidenceImage={item && item.evidenceImage}
        mediaType={item.mediaType}

    />;
    return meta;
}

export const ImageComment = (props: ImageCommentProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const isMobileDevice = useIsMobileDevice();
    const isTabletDevice = useIsTabletDevice();
    const isMobile = useMaxWidthBreak(Breakpoints.SM);

    const [showFull, setShowFull] = useState(!isMobile)
    const [autoFocus, setAutoFocus] = useState(false);
    const showTranslatedComments = useSelector(showTranslatedCommentsSelector);
    const { type, description, imageComment, id, quantity_affected, translated_comment2, comment, comment2, severity, imageUrl, defectType, whole_lot, isEvidence, evidenceImage, mediaType } = props;
    const currentLang = intl.defaultLocale;
    const hasTranslation = translated_comment2 && translated_comment2[currentLang] !== undefined;
    const commentType: CommentTypes = type === 'checkpoint' ? 'checkpoint_' + mediaType as CommentTypes : 'defect_' + mediaType as CommentTypes;
        
    const label = 'report.label.' + commentType;
    const isDefect = commentType === 'defect_image' || 'defect_video';
    const toggleCommentsFull = (full: boolean): void => {
        setShowFull(full)
        dispatch(setLayoutAttribute('bodyFixed', showFull));
        setAutoFocus(full);
    }
    if (showFull) {
        return (
            <div className={twMerge('cursor-move bg-white sm:rounded p-3 w-full ', isMobile && 'top-0')}>
                <h4 className='flex items-center justify-between'><span className='flex'><Icon className=' text-secondary mr-1 -ml-1' name='drag_indicator'/><FormattedMessage id={label} /></span>
                {isMobile && <CloseIcon onClick={(): void => toggleCommentsFull(false)}/>}{/* <Icon className='close' name='delete' onClick={(): void => toggleCommentsFull(false)} /> */}</h4>
                <div className='text-lg font-medium mb-2'>
                    {isEvidence && <InspectionReportCorrectiveActionEvidenceMarker correctiveActionStatus={evidenceImage.ca_status} className='mb-4 rounded' />}
                    {!isEvidence && isDefect && getDefectType(defectType)} {commentType === 'checkpoint_image' && (description || '-')}
                    {isDefect && <div className='pt-1'>
                        <TranslateText

                            showTranslatedComments={showTranslatedComments}
                            translateObj={props.defect && props.defect.translated_description}
                            originalLanguage={props.defect && props.defect.language}
                            originalText={props.defect && props.defect.description}
                            showToggleActions={true}
                        /></div>}
                </div>
                {isDefect && quantity_affected && <ItemsAffected pcs={quantity_affected} all={whole_lot} />}
                {isDefect && <div className='defect-item-LB'><SeverityLabel severity={severity} /></div>}
                <p>{commentType === 'checkpoint_image' && !hasTranslation && <span>{comment ? comment : comment2}</span>}</p>
                <p>{commentType === 'checkpoint_image' && hasTranslation && <span>{translated_comment2[currentLang]}</span>}</p>
                {imageComment && <div className='comment items-center'>
                    <span className=''>
                        <TranslateText
                            showTranslatedComments={true}
                            translateObj={imageComment.translated_comment}
                            originalLanguage={imageComment.comment_language}
                            originalText={imageComment.comment}
                            showToggleActions={true}
                        />
                    </span>
                    <Label horizontal size='mini'><FormattedMessage id='inspections.image_comment' /></Label>
                </div>}
                <HasFeatureAccess type='inspection' feature='create_comments' inspection={props.inspection}>
                    {!isEvidence && <WriteComment id={id} showModal={false} title='' type={commentType} imageUrl={imageUrl} autoFocus={autoFocus} />}
                </HasFeatureAccess>
                <Comments typeId={id} showCollapsed={false} type={commentType} imageUrl={imageUrl} inspection={props.inspection} />
            </div>
        );
    }
    // return <div className='flex top-[85%]  justify-between absolute items-center' onClick={(): void => toggleCommentsFull(false)}>
    return <div className='flex justify-between items-center p-2' onClick={(): void => !showFull && toggleCommentsFull(true)}>
        <HasFeatureAccess type='inspection' feature='create_comments' inspection={props.inspection}>
            <Comments typeId={id} showCollapsed={true} type={commentType} imageUrl={imageUrl} inspection={props.inspection} hideZero={false} />
            <Button onClick={(e): void => { /* preventDefaultAndStopPropagation(e);  */toggleCommentsFull(true); }}>Add comment</Button>
        </HasFeatureAccess>
    </div>
};
