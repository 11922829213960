import { Pie } from '@nivo/pie';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import { getPrettyDate } from '../../../base/utils';
import { OrganizedProductRequiredDocumentStateEnum, ProductDeadline, ShallowProductDeadlineStats } from '../../../compliance_api/models';
import { TextItem } from '../../globals/components';

type Props = {
    primaryDeadline?: ProductDeadline | null;
    requirementData: ShallowProductDeadlineStats;
    showEmpty?: boolean;
    w?: number;
    h?: number;
};

const ComplianceRequirementsStatusPie = (props: Props) => {
    const { requirementData, w, h, showEmpty = true, primaryDeadline } = props;
    const labelClass = 'font-normal w-3/4';
    if (requirementData) {
        const total = requirementData.approved + requirementData.rejected + requirementData.ready_for_approval + requirementData.not_ready_for_approval + requirementData.ignored;
        const data = total > 0 ? [
            // TODO: get corrective action status hex color values from config file
            { id: OrganizedProductRequiredDocumentStateEnum.Approved, value: requirementData.approved, color: '#529e64' },
            { id: OrganizedProductRequiredDocumentStateEnum.Rejected, value: requirementData.rejected, color: '#30ae24' },
            { id: OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval, value: requirementData.not_ready_for_approval, color: '#455f94' },
            { id: OrganizedProductRequiredDocumentStateEnum.ReadyForApproval, value: requirementData.ready_for_approval, color: '#FDC35A' },
            // { id: OrganizedProductRequiredDocumentStateEnum.Ignored, value: requirementData.ignored, color: '#8D9AA5' },
        ] : showEmpty ? [{ id: 'none', value: 2, color: '#eee' }] : [{}];
        const pie = <Pie
            borderWidth={0}
            data={data}
            tooltip={() => null}
            isInteractive={false}
            height={h ? h : 35} width={w ? w : 35} innerRadius={.5} enableArcLabels={false} enableArcLinkLabels={false} layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
            colors={{ datum: 'data.color' }} />;

        // TODO Lukas: I am not sure why this was here. If we want this back, we need to change some things with deadline in the product list view and endpoints.
        return <Popup className='w-72' trigger={<span>{pie}</span>}>
            <div className='pb-2 font-bold'>Deadline</div>
            {primaryDeadline && <div className='pb-2'>{primaryDeadline.name}</div>}
            {/* <TextItem labelClass={labelClass} label='Deadline' text={'A very long deadline name - name name. '} /> */}
            {primaryDeadline && <TextItem labelClass={labelClass} label='Date' text={getPrettyDate(primaryDeadline.date)} />}
        </Popup>
    }
    return null;
}

export default ComplianceRequirementsStatusPie