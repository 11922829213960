import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { CorrectiveAction, CorrectiveActionAccessibleFeaturesEnum, CorrectiveActionsState, DescriptionOfResolutionCorrectiveActionRequest, TextWithTranslation } from '../../../backend_api/models';
import Icon from '../../../base/components/basic/Icon';
import { SERVICES_PREFIX } from '../../../base/config';
import { useAppDispatch } from '../../../store';
import AttachmentItemList from '../../attachments/components/AttachmentItemList';
import FileUpload from '../../files/components/FileUpload';
import TranslatedTextField from '../../globals/components/TranslatedTextField';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import LocationMap from '../../inspections/components/LocationMap';
import { deleteCorrectiveActionEvidence, getCorrectiveAction, getCorrectiveActionHistory, updateCorrectiveActionDescriptionOfResolution } from '../correctiveActionsSlice';

type Props = {
    correctiveAction: CorrectiveAction;
    disabled?: boolean;
}
const CorrectiveActionEvidenceSection = (props: Props) => {
    const { correctiveAction } = props;
    const disabled = correctiveAction.state == CorrectiveActionsState.Review || correctiveAction.state == CorrectiveActionsState.Closed || props.disabled || false;
    const intl = useIntl();
    const [descriptionOfResolution, setDescriptionOfResolution] = useState<TextWithTranslation>(null);
    const [editing, setIsEditing] = useState<string>(null);
    const hasAttachments = correctiveAction.evidence.length > 0;
    const dispatch = useAppDispatch();
    const loadCorrectiveAction = () => {
        dispatch(getCorrectiveAction({ correctiveActionId: correctiveAction.id, showLoader: false }));
        dispatch(getCorrectiveActionHistory(correctiveAction.id));
    }
    const deleteEvidence = (fileId: string) => {
        dispatch(deleteCorrectiveActionEvidence({ evidenceId: fileId }));
    }
    const updateResponsibleComment = () => {
        if (descriptionOfResolution !== correctiveAction.description_of_resolution) {
            const request: DescriptionOfResolutionCorrectiveActionRequest = { description_of_resolution: descriptionOfResolution.text }
            dispatch(updateCorrectiveActionDescriptionOfResolution(request));
        }
    }
    useEffect(() => {
        editing !== 'descriptionOfResolution' && setDescriptionOfResolution(correctiveAction.description_of_resolution);
    }, [correctiveAction])

    return <Fragment>
        <Form>
            <Form.Field width={8} inline >
                <label className=''><div className='flex items-center space-x-1 '><Icon className='text-lg text-primary' name='comment' /><span><FormattedMessage id='corrective_actions.evidence.responsible_comment' /></span></div></label>
                <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.UploadEvidence} showDisabled={true}>
                    <TranslatedTextField
                        onFocus={() => setIsEditing('descriptionOfResolution')}
                        type='text-area'
                        value={descriptionOfResolution}
                        onChange={setDescriptionOfResolution}
                        onBlur={
                            () => {
                                setIsEditing('');
                                updateResponsibleComment();
                            }
                        }
                        placeholder={intl.formatMessage({ id: 'corrective_actions.evidence.responsible_comment.no_comment' })}
                        name="description-text-area"
                        disabled={disabled}
                    />
                </HasFeatureAccess>
            </Form.Field>
        </Form>
        {correctiveAction.location && <div className='flex space-x-1 pt-6 items-center'><Icon className='text-lg' name='place' /><LocationMap location={correctiveAction.location} /></div>}

        <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.UploadEvidence} showDisabled={true}>
            {!disabled && <FileUpload
                allowMultiUpload                                
                fileSelectorLabelText={intl.formatMessage({ id: 'file_upload.select_or_drop_files_to_upload' })}
                fileSelectorLabelClass='link noUnderline'
                disabled={disabled}
                showButton={!disabled}
                endpoint={SERVICES_PREFIX + '/corrective_actions/' + correctiveAction.id + '/evidence/'}
                uploadComplete={() => loadCorrectiveAction()}
            >
            </FileUpload>}
            <div className='py-6'>
                {hasAttachments && <HasFeatureAccess type='corrective_action' correctiveAction={correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.UploadEvidence} showDisabled={true}>
                    <AttachmentItemList disabled={disabled} attachments={correctiveAction.evidence} deleteFunction={(id) => deleteEvidence(id)} />
                </HasFeatureAccess>}
            </div>
        </HasFeatureAccess>
    </Fragment >
}

export default CorrectiveActionEvidenceSection;