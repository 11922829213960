import { addMonths, eachMonthOfInterval, eachYearOfInterval, format, getDecade, getMonth, getYear, isValid, Locale } from 'date-fns';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { Icon } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { Breakpoints, DEFAULT_DATE_FORMAT2 } from '../../config';
import InvalidFieldMessage from '../../ui/components/labels/InvalidFieldMessage';
import { getLocaleLanguageString, isUndefinedOrNull, isUndefinedOrNullOrEmptyString, preventDefaultAndStopPropagation, useMaxWidthBreak } from '../../utils';
import Input from './Input';
import './react-datepicker.css';
import RemoveIcon from './RemoveIcon';

type DatePicker2Props = {
    id?: string;
    isRequired?: boolean;
    className?: string;
    showTwoMonths?: boolean;
    pastDates?: boolean;
    showIcon?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    date?: string | Date;
    iconPosition?: 'left' | 'right';
    placeholderKey?: string;
    name?: string;
    startDate?: Date;
    maxDate?: Date;
    minDate?: Date;
    basic?: boolean;
    showClose?: boolean;
    noRemove?: boolean;
    handleChange(date: Date): void;
    handleBlur?(): void;
    onFocus?(): void;
    onBlur?(): void;
};

const DatePicker3 = (props: DatePicker2Props): React.ReactElement => {
    const { id, pastDates = true, handleChange, showIcon = true, iconPosition = 'left', disabled = false, onBlur, placeholderKey, name, showTwoMonths = false, readOnly = false, noRemove = false, isRequired = false,  showClose = true } = props;
    const [date, setDate] = useState(!isUndefinedOrNullOrEmptyString(props.date) ? new Date(props.date) : null);
    const [startDateFoo, setStartDateFoo] = useState(!isUndefinedOrNull(props.date) ? new Date(props.date) : null);
    const [showMonthsSelector, setShowMonthsSelector] = useState(false);
    const [showYearSelector, setShowYearSelector] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(getMonth(date) || getMonth(new Date()));
    const [requiredError, showRequiredError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMaxWidthBreak(Breakpoints.MD);
    const intl = useIntl();
    const localeLang = getLocaleLanguageString(intl.locale);
    const locale = intl.locale;
    const decade = getDecade(new Date());
    const pickerRef = useRef<DatePicker>();
    let l: Locale;
    const [years, setYears] = useState([]);
    try {
        l = require('date-fns/locale/' + locale + '/index').default;
    } catch (ex) {
        l = require('date-fns/locale/' + localeLang + '/index').default;
    }
    registerLocale(localeLang, l);
    let placeholderLabel = intl.formatMessage({ id: 'datepicker.label.select_date' });
    if (placeholderKey) {
        placeholderLabel = intl.formatMessage({ id: placeholderKey });
    }
    const months = eachMonthOfInterval({
        start: new Date(decade, 0),
        end: new Date(decade, 11),
    });
    const getYears = (startYear: number) => {
        const yo = [];
        const years = eachYearOfInterval({
            start: new Date(startYear, 1, 1),
            end: new Date(startYear + 8, 12, 31)
        });
        years.forEach((y) => {
            const value = format(y, 'yyyy', { locale: l });
            yo.push({
                value,
                text: value,
                id: value,
                key: value,
            });
        });
        setYears(yo);
    }
    const getNextYears = () => {
        getYears(parseInt(years.at(-1).value));
    }
    const getPrevYears = () => {
        getYears(parseInt(years.at(0).value) - 11);
    }
    const customInput = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => forwardRef((props: any, ref2) => {
            return <Input
                id={id}
                disabled={disabled}
                className='text-sml'
                type='text'
                icon={showIcon && 'calendar_month'}
                iconPosition={iconPosition}
                iconClass={twMerge('', iconPosition === 'right' ? 'border-l' : 'border-r')}
                readOnly={isMobile}
                onBlur={onBlur}
                autoFocus={true}
                ref={ref2}
                inputClass={isValid(new Date(props.value)) ? '' : 'font-bold'}
                {...props}
            />
        }),
        [date],
    )
    useEffect(() => {
        getYears(decade);
    }, []);
    useEffect(() => {
        isUndefinedOrNullOrEmptyString(props.date) ? setDate(null) : setDate(new Date(props.date));
    }, [props.date])
    const CloseDatepicker = () => (
        <RemoveIcon iconClassName='justify-start flex text-2xl text-secondary' onClick={() => pickerRef.current.setOpen(false)} />
    );
    const DP = <DatePicker
        showTimeInput={isMobile}
        customTimeInput={!noRemove && <CloseDatepicker />} //TODO: Fixme: using timeinput is a hack to show a close button. Better to fork the picker and add a proper button
        ref={pickerRef}
        todayButton={<div className='focus:text-lg mt-4 mb-4 md:mt-0 md:text-sml link noUnderline font-normal'><FormattedMessage id='datepicker.label.today' /></div>}
        renderCustomHeader={({
            date,
            changeYear,
            increaseMonth,
            decreaseMonth,
            changeMonth,
            monthDate,
            customHeaderCount,
        }) => {
            return <div className='flex justify-between p-1'>
                <button
                    className='text-2xl md:text-base p-4 md:p-1 font-bold md:font-normal text-secondary hover:text-primary'
                    onClick={(e) => {
                        showYearSelector ? getPrevYears() : decreaseMonth();
                        setCurrentMonth(getMonth(addMonths(date, -1)));
                        preventDefaultAndStopPropagation(e);
                    }}
                    disabled={disabled}>
                    {customHeaderCount === 0 && <Icon name='chevron left' />}
                </button>
                <div className={twMerge('cursor-pointer flex', (showMonthsSelector || showYearSelector) && 'flex-col')}>
                    {!showMonthsSelector && <div className={twMerge('flex justify-center border-b md:p-1 px-2 py-4 text-4xl md:text-xl w-full font-medium', showYearSelector && 'text-secondary')} onClick={() => {
                        setShowMonthsSelector(true);
                        setShowYearSelector(false);
                    }}>{(!showMonthsSelector) && format(date, 'MMM')}</div>}
                    {showMonthsSelector && <div className='flex flex-wrap px-4 py-2 text-2xl md:text-base w-full md:w-72 justify-center'>
                        {months.map((m) => {
                            return <div className={twMerge('w-16 p-4 md:p-2', currentMonth === getMonth(m) && 'font-bold')} onClick={() => {
                                setShowMonthsSelector(false);
                                changeMonth(getMonth(m));
                            }}
                                key={'m_' + m}>{format(m, 'MMM', { locale: l })}</div>
                        })}
                    </div>}
                    {!showYearSelector && <div className={twMerge('px-2 py-4 border-b md:p-1 justify-center text-4xl md:text-xl font-medium', showMonthsSelector && 'text-secondary')} onClick={() => {
                        setShowYearSelector(true);
                        setShowMonthsSelector(false);
                    }}>
                        {getYear(date)}</div>}
                    {showYearSelector &&
                        <div className='flex w-full flex-wrap p-1 justify-center md:w-72 '>
                            {years.map((y) => {
                                return <div className='px-4 py-2 md:px-4 md:text-base text-2xl' onClick={() => {
                                    setShowYearSelector(false);
                                    changeYear(y.value);
                                }}
                                    key={'y_' + (y.value)}>
                                    {y.value}
                                </div>
                            })}
                        </div>}
                </div>
                <button
                    className='text-2xl md:text-base p-4 md:p-1 font-bold md:font-normal text-secondary hover:text-primary'
                    onClick={(e) => {
                        showYearSelector ? getNextYears() : increaseMonth();
                        setCurrentMonth(getMonth(addMonths(date, 1)));
                        preventDefaultAndStopPropagation(e);
                    }}
                    disabled={false}>
                    {(!showTwoMonths || customHeaderCount > 0) && <Icon name='chevron right' />}
                </button>
            </div >
        }}
        locale={l}
        fixedHeight={true}
        maxDate={props.maxDate}
        minDate={props.minDate || (pastDates === true ? null : new Date())}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => {
            setIsOpen(false);
            setShowMonthsSelector(false);
            setShowYearSelector(false);
            const fd = date !== null ? format(date, DEFAULT_DATE_FORMAT2) : null;
            console.log('fd ', fd)
            const fdFoo = startDateFoo !== null ? format(startDateFoo, DEFAULT_DATE_FORMAT2) : null;
            if (fd !== fdFoo) {
                handleChange && handleChange(startDateFoo);
            }
            setDate(startDateFoo)
        }}
        name={name || null}
        dateFormat={isOpen ? DEFAULT_DATE_FORMAT2 : 'd MMM, y'}
        isClearable={(readOnly || disabled) ? false : true}
        selected={date}
        onChange={d => {
            if (isRequired && d === null) {
                setStartDateFoo(d);
                setDate(null);
                showRequiredError(true);
            } else {
                showRequiredError(false);
                setStartDateFoo(d);
                if (d === null) {
                    setDate(null);
                    handleChange && handleChange(null);
                }
            }
        }}
        onSelect={d => {
            setStartDateFoo(d);
        }}
        onFocus={(e) => preventDefaultAndStopPropagation(e)}
        customInput={
            isOpen ? <NumberFormat
                type='text'
                customInput={customInput}
                autoFocus={true}
                format={'####-##-##'}
                placeholder="YYYY-MM-DD" mask={['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D']}
                disabled={disabled}
                readOnly={disabled || isMobile}
                {...props
                }
            /> : <Input
                
                onIconClick={() => setIsOpen(!isOpen)}
                type='text'
                className={'text-sml'}
                disabled={disabled}
                readOnly={readOnly}
                icon={showIcon && 'calendar_month'}
                iconPosition={iconPosition}
                iconClass={twMerge(isOpen ? 'cursor-default' : 'cursor-pointer', iconPosition === 'right' ? 'border-l' : 'border-r')}
                {...props}
            />
        }
        autoFocus={isOpen ? (isMobile ? false : true) : false}
        withPortal={isMobile}
        placeholderText={placeholderLabel}
        monthsShown={showTwoMonths ? 2 : 1}
        popperPlacement="bottom"        
        {...props}
    />;
    return (
        <div className={!showClose && 'hideClose'}>        
            {DP}
            {requiredError && <InvalidFieldMessage className='pt-2' message='Please select a date'/>}
        </div>
    );

}
export default DatePicker3;
