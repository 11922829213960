import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { FileResource } from "../../../../backend_api/models";
import { useAppDispatch } from '../../../../store';
import AttachmentItemList from '../../../attachments/components/AttachmentItemList';
import FileUpload from '../../../files/components/FileUpload';
import { addAttachmentToAudit, removeAuditAttachment } from '../../slices/auditSlice';

export function NewAuditAttachments(props: {
    auditId: string,
    isReport: boolean,
    disabled: boolean,
    className: string,
    attachments: FileResource[]
}) {
    const { className, auditId, attachments, isReport, disabled } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    return (
        <div className="border bg-white py-4 px-4 rounded">
            <div className='sm:text-base text-lg font-bold mb-1'><FormattedMessage id='audit.attachments.heading'/></div>
            {
                !!attachments &&
                <div className={twMerge('w-full', className)}>
                    <div>
                        <FileUpload
                            disabled={disabled}
                            autoClearAfterUpload={true}
                            returnMultipleWhenAllDone
                            inline={true}
                            fileSelectorShowIcon={true}
                            compact={false}
                            showCompleteMessage={true}
                            fileSelectorLabelText={intl.formatMessage({ id: 'audit.attachments.click_to_add_or_drop' })}
                            fileSelectorLabelClass='py-4 flex justify-center cursor-pointer'
                            uploadComplete={(f: any, files) => dispatch(addAttachmentToAudit({ auditId, attachments: files as FileResource[] }))}
                        >
                            {attachments?.length === 0 && <div className='flex w-full text-secondary text-sm'><FormattedMessage id='audit.attachments.no_attachments' /></div>}
                            <AttachmentItemList disabled={disabled} attachments={attachments} deleteFunction={(id) => dispatch(removeAuditAttachment({ auditId, attachmentId: id, isReport }))} className='space-y-4' />
                        </FileUpload >
                    </div>
                </div>  
            }
        </div>
    )
}