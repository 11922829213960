import React from 'react';
import { useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import StatusPie from '../../../base/ui/components/status/StatusPie';
import { twMerge } from '../../../base/utils';
import { OrganizedProductDeadlineStats } from '../../../compliance_api/models';

type Props = {
    className?: string;
    statistics: OrganizedProductDeadlineStats;
    productName: string;
};

const ComplianceDocumentStatusPie = (props: Props): React.ReactElement => {
    const { className, statistics, productName } = props;
    const intl = useIntl();
    type statsKeys = 'not_ready_for_approval' | 'rejected' | 'ready_for_approval' | 'approved' | 'ignored'; // TODO: get stats keys from model data at some point;
    const colorMap = {
        approved: 'bg-status-approved',
        rejected: 'bg-status-rejected',
        ready_for_approval: 'bg-status-pending',
        not_ready_for_approval: 'bg-status-none-planned',
        ignored: 'bg-status-ignored',
    }
    // Ignored, Approved, Pending, Rejected
    const data = [
        { id: 'statistics.ignored', value: statistics.ignored, color: 'var(--status-ignored)' },
        { id: 'statistics.approved', value: statistics.approved, color: 'var(--status-approved)' },
        { id: 'statistics.ready_for_approval', value: statistics.ready_for_approval, color: 'var(--status-pending)' },
        { id: 'statistics.not_ready_for_approval', value: statistics.not_ready_for_approval, color: 'var(--status-none-planned)' },
        { id: 'statistics.rejected', value: statistics.rejected, color: 'var(--status-rejected)' },        
    ];
    const all = Object.values(statistics).reduce((s, t) => s + t)
    const pie = <StatusPie
        data={data}
        h={35}
        w={35}
    />
    const getStats = (sob: statsKeys) => {
        const value = statistics[sob];
        return <div className='flex justify-between gap-x-2'>
            <div className='flex items-center gap-2 w-9/12'>
                <div className={twMerge(colorMap[sob], 'h-4 rounded-full w-4')}></div>
                <div className=''>{intl.formatMessage({ id: 'compliance.product.state.' + sob })}</div>
            </div>

            <div className='font-bold justify-end flex w-2/12'>{value}</div>
            <div className='ml-1 text-secondary w-1/12 items-center justify-end text-xs flex whitespace-nowrap'>{value > 0 ? ((value / all) * 100).toFixed(1) : 0} %</div>
        </div>
    }
    const popup = <Popup
        className='w-[450px]'
        hoverable
        trigger={<span>{pie}</span>}>
        <div className='flex gap-y-2 flex-col w-96'>
            <div className='pb-3'>Document status for <span className='font-bold break-words'>{productName}</span></div>
            {getStats('not_ready_for_approval')}
            {getStats('rejected')}
            {getStats('ready_for_approval')}
            {getStats('approved')}
            <div className='mt-3'>{getStats('ignored')}</div>
        </div>

    </Popup>
    return popup;
}
export default ComplianceDocumentStatusPie;
