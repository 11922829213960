import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import LoadMore from '../../../base/components/LoadMore';
import Link from '../../../base/components/basic/Link';
import { DEFAULT_LIMIT_LIST_SIZE } from '../../../base/config';
import EmptyState, { EmptyStateType } from '../../../base/ui/components/emptyState/EmptyState';
import { getLocationEntry, urlParamsToArray } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import FiltersList from '../../filters/components/FiltersListNew';
import { hasActiveFiltersSelectorIncludingDate } from '../../filters/selectors';
import PageContainer from '../../globals/components/PageContainer';
import { getCorrectiveActionsFetchingSelector, getCorrectiveActionsMetaDataSelector, getInspectionsCorrectiveActionsListSelector, listInspectionsCorrectiveActions, listInspectionsOverdueCorrectiveActions, setCorrectiveActionListDate } from '../correctiveActionsSlice';
import CorrectiveActionsBasicList from './CorrectiveActionsBasicList';
import CorrectiveActionsSharedTabsMenu from './CorrectiveActionsSharedTabsMenu';

type Props = {
    className?: string;
};

const CorrectiveActionsInspectionsList = (props: Props): React.ReactElement => {
    const { className } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    let filters = {} as any;
    const preset = getLocationEntry(location, 'preset')[0] || 'all';
    const isFetching = useSelector(getCorrectiveActionsFetchingSelector);
    const correctiveActionsData = useSelector(getInspectionsCorrectiveActionsListSelector);
    const hasCorrectiveActions = correctiveActionsData && correctiveActionsData.corrective_actions.length > 0
    const [total, setTotal] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(DEFAULT_LIMIT_LIST_SIZE);
    const [offset, setOffset] = useState<number>(0);
    const hasFilters = useSelector(hasActiveFiltersSelectorIncludingDate);
    const getList = (filters: any) => {
        if (preset == 'me') {
            dispatch(listInspectionsCorrectiveActions(filters))
        }
        else if (preset == 'overdue') {
            dispatch(listInspectionsOverdueCorrectiveActions(filters))
        }
        else {
            dispatch(listInspectionsCorrectiveActions(filters))
        }
    }

    useEffect(() => {
        filters = urlParamsToArray(location.search);
        getList(filters);
    }, [preset, location.search])

    useEffect(() => {
        if (correctiveActionsData && correctiveActionsData.pagination) {
            setOffset(correctiveActionsData.pagination.offset);
            setLimit(correctiveActionsData.pagination.limit);
            setTotal(correctiveActionsData.pagination.total);
            setCount(parseInt(correctiveActionsData.pagination.limit as unknown as string, 10) + parseInt(correctiveActionsData.pagination.offset as unknown as string, 10));
        }
    }, [correctiveActionsData]);

    return <PageContainer
        header={intl.formatMessage({ id: 'corrective_actions.list.inspections.headline' })}
    >
        <div className='flex justify-between'>
            <div className='w-full'>
                <CorrectiveActionsSharedTabsMenu hideNewButton={true} />
                <FiltersList
                    getFilterMetaData={getCorrectiveActionsMetaDataSelector}
                    context=''
                    noStatusAfterClear={true}
                    dateFilterChange={setCorrectiveActionListDate}
                    filters={[
                        'date-filter',
                        'approver-filter',
                        'supplier-filter',
                        'production-unit-filter',
                        'responsible-filter',
                        'ca-state-filter'
                    ]}
                />
            </div>
        </div>
        {!hasCorrectiveActions && <EmptyState
            title={intl.formatMessage({ id: 'corrective_actions.inspections.no_ca_found' })} type={EmptyStateType.Standalone}
            guidance={<div className='gap-6 flex flex-col'>
                {hasFilters && <div><FormattedMessage id='corrective_actions.inspections.try_resetting_filters' values={{ link: <Link to='/inspections/ca'>{intl.formatMessage({ id: 'corrective_actions.inspections.try_resetting_filters_link' })}</Link> }} />.</div>}
                <div><FormattedMessage id='corrective_actions.inspections.havent_created_ca' values={{ link: <Link to='/inspections?status=report'>{intl.formatMessage({ id: 'corrective_actions.inspections.havent_created_ca_link' })}</Link> }} />.</div>
            </div>} />}
        <CorrectiveActionsBasicList correctiveActions={{ pagination: correctiveActionsData && correctiveActionsData.pagination, corrective_actions: correctiveActionsData && correctiveActionsData.corrective_actions }} />
        {total > 0 && <LoadMore
            isLoading={isFetching}
            className='border border-t-0'
            chunk={limit}
            total={total}
            count={count}
            onLoadMore={(limit: number, offset: number) => {
                filters = urlParamsToArray(location.search);
                filters.limit = limit;
                filters.offset = offset;
                getList(filters);
            }} />}
    </PageContainer>
}
export default CorrectiveActionsInspectionsList;
