import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { CreateCorrectiveActionOnInspectionRequest, CreateCorrectiveActionOnSupplierRequest, CreateCorrectiveActionOnSupplierRequestToJSON, DescriptionOfResolutionCorrectiveActionRequest, ListCreateCorrectiveActionRequest, ListCreateCorrectiveActionRequestToJSON, PaginatedCorrectiveActions } from '../../backend_api/models';
import { ApproveCorrectiveAction, ApproveCorrectiveActionToJSON } from '../../backend_api/models/ApproveCorrectiveAction';
import { CorrectiveAction } from '../../backend_api/models/CorrectiveAction';
import { CorrectiveActionHistory, CorrectiveActionHistoryFromJSONTyped } from '../../backend_api/models/CorrectiveActionHistory';
import { EditCorrectiveActionRequest, EditCorrectiveActionRequestToJSON } from '../../backend_api/models/EditCorrectiveActionRequest';
import { ResolveCorrectiveAction, ResolveCorrectiveActionToJSON } from '../../backend_api/models/ResolveCorrectiveAction';
import { request2 } from '../../base/api';
import { setContextData, setLayoutAttribute } from '../../base/baseSlice';
import { DEFAULT_LIMIT_LIST_SIZE } from '../../base/config';
import { AppState, Context } from '../../base/types';
import history from '../../store/history';
import { catchException } from '../errorHandling/handler';
import { convertFiltersToQueryString } from '../filters/util';
import { getInspectionReport } from '../inspections/slices/inspectionReportSlice';
import { sendErrorMessage, sendStatusMessage } from '../messages/actions';
import { CorrectiveActionState, CorrectiveActionsListMeta } from './types';


type CommentOnCAInput = {
    correctiveActionId: string,
    comment: string
}

export const addCommentToCorrectiveAction = createAsyncThunk<void, CommentOnCAInput>(
    'addCommentToCorrectiveAction',
    async (input: CommentOnCAInput, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + input.correctiveActionId + '/comment', { method: 'post', body: JSON.stringify({ comment: input.comment }) });
        if (!rq.ok) {

            console.log('There was an error getting CorrectiveAction ' + input.correctiveActionId);
            dispatch(sendErrorMessage(['error_message.corrective_action.add_comment'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(getCorrectiveActionHistory(input.correctiveActionId))
    });


type CommentOnEventInput = {
    correctiveActionId: string,
    requestData: {
        comment: string,
        event_id: string,
    }
}
export const addCommentToEvent = createAsyncThunk<void, CommentOnEventInput>(
    'addCommentToEvent',
    async (input: CommentOnEventInput, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + input.correctiveActionId + '/comment_on_event', { method: 'post', body: JSON.stringify(input.requestData) });
        if (!rq.ok) {
            console.log('There was an error getting CorrectiveAction ' + input.correctiveActionId);
            dispatch(sendErrorMessage(['error_message.corrective_action.add_comment'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(getCorrectiveActionHistory(input.correctiveActionId))
    });

export const getCorrectiveAction = createAsyncThunk<CorrectiveAction, { correctiveActionId: string, showLoader?: boolean }>(
    'getCorrectiveActionAction',
    async (params: { correctiveActionId: undefined, showLoader: true }, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + params.correctiveActionId, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting CorrectiveAction ' + params.correctiveActionId);
            dispatch(sendErrorMessage(['error_message.corrective_action.get_ca'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data as CorrectiveAction;
    });

export const createCorrectiveActionOnAudit = createAsyncThunk<void, ListCreateCorrectiveActionRequest>(
    'createCorrectiveActionOnAudit',
    async (data: ListCreateCorrectiveActionRequest, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/create_on_audit', { method: 'post', body: JSON.stringify(ListCreateCorrectiveActionRequestToJSON(data)) });
        if (!rq.ok) {
            console.log('There was an error creating the CorrectiveAction...');
            dispatch(sendErrorMessage(['error_message.corrective_action.could_not_be_created'], 3000));
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.corrective_action.was_created_succesfully'], 3000));
            const response = await rq.json() as { ids: string[] };
            // history.push('/ca/details/' + response.ids[0])
        }
    });

export const createCorrectiveActionOnSupplier = createAsyncThunk<void, CreateCorrectiveActionOnSupplierRequest>(
    'createCorrectiveActionOnSupplier',
    async (data: CreateCorrectiveActionOnSupplierRequest, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/create_on_supplier', { method: 'post', body: JSON.stringify(CreateCorrectiveActionOnSupplierRequestToJSON(data)) });
        if (!rq.ok) {
            console.log('There was an error creating the CorrectiveAction...');
            dispatch(sendErrorMessage(['error_message.corrective_action.could_not_be_created'], 3000));
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.corrective_action.was_created_succesfully'], 3000));
        }
        const ca = await rq.json();
        history.push('/ca/details/' + ca.id)
    });
export const createCorrectiveActionOnInspection = createAsyncThunk<CorrectiveAction, { inspectionId: string, list: CreateCorrectiveActionOnInspectionRequest[] }>(
    'createCorrectiveActionOnInspection',
    async (params, { dispatch, getState, rejectWithValue }) => {
        const rq = await request2('corrective_actions/create_on_inspection/' + params.inspectionId, { method: 'post', body: JSON.stringify({ list: params.list }) });
        if (!rq.ok) {
            console.log('There was an error creating the corrective action(s) on the inspection...');
            dispatch(sendErrorMessage(['error_message.corrective_action.create_corrective_actions_on_inspection'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.corrective_action.was_created_succesfully'], 3000));
        dispatch(getCorrectiveActionsOnInspection({ inspectionId: params.inspectionId }));
        dispatch(getInspectionReport({ inspectionId: params.inspectionId, hideFetching: true }));
        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
        return await rq.json();
    });

export const editCorrectiveAction = createAsyncThunk<CorrectiveAction, { id: string, request: EditCorrectiveActionRequest }>(
    'editCorrectiveAction',
    async (input: { id: string, request: EditCorrectiveActionRequest }, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + input.id + '/edit', { method: 'put', body: JSON.stringify(EditCorrectiveActionRequestToJSON(input.request)) });
        if (!rq.ok) {
            console.log('There was an error creating the CorrectiveAction...');
            dispatch(sendErrorMessage(['error_message.corrective_action.edit'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.corrective_action.edit'], 3000));
        dispatch(getCorrectiveActionHistory(input.id));
        const data = await rq.json();
        return data as CorrectiveAction;
    });

export const resolveCorrectiveAction = createAsyncThunk<CorrectiveAction, { id: string, ca: ResolveCorrectiveAction }>(
    'resolveCorrectiveAction',
    async (input: { id: string, ca: ResolveCorrectiveAction }, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + input.id + '/resolve', { method: 'put', body: JSON.stringify(ResolveCorrectiveActionToJSON(input.ca)) });
        if (!rq.ok) {
            console.log('There was an error resolving the CorrectiveAction...');
            dispatch(sendErrorMessage(['error_message.corrective_action.resolve'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.corrective_action.resolved'], 3000));
        dispatch(getCorrectiveActionHistory(input.id));
        const data = await rq.json();
        return data as CorrectiveAction;
    });

export const reopenCorrectiveAction = createAsyncThunk<CorrectiveAction, string>(
    'reopenCorrectiveAction',
    async (corrective_action_id, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + corrective_action_id + '/reopen', { method: 'put' });
        if (!rq.ok) {
            console.log('There was an error reopening the CorrectiveAction...');
            dispatch(sendErrorMessage(['error_message.corrective_action.reopen'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(getCorrectiveActionHistory(corrective_action_id));
        const data = await rq.json();
        return data as CorrectiveAction;
    });

export const notifyResponsible = createAsyncThunk<void, string>(
    'notifyResponsible',
    async (id: string, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + id + '/notify_responsible', { method: 'put' });
        if (!rq.ok) {
            console.log('There was an error notifying the responsible...');
            dispatch(sendErrorMessage(['error_message.corrective_action.notify_responsible'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.corrective_action.responsible_notified'], 3000));
    });

export const getCorrectivationsForAudit = createAsyncThunk<{ data: CorrectiveAction[], auditId: string }, string>(
    'getCorrectivationsForAudit',
    async (id: string, { dispatch, rejectWithValue }) => {
        const rq = await request2('audits/' + id + '/corrective_actions/', { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting CorrectiveActions for audit ' + id);
            dispatch(sendErrorMessage(['error_message.corrective_action.get_ca'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return { data, auditId: id };
    });

export const approveCorrectiveAction = createAsyncThunk<CorrectiveAction, { id: string, request: ApproveCorrectiveAction }>(
    'approveCorrectiveAction',
    async (input: { id: string, request: ApproveCorrectiveAction }, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + input.id + '/approve', { method: 'put', body: JSON.stringify(ApproveCorrectiveActionToJSON(input.request)) });
        if (!rq.ok) {
            console.log('There was an error rejecting the CorrectiveAction...');
            dispatch(sendErrorMessage(['error_message.corrective_action.approve'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
        dispatch(sendStatusMessage(['status_message.corrective_action.approved'], 3000));
        dispatch(getCorrectiveActionHistory(input.id));
        const data = await rq.json();
        return data as CorrectiveAction;
    });

export const approveCorrectiveActionOnInspection = createAsyncThunk<CorrectiveAction, { id: string, reportId: string, request: ApproveCorrectiveAction }>(
    'approveCorrectiveActionOnInspection',
    async (input, { dispatch, rejectWithValue }) => {
        console.log('approveCorrectiveActionOnInspection')
        const rq = await request2('corrective_actions/' + input.id + '/approve', { method: 'put', body: JSON.stringify(ApproveCorrectiveActionToJSON(input.request)) });
        if (!rq.ok) {
            console.log('There was an error rejecting the CorrectiveAction...');
            dispatch(sendErrorMessage(['error_message.corrective_action.approve'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.corrective_action.approved'], 3000));
        dispatch(getCorrectiveActionHistory(input.id));
        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
        dispatch(getInspectionReport({ inspectionId: input.reportId, hideFetching: true }));

        const data = await rq.json();
        return data;
    });

export const listCorrectiveActions = createAsyncThunk<PaginatedCorrectiveActions, any>(
    'listCorrectiveActions',
    async (filters, { dispatch, rejectWithValue }) => {
        filters = renameFilters(filters)
        const limit = filters.limit || DEFAULT_LIMIT_LIST_SIZE;
        const offset = filters.offset || 0;
        delete filters.limit;
        delete filters.offset;
        const filterString = convertFiltersToQueryString(filters);
        const rq = await request2('corrective_actions/list?' + 'limit=' + limit + '&offset=' + offset + '&' + filterString, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error listing CorrectiveActions...');
            dispatch(sendErrorMessage(['error_message.corrective_action.list_ca'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        dispatch(setContextData({ context: Context.CorrectiveActions, metaData: { list: { total: data.pagination.total } } }))
        return (data);
    });

export const listCorrectiveActionsWaitingForMe = createAsyncThunk<PaginatedCorrectiveActions, any>(
    'listCorrectiveActionsWaitingForMe',
    async (filters, { dispatch, rejectWithValue }) => {
        filters = renameFilters(filters)
        const limit = filters.limit || DEFAULT_LIMIT_LIST_SIZE;
        const offset = filters.offset || 0;
        delete filters.limit;
        delete filters.offset;
        const filterString = convertFiltersToQueryString(filters);
        const rq = await request2('corrective_actions/list_waiting_for_user?' + 'limit=' + limit + '&offset=' + offset + '&' + filterString, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting CorrectiveAction waiting for user', rq);
            dispatch(sendErrorMessage(['error_message.corrective_action.list_ca'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        dispatch(setContextData({ context: Context.CorrectiveActions, metaData: { list: { total: data.pagination.total } } }))
        return (data);
    });

export const listOverdueCorrectiveActions = createAsyncThunk<PaginatedCorrectiveActions, any>(
    'listOverdueCorrectiveActions',
    async (filters, { dispatch, rejectWithValue }) => {
        filters = renameFilters(filters)
        const limit = filters.limit || DEFAULT_LIMIT_LIST_SIZE;
        const offset = filters.offset || 0;
        delete filters.limit;
        delete filters.offset;

        const filterString = convertFiltersToQueryString(filters);
        const rq = await request2('corrective_actions/list_overdue?' + 'limit=' + limit + '&offset=' + offset + '&' + filterString, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting CorrectiveAction waiting for user', rq);
            dispatch(sendErrorMessage(['error_message.corrective_action.list_ca'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        dispatch(setContextData({ context: Context.CorrectiveActions, metaData: { list: { total: data.pagination.total } } }))
        return (data);
    });

export const getCorrectiveActionHistory = createAsyncThunk<CorrectiveActionHistory, string>(
    'getCorrectiveActionHistory',
    async (id, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + id + '/history', { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error listing CorrectiveActions...');
            dispatch(sendErrorMessage(['error_message.corrective_action.get_history'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return CorrectiveActionHistoryFromJSONTyped(data, false);
    });

export const deleteCorrectiveAction = createAsyncThunk<void, { id, isICA?: boolean }>(
    'deleteCorrectiveAction',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2('corrective_actions/' + params.id + '/delete', { method: 'put' });
        if (!rq.ok) {
            console.log('There was an error deleting the CorrectiveAction...');
            dispatch(sendErrorMessage(['error_message.corrective_action.delete'], 3000));
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.corrective_action.deleted'], 3000));
            history.push(params.isICA ? '/inspections/ca' : '/ca')
        }
    });

export const deleteCorrectiveActionEvidence = createAsyncThunk<void, { evidenceId: string }, {
    state: AppState;
}>(
    'deleteCorrectiveActionEvidence',
    async ({ evidenceId }, { dispatch, getState, rejectWithValue }) => {
        const correctiveActionId = getState().app.correctiveActions.correctiveAction.id;
        const rq = await request2('corrective_actions/' + correctiveActionId + '/evidence/' + evidenceId, { method: 'delete' });
        if (!rq.ok) {
            console.log('There was an error deleting evidence from the corrective action...');
            dispatch(sendErrorMessage(['error_message.corrective_action.remove_evidence'], 3000));
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.corrective_action.removed_evidence_sucessfully'], 3000));
        }
        dispatch(getCorrectiveAction({ correctiveActionId }));
        dispatch(getCorrectiveActionHistory(correctiveActionId));
    });

export const listInspectionsCorrectiveActions = createAsyncThunk<PaginatedCorrectiveActions, any>(
    'listInspectionsCorrectiveActions',
    async (filters, { dispatch, getState, rejectWithValue }) => {
        filters = renameFilters(filters)
        const limit = filters.limit || DEFAULT_LIMIT_LIST_SIZE;
        const offset = filters.offset || 0;
        delete filters.limit;
        delete filters.offset;
        const filterString = convertFiltersToQueryString(filters);
        const rq = await request2('corrective_actions/list_on_inspections?' + 'limit=' + limit + '&offset=' + offset + '&' + filterString, { method: 'get' });
        // const rq = await request2('corrective_actions/list_on_inspections', {});
        if (!rq.ok) {
            console.log('There was an error getting the inspections corrective actions...');
            dispatch(sendErrorMessage(['error_message.corrective_action.get_inspections_corrective_actions'], 3000));
            rejectWithValue(rq as Response);
        }
        return await rq.json();
    });
export const listInspectionsOverdueCorrectiveActions = createAsyncThunk<PaginatedCorrectiveActions, any>(
    'listInspectionsOverdueCorrectiveActions',
    async (filters, { dispatch, getState, rejectWithValue }) => {
        filters = renameFilters(filters)
        const limit = filters.limit || DEFAULT_LIMIT_LIST_SIZE;
        const offset = filters.offset || 0;
        delete filters.limit;
        delete filters.offset;
        const filterString = convertFiltersToQueryString(filters);
        const rq = await request2('corrective_actions/list_overdue_on_inspections?' + 'limit=' + limit + '&offset=' + offset + '&' + filterString, { method: 'get' });
        // const rq = await request2('corrective_actions/list_on_inspections', {});
        if (!rq.ok) {
            console.log('There was an error getting the overdue inspections corrective actions...');
            dispatch(sendErrorMessage(['error_message.corrective_action.get_inspections_corrective_actions'], 3000));
            rejectWithValue(rq as Response);
        }
        return await rq.json();
    });
export const getCorrectiveActionsOnInspection = createAsyncThunk<CorrectiveAction[], { inspectionId: string }>(
    'getCorrectiveActionsOnInspection',
    async (params, { dispatch, getState, rejectWithValue }) => {
        const rq = await request2('inspections/' + params.inspectionId + '/corrective_actions', {});
        if (!rq.ok) {
            console.log('There was an error getting the corrective actions on the inspection...');
            dispatch(sendErrorMessage(['error_message.corrective_action.get_corrective_actions_on_inspection'], 3000));
            rejectWithValue(rq as Response);
        }
        return await rq.json();
    });

export const updateCorrectiveActionDescriptionOfResolution = createAsyncThunk<CorrectiveAction, DescriptionOfResolutionCorrectiveActionRequest, {
    state: AppState;
}>(
    'updateCorrectiveActionDescriptionOfResolution',
    async (request, { dispatch, getState, rejectWithValue }) => {
        const correctiveActionId = getState().app.correctiveActions.correctiveAction.id;
        const rq = await request2('corrective_actions/' + correctiveActionId + '/description_of_resolution',
            { method: 'put', body: JSON.stringify(request) });
        if (!rq.ok) {
            catchException('updateCorrectiveActionResponsibleComment', {
                endpoint: 'corrective_actions/[correctiveActionId]/responsible_comment',
                request: 'corrective_actions/' + correctiveActionId + '/responsible_comment',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            console.log('There was an error updating the responsible comment...');
            dispatch(sendErrorMessage(['error_message.corrective_action.update_responsible_comment'], 3000));
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.corrective_action.updated_responsible_comment_succesfully'], 3000));
            dispatch(getCorrectiveAction({ correctiveActionId }));
            dispatch(getCorrectiveActionHistory(correctiveActionId));
            const data = await rq.json();
            return data as CorrectiveAction;
        }
    });

const renameFilters = (({
    approver: approver_ids,
    ca_states: states,
    ...rest
}) => ({
    approver_ids,
    states,
    ...rest
}));

const initialState: CorrectiveActionState = {
    correctiveAction: null,
    correctiveActions: null,
    inspectionsCorrectiveActions: null,
    correctiveActionsOnInspection: null,
    isFetching: false,
    auditActions: {},
    isUpdating: false,
    correctiveActionHistory: { history: [] },
    isFetchingHistory: false,
    listFilterDate: undefined
}

export const correctiveActionSlice = createSlice({
    name: 'correctiveAction',
    initialState,
    reducers: {
        setCorrectiveActionListDate: (state, action: { payload: string }): void => { state.listFilterDate = action.payload }
    },
    extraReducers: builder => {
        builder.addCase(getCorrectiveActionHistory.fulfilled, (state, action) => {
            state.correctiveActionHistory = action.payload;
            state.isFetchingHistory = false;
        });
        builder.addCase(getCorrectiveActionHistory.pending, (state) => {
            state.isFetchingHistory = true;
        });
        builder.addCase(getCorrectiveActionHistory.rejected, (state) => {
            state.isFetchingHistory = false;
        });
        builder.addCase(listCorrectiveActions.fulfilled, (state, action) => {
            if (action.payload.pagination.offset > 0) {
                state.correctiveActions.corrective_actions = state.correctiveActions.corrective_actions.concat(action.payload.corrective_actions);
                state.correctiveActions.pagination = action.payload.pagination;
            } else {
                state.correctiveActions = action.payload;
            }
            state.isFetching = false;
        });
        builder.addCase(listCorrectiveActions.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(listCorrectiveActions.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(listCorrectiveActionsWaitingForMe.fulfilled, (state, action) => {
            if (action.payload.pagination.offset > 0) {
                state.correctiveActions.corrective_actions = state.correctiveActions.corrective_actions.concat(action.payload.corrective_actions);
                state.correctiveActions.pagination = action.payload.pagination;
            } else {
                state.correctiveActions = action.payload;
            }
            state.isFetching = false;
        });
        builder.addCase(listCorrectiveActionsWaitingForMe.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(listCorrectiveActionsWaitingForMe.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(listOverdueCorrectiveActions.fulfilled, (state, action) => {
            if (action.payload.pagination.offset > 0) {
                state.correctiveActions.corrective_actions = state.correctiveActions.corrective_actions.concat(action.payload.corrective_actions);
                state.correctiveActions.pagination = action.payload.pagination;
            } else {
                state.correctiveActions = action.payload;
            }
            state.isFetching = false;
        });
        builder.addCase(listOverdueCorrectiveActions.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(listOverdueCorrectiveActions.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getCorrectiveAction.fulfilled, (state, action) => {
            state.correctiveAction = action.payload;
            state.isFetching = false;
        });
        builder.addCase(getCorrectiveAction.pending, (state, action) => {
            state.isFetching = action.meta.arg.showLoader;
        });
        builder.addCase(getCorrectiveAction.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(editCorrectiveAction.fulfilled, (state, action) => {
            state.correctiveAction = action.payload;
            state.isUpdating = false;
        });
        builder.addCase(editCorrectiveAction.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(editCorrectiveAction.rejected, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(updateCorrectiveActionDescriptionOfResolution.fulfilled, (state, action) => {
            state.correctiveAction = action.payload;
            state.isUpdating = false;
        });
        builder.addCase(updateCorrectiveActionDescriptionOfResolution.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(updateCorrectiveActionDescriptionOfResolution.rejected, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(resolveCorrectiveAction.fulfilled, (state, action) => {
            state.correctiveAction = action.payload;
            state.isUpdating = false;
        });
        builder.addCase(resolveCorrectiveAction.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(resolveCorrectiveAction.rejected, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(approveCorrectiveAction.fulfilled, (state, action) => {
            state.correctiveAction = action.payload;
            state.isUpdating = false;
        });
        builder.addCase(approveCorrectiveAction.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(approveCorrectiveAction.rejected, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(approveCorrectiveActionOnInspection.fulfilled, (state, action) => {
            state.correctiveAction = action.payload;
            state.isUpdating = false;
        });
        builder.addCase(approveCorrectiveActionOnInspection.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(approveCorrectiveActionOnInspection.rejected, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(createCorrectiveActionOnAudit.fulfilled, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(createCorrectiveActionOnAudit.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(createCorrectiveActionOnAudit.rejected, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(createCorrectiveActionOnSupplier.fulfilled, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(createCorrectiveActionOnSupplier.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(createCorrectiveActionOnSupplier.rejected, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(getCorrectivationsForAudit.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getCorrectivationsForAudit.fulfilled, (state, action) => {
            state.auditActions[action.payload.auditId] = action.payload.data;
            state.isFetching = false;
        });
        builder.addCase(reopenCorrectiveAction.fulfilled, (state, action) => {
            state.correctiveAction = action.payload;
        });
        builder.addCase(getCorrectiveActionsOnInspection.pending, (state, action) => {
            state.isFetching = true;
        });
        builder.addCase(getCorrectiveActionsOnInspection.fulfilled, (state, action) => {
            state.correctiveActionsOnInspection = action.payload;
            state.isFetching = false;
        });
        builder.addCase(getCorrectiveActionsOnInspection.rejected, (state, action) => {
            state.isFetching = false;
        });

        builder.addCase(listInspectionsCorrectiveActions.pending, (state, action) => {
            state.isFetching = true;
        });
        builder.addCase(listInspectionsCorrectiveActions.rejected, (state, action) => {
            state.isFetching = false;
        });
        builder.addCase(listInspectionsCorrectiveActions.fulfilled, (state, action) => {
            state.inspectionsCorrectiveActions = action.payload;
            state.isFetching = false;
        });
        builder.addCase(listInspectionsOverdueCorrectiveActions.pending, (state, action) => {
            state.isFetching = true;
        });
        builder.addCase(listInspectionsOverdueCorrectiveActions.rejected, (state, action) => {
            state.isFetching = false;
        });
        builder.addCase(listInspectionsOverdueCorrectiveActions.fulfilled, (state, action) => {
            state.inspectionsCorrectiveActions = action.payload;
            state.isFetching = false;
        });
    }
});

export const getListDateSelector = createSelector(
    [(state: AppState): string => state.app.correctiveActions.listFilterDate],
    (defaultDate) => defaultDate,
);
export const getCorrectiveActionsLoadingSelector = createSelector(
    [(state: AppState): boolean => state.app.correctiveActions.isFetching],
    (isFetching) => isFetching,
);
export const getCorrectiveActionsListSelector = createSelector(
    [(state: AppState): PaginatedCorrectiveActions => state.app.correctiveActions.correctiveActions],
    (cas) => cas,
);

export const getInspectionsCorrectiveActionsListSelector = createSelector(
    [(state: AppState): PaginatedCorrectiveActions => state.app.correctiveActions.inspectionsCorrectiveActions],
    (cas) => cas,
);
export const getCorrectiveActionsOnInspectionListSelector = createSelector(
    [(state: AppState): CorrectiveAction[] => state.app.correctiveActions.correctiveActionsOnInspection],
    (cas) => cas,
);
export const getCorrectiveActionsFetchingSelector = createSelector(
    [(state: AppState): boolean => state.app.correctiveActions.isFetching],
    (isFetching) => isFetching,
);

export const getCorrectiveActionsMetaDataSelector = createSelector(
    [(state: AppState): CorrectiveActionsListMeta => {
        const data = state.app.correctiveActions.correctiveActions;
        return { start: 0, per_page: data && data.corrective_actions.length || 0, total: data && data.corrective_actions.length || 0, isFetching: false };
    }],
    (data) => data,
);

export const { setCorrectiveActionListDate } = correctiveActionSlice.actions;

export default correctiveActionSlice.reducer;