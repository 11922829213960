import React, { ReactNode } from 'react';
import { usePress } from 'react-aria';
import { Button as AriaButton, PressEvent } from 'react-aria-components';
import { ButtonProps } from 'react-html-props';
import { FormattedMessage } from 'react-intl';
// import { ButtonProps } from 'react-html-props';
import { Sizes } from '../../../components/types';
import { twMerge } from '../../../utils';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';

export type SIZES =
    | 'xsmall'
    | 'small'
    | 'medium'
    ;

export enum ButtonTypes {
    Plain = 'plain',
    Primary = 'primary',
    Secondary = 'secondary',
    Ghost = 'ghost',
    Danger = 'danger',
    Context = 'context',
};

export enum ButtonSizes {
    XSmall = 'xsmall',
    Small = 'small',
    Medium = 'medium',
};

export type SizeMap = { [SIZE in SIZES]: string; }

export const ButtonSizeMappings: SizeMap = {
    xsmall: 'text-xs',
    small: 'text-sm',
    medium: 'text-base',
};

interface Props extends ButtonProps {
    className?: string;
    iconClassName?: string;
    size?: Sizes.XSmall | Sizes.Small | Sizes.Medium;
    label?: string;
    labelLeft?: boolean;
    children?: ReactNode;
    buttonType?: ButtonTypes;
    icon?: Icons;
    dataTestId?: string;
    value?: string;
    primary?: boolean;
    secondary?: boolean;
    danger?: boolean;
    ghost?: boolean;
    context?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    onPress?(e: PressEvent): void;
}

const  Button = (props: Props): React.ReactElement => {
    const getButtonType = () => {
        if (primary) {
            return ButtonTypes.Primary
        }
        if (secondary) {
            return ButtonTypes.Secondary
        }
        if (danger) {
            return ButtonTypes.Danger;
        }
        if (ghost) {
            return ButtonTypes.Ghost;
        }
        if (context) {
            return ButtonTypes.Context;
        }
        return props.buttonType || ButtonTypes.Plain;
    };

    const { className, size = Sizes.Medium, children, primary, secondary, danger, ghost, context, icon, iconClassName, disabled, label, labelLeft = false, fullWidth = false, ...rest } = props;
    const buttonType = getButtonType();
    const sizeValue = ButtonSizeMappings[size];
    const base = twMerge('rounded-md whitespace-pre items-center justify-center', fullWidth ? 'flex flex-1' : 'inline-flex w-min');
    const paddingMap = {
        medium: 'px-8 py-3',
        small: 'px-5 py-2',
        xsmall: 'px-3 py-1',
    }
    const padding = paddingMap[size];
    const textSizeMap = {
        medium: 'text-base',
        small: 'text-sm',
        xsmall: 'text-xs',
    }
    const textSize = textSizeMap[size];
    const backgroundColorMap = {
        plain: 'bg-surface-plain',
        primary: 'bg-surface-brand',
        secondary: 'bg-surface-secondary',
        danger: 'bg-surface-danger',
        ghost: 'bg-default',
        context: 'bg-surface-interactive',
    }
    const textColorMap = {
        plain: 'text-on-surface-plain',
        primary: 'text-on-surface-brand',
        secondary: 'text-on-surface-secondary',
        danger: 'text-on-surface-danger',
        ghost: 'text-brand',
        context: 'text-on-surface-interactive',
    }
    const iconColorMap = {
        plain: 'text-on-surface-plain',
        primary: 'text-on-surface-brand',
        secondary: 'text-on-surface-secondary',
        danger: 'text-on-surface-danger',
        ghost: 'text-brand',
        context: 'text-on-surface-interactive',
    }
    const borderColorMap = {
        plain: 'border border-default',
        primary: 'border border-default',
        secondary: 'border border-default',
        danger: 'border border-default',
        ghost: 'border-none',
        context: 'border-none',
    }
    const backgroundColor = backgroundColorMap[buttonType ? buttonType : ButtonTypes.Plain];
    const textColor = textColorMap[buttonType ? buttonType : ButtonTypes.Plain];
    const borderColor = borderColorMap[buttonType ? buttonType : ButtonTypes.Plain];

    let { pressProps, isPressed } = usePress({
        onPress: (e) => {
            props.onPress && props.onPress(e);
        },
    });
    return <AriaButton {...pressProps} data-test-id={props.dataTestId} isDisabled={disabled} className={twMerge('', base, textSize, sizeValue, padding, backgroundColor, textColor, borderColor, className)}  {...rest} >
        <div className='flex items-center gap-1 leading-none'>
            {label && labelLeft && <FormattedMessage id={label} />}
            {icon && <Icon name={icon} size={size as Sizes} className={iconClassName || iconColorMap[buttonType]} />}
            {children}</div>
    </AriaButton>
}
export default Button;
