import classnames from 'classnames';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Loader from '../../../base/components/Loader';
import TabsMenu from '../../../base/components/TabsMenu';
import Button from '../../../base/components/basic/Button';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import FiltersList from '../../filters/components/FiltersList';
import SelectedFilters from '../../filters/components/SelectedFilters';
import PageContainer from '../../globals/components/PageContainer';
import { ListView, ListViewHeader, ListViewLines } from '../../globals/components/views/list/ListView';
import { getComplianceProductsList } from '../complianceSlice';
import { getComplianceProductsSelector, getComplianceProductsSelectorTodo, isFetchingSelector } from '../selectors/ComplianceSelectors';
import ComplianceProductsItem from './ComplianceProductsItem';

const ComplianceProductsList = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isFetching = useSelector(isFetchingSelector);
    const location = useLocation();
    const isTodo = getLocationEntry(location, 'status')[0] === 'todo';
    const products = isTodo ? useSelector(getComplianceProductsSelectorTodo) : useSelector(getComplianceProductsSelector);
    const history = useHistory();
    const hasProducts = products.length > 0;

    const openProduct = (id: string) => {
        history.push('/compliance/products/' + id);
    };
    const status = getLocationEntry(location, 'status')[0] || 'all';
    useEffect(() => {
        dispatch(getComplianceProductsList(status));
    }, [status])
    return <PageContainer header={<FormattedMessage id='compliance.products_list.title' />}>
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.compliance.products' })} />
        <div className='flex space-x-2'>
            <TabsMenu
                active={status}
                path=''
                items={[
                    { labelId: 'compliance.products_list.tabs.item.all', query: { status: 'all' }, id: 'all' },
                    { labelId: 'compliance.products_list.tabs.item.todo', query: { status: 'todo' }, id: 'todo' },
                ]} />
            <SelectedFilters hideDate={true} showCompact={true} hideEmpty={false} />
        </div>
        <div className='pt-8'>
            <FiltersList
                filters={['supplier-filter', 'compliance-requirements-category-filter']}
            />
        </div>

        <ListView className='pt-8'>
            <Loader active={isFetching}>
                {hasProducts && <ListViewHeader
                    className={classnames('top-0')}
                    items={[
                        { label: undefined, className: 'w-1/16', key: 'spacer.1', },
                        { label: intl.formatMessage({ id: 'compliance.products_list.header.name' }), className: 'w-6/16', key: 'product_name' },
                        { label: intl.formatMessage({ id: 'compliance.products_list.header.number' }), className: 'w-4/16', key: 'product_number' },
                        { label: undefined, className: 'w-1/16', key: 'product_stats' },
                        { label: intl.formatMessage({ id: 'compliance.products_list.header.deadline' }), className: 'w-4/16', key: 'product_deadline' },
                    ]} />}
                {hasProducts && <ListViewLines className='border border-b-0'>

                    {products.map((product, i) => {
                        return <ComplianceProductsItem product={product} key={'product_item' + product.product_id + i} onClick={openProduct} />;
                    })}
                </ListViewLines>}
                {!hasProducts && <div className='justify-center md:justify-start flex py-8 px-2 md:px-0'><FormattedMessage id='compliance.products_list.no_products_found' /></div>}
            </Loader>
        </ListView>

        <div className='flex justify-center md:justify-start'>
            <Button primary onClick={() => history.push('products/create')}><FormattedMessage id='compliance.products_list.create_product' /></Button>
        </div>
    </PageContainer>
}

export default ComplianceProductsList;