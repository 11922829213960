import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ConclusionConclusionCategoryEnum } from '../../../../backend_api/models';
import { CompleteApprover } from '../../../../backend_api/models/CompleteApprover';
import { Sizes } from '../../../../base/components/types';
import Icon from '../../../../base/ui/components/icons/Icon';
import { Icons } from '../../../../base/ui/components/icons/iconTypes';
import { getLocaleSpecificString } from '../../../../base/utils';
import { MultiLanguageText } from '../../../globals/types';

interface ApprovalStatusProps {
    approval: CompleteApprover;
    disabled?: boolean;
    type: 'notSet' | 'status' | 'statusLabel';
    onChange(type: string, approval: CompleteApprover): void;
    showIcon?: boolean;
}

const ApprovalStatus = (props: ApprovalStatusProps): React.ReactElement => {
    const { disabled = false, approval, type, onChange } = props;
    return ApprovalStatusWrapper({ onClick: () => !disabled && onChange('approvalStep', approval), shortName: approval.approved_status.conclusion.short_name, conclusionCategory: approval.approved_status.conclusion.conclusion_category })
}

export default ApprovalStatus;

export const ApprovalStatusWrapper = (props: {
    disabled?: boolean;
    conclusionCategory?: ConclusionConclusionCategoryEnum;
    shortName?: MultiLanguageText;
    conclusionName?: string,
    showIcon?: boolean,
    onClick?(): any;
}) => {
    const { disabled, conclusionCategory, shortName, onClick, showIcon = true } = props;
    const m = {
        approved: 'bg-status-approved',
        rejected: 'bg-status-rejected',
        pending: 'bg-status-pending',
        not_set: 'bg-status-pending',
    }
    const iconMap: { [id: string]: Icons } = {
        approved: 'status_checked-ok',
        rejected: 'operation_remove',
        pending: 'operation_replace',
        not_set: 'status_pending',
    }

    const cn = twMerge('flex gap-2 w-40 text-xs uppercase rounded h-10 items-center text-white justify-center', disabled && 'noCursor', disabled && 'disabled');
    return <div onClick={onClick ? onClick : null} className={twMerge(cn, m[conclusionCategory])}>
        {showIcon && <Icon size={Sizes.Small} className='font-bold text-on-status' name={iconMap[conclusionCategory]} />}
        {getLocaleSpecificString(shortName)}
    </div>
}

