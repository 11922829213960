import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Header, Popup } from 'semantic-ui-react';
import { FileResource } from '../../../backend_api/models';
import { DEFAULT_FILE_UPLOAD_ENDPOINT, MAX_UPLOAD_FILE_SIZE, WARN_UPLOAD_FILE_SIZE } from '../../../base/config';
import { ComponentOrStringType } from '../../../base/types';
import { Icons } from '../../../base/ui/components/icons/iconTypes';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { AttachmentResponse } from '../../Images/actions/actions';
import { WarnBox } from '../../attachments/components';
import Uploading from '../../attachments/components/Uploading2';
import { UploadingFile } from '../../attachments/types';
import { DropZone } from '../../globals/components';
import { uploadImage } from '../actions';
import FileSelector from './FileSelector';

type Props = {
    header?: string;
    endpoint?: string;
    disabled?: boolean;
    inline?: boolean;
    allowMultiUpload?: boolean;
    hasDrop?: boolean;
    dropPlacement?: 'top' | 'bottom';
    showCompleteMessage?: boolean;
    autoClearAfterUpload?: boolean;
    returnMultipleWhenAllDone?: boolean;
    compact?: boolean;
    fileSelectorLabelText?: ComponentOrStringType;
    fileSelectorLabelClass?: string;
    fileSelectorCustomIconClass?: string;
    fileSelectorShowIcon?: boolean;
    fileSelectorCustomIcon?: Icons;
    fileSelectorRef?: React.MutableRefObject<any>;
    fileSelectorId?: string;
    children?: React.ReactNode;
    className?: string;
    dropClass?: string;
    hideAll?: boolean;
    showButton?: boolean
    acceptedFileTypes?: string[];
    uploadComplete?(file: AttachmentResponse | FileResource, allFiles?: AttachmentResponse[] | FileResource[]);
    uploadStart?();

}

const FileUpload = (props: Props) => {
    const { className, acceptedFileTypes, disabled = false, inline = true, endpoint = DEFAULT_FILE_UPLOAD_ENDPOINT, showCompleteMessage = true, autoClearAfterUpload = false, compact = false, fileSelectorRef, fileSelectorId, fileSelectorLabelClass, fileSelectorCustomIconClass, fileSelectorShowIcon = false, fileSelectorCustomIcon, allowMultiUpload = true, 
        returnMultipleWhenAllDone = false, hideAll = false, dropPlacement = 'top', dropClass, uploadStart, showButton = true } = props;
    let hasDrop = props.hasDrop;
    if (disabled === false) {
        hasDrop = true;
    }

    const dispatch = useAppDispatch();
    const [warnFiles, setWarnFiles] = useState<File[]>([]);
    const [tooBigFiles, setTooBigFiles] = useState<File[]>([]);
    const [okFiles, setOkFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [showWarn, setShowWarn] = useState<boolean>(false);
    const [filesUploading, setFilesUploading] = useState<UploadingFile[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);
    const [completedFiles, setCompletedFiles] = useState<AttachmentResponse[]>([]);
    const [completedFile, setCompletedFile] = useState<AttachmentResponse>(null);

    useEffect(() => {
        if (completedFile) {
            if (props.uploadComplete) {
                if (returnMultipleWhenAllDone) {
                    const f = [...completedFiles, completedFile];
                    if (filesUploading.length === f.length) {
                        props.uploadComplete(completedFile, [...completedFiles, completedFile])
                    }
                } else {
                    props.uploadComplete(completedFile, [...completedFiles, completedFile]);
                }
            }
            setCompletedFiles([...completedFiles, completedFile]);
        }
    }, [completedFile])

    useEffect(() => {
        if (completedFiles.length > 0 && completedFiles.length === filesUploading.length) {
            setIsUploadComplete(true);
            setIsUploading(false);
        }
    }, [completedFiles])

    useEffect(() => {
        if (!isUploading && filesUploading && filesUploading.length > 0) {
            filesUploading.forEach((file: UploadingFile) => {
                uploadFile(file.file);
            });
            setIsUploading(true);
        }
    }, [filesUploading])
    console.log('filesUploading ', filesUploading)
    const handleComplete = (error, attachment: any): void => {
        const _attachment = attachment;
        /* if(_attachment.type === 'image') {
            delete _attachment['file'];        
        } */
        if (!error) {
            setCompletedFile(_attachment);
        } else {
            setCompletedFile({ error: true } as AttachmentResponse);
        }
    }

    const uploadFile = (file: File): void => {
        const endpointAndFile = endpoint + (file.name);
        dispatch(uploadImage(file, setProgress, endpointAndFile, handleComplete));
    }

    const setProgress = (file: File, progress: number): void => {
        const uploadingFile: UploadingFile = filesUploading.find((uploadingFile: UploadingFile) => uploadingFile.file.name === file.name);
        uploadingFile.progress = progress;
        setFilesUploading([...filesUploading]);
    }

    const uploadFiles = (files: FileList): void => {
        const warnFiles: File[] = [];
        const tooBigFiles: File[] = [];
        const okFiles: File[] = [];
        Array.from(files).forEach((file: File) => {
            if (file.size >= MAX_UPLOAD_FILE_SIZE) {
                tooBigFiles.push(file);
            } else {
                if (file.size >= WARN_UPLOAD_FILE_SIZE) {
                    warnFiles.push(file);
                } else {
                    okFiles.push(file);
                }
            }
        });
        if (warnFiles.length > 0 || tooBigFiles.length > 0) {
            setWarnFiles(warnFiles);
            setTooBigFiles(tooBigFiles);
            setOkFiles(okFiles);
            setFiles(Array.from(files));
            setShowWarn(true);
        } else {
            convertAndSetUploadingFiles(Array.from(files))
        }
    }

    const convertAndSetUploadingFiles = (files): void => {
        const uploadingFiles: UploadingFile[] = [];
        console.log('convertAndSetUploadingFiles')
        uploadStart && uploadStart();
        files.forEach((file) => uploadingFiles.push({ status: 'ok', progress: 0, file: file }));
        setFilesUploading(uploadingFiles);
    }

    const clearUploadsHandler = (): void => {
        setFilesUploading([]);
        setCompletedFiles([]);
        setIsUploadComplete(false);
    }

    const handleWarning = (proceed: boolean): void => {
        if (proceed) {
            convertAndSetUploadingFiles(Array.from(files))
        } else {
            setFiles([]);
        }
        setShowWarn(false);
    }

    const reUpload = (files: File[]) => {
        files.forEach((file) => {
            const uploadingFile: UploadingFile = filesUploading.find((uploadingFile: UploadingFile) => uploadingFile.file.name === file.name);
            uploadingFile.status = 'ok';
            uploadingFile.progress = 0
            uploadFile(file);
        })
        setFilesUploading([...filesUploading]);
        setCompletedFiles([]);
        setIsUploadComplete(false);
        setIsUploading(true)
    }

    const fileSelector = <FileSelector
        label={props.fileSelectorLabelText}
        showIcon={fileSelectorShowIcon}
        customIcon={fileSelectorCustomIcon}
        uploadFiles={uploadFiles}
        disabled={isUploading || disabled}
        showButton={showButton}
        ref={fileSelectorRef}
        className={fileSelectorLabelClass}
        iconClassName={fileSelectorCustomIconClass}
        multiple={allowMultiUpload}
        id={fileSelectorId}
        acceptedFileTypes={acceptedFileTypes}

    />;
    const content = <Fragment>
        {props.children}
        <Uploading
            files={filesUploading}
            isComplete={isUploadComplete}
            clearUploadsHandler={clearUploadsHandler}
            showCompleteMessage={showCompleteMessage}
            autoClear={autoClearAfterUpload}
            compact={compact}
            inline={inline}
            reUpload={reUpload}
        />
        <WarnBox
            tooBigFiles={tooBigFiles}
            warnFiles={warnFiles}
            okFiles={okFiles}
            actionHandler={handleWarning}
            showWarnBox={showWarn}
            canRemoveWarnFile={false}
        />
    </Fragment>;
    const dropWrappedContentTmp = hasDrop ? <DropZone onDrop={(f) => uploadFiles(f)}><span className={dropClass}>{content}</span></DropZone> : <div>{content}</div>;
    if (showWarn) {
        return dropWrappedContentTmp;
    }
    const dropWrappedContent = (isUploadComplete || isUploading) ?
        inline ? <div>{dropWrappedContentTmp}</div> :
            <Popup className='w-full' flowing style={{ width: '600px' }} basic trigger={<div>{fileSelector}</div>} open={true}>{dropWrappedContentTmp}</Popup>
        : <div className={twMerge('flex flex-col', dropPlacement === 'bottom' && 'flex-col-reverse')} >{dropWrappedContentTmp}<div className='flex self-center'>{fileSelector}</div></div>;

    return (<span className={twMerge('flex whitespace-no-wrap flex-col', className)}>
        {!inline && props.header && <Header as='h4'>!<FormattedMessage id={props.header} /></Header>}
        {dropWrappedContent}
    </span>
    )
}

export default FileUpload;