import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Checkbox, Grid, Modal } from 'semantic-ui-react';
import { ListUsersUser } from '../../../../../../backend_api/models/ListUsersUser';
import { AppState } from '../../../../../../base/types';
import { deepCopy, getLocationEntry } from '../../../../../../base/utils';
import { useAppDispatch } from '../../../../../../store';
import { getBulkApprovalFlow } from '../../../../../approval/actions';
import { getBulkApprovalFlowSelector } from '../../../../../approval/selectors';
import {
    ListView,
    ListViewHeader, ListViewHeaderItem, ListViewLines
} from '../../../../../globals/components/views/list/ListView';
import { getOrderUsersSelector } from '../../../../../users/selectors';
import { getGroupedInspectionsByTypeIdSelector, getReportNoGroupedInspectionsSelector } from '../../../../selectors/reportSelectors';
import { hasInspectionTypes } from '../../../../selectors/selectors';
import { changeApprovalStepsBulk, changeInspectionConclusionBulk } from '../../../../slices/inspectionReportSlice';
import { ApproveBulkInspectionRequest, ApproveBulkStepsRequest, BulkApprovalFlow, BulkApprovalStep, Inspection, Order } from '../../../../types';
import { getInspectionByTypeSequence } from '../../../../Utils';
import { InspectionTypeMarker } from '../../../inspectionTypes/InspectionTypes';
import Approval from '../../../report/Approval';
import InspectionItem from '../../InspectionItem';
import { config } from '../../inspectionItemConfig';

type OwnProps = {
    order: Order;
    isGrouped?: boolean;
    selectedInspectionId?: string;
    showDefects?: boolean;
    showInspectionTypes?: boolean;
};

type OrderInspectionsListProps = OwnProps;

const OrderInspectionsList = (props: OrderInspectionsListProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const intl = useIntl();
    const users = useSelector<AppState, ListUsersUser[]>(getOrderUsersSelector);
    const { order, isGrouped, selectedInspectionId, showDefects = true } = props;
    const formatMessage = intl.formatMessage;
    const bulkApproval: BulkApprovalFlow = useSelector(getBulkApprovalFlowSelector);
    const showInspectionTypes = props.showInspectionTypes || useSelector(hasInspectionTypes);
    const [stepsMap, setStepsMap] = useState([][0]);
    const filters = getLocationEntry(location, 'type_id') || [];
    const [showModal, setShowModal] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isSeletable, setIsSeletable] = useState(false);
    const inspectionsNoGroup: Inspection[] = useSelector<AppState, Inspection[]>(getReportNoGroupedInspectionsSelector);
    const inspections = useSelector((state: AppState) => getGroupedInspectionsByTypeIdSelector(state, filters));
    const [selectedInspections, setSelectedInspections] = useState<Inspection[]>([]);
    const [selectedInspection, setSelectedInspection] = useState<Inspection>();
    const getAllInspections = (): Inspection[] => {
        const inspectionWithGroup = Object.values(inspections).map((inspectionsByGroup: Inspection[]) => {
            const inspectionBySequence = getInspectionByTypeSequence(inspectionsByGroup);
            return inspectionBySequence || inspectionsByGroup[0];
        });
        return inspectionWithGroup.concat(inspectionsNoGroup).filter((ins) => ins.status === 'report');
    }

    const allInspections = getAllInspections();

    const settingSelectedInspections = (inspections: Inspection[]): void => {
        setSelectedInspections(inspections);
        if (inspections.length > 0) {
            const inspection: Inspection = deepCopy(inspections[0]);
            inspection.features = [];
            let changeConclusion = true;
            let delegatedApprovalStep = true;
            inspections.forEach((inspec) => {
                if (!inspec.features.includes('change_conclusion')) {
                    changeConclusion = false;
                }
                if (!inspec.features.includes('delegated_approval_step')) {
                    delegatedApprovalStep = false;
                }
            })
            if (changeConclusion) {
                inspection.features.push('change_conclusion');
            }
            if (delegatedApprovalStep) {
                inspection.features.push('delegated_approval_step');
            }
            setSelectedInspection(inspection)
        }
        else {
            setSelectedInspection(null);
        }
    };

    const toggleSelectAll = (event, data): void => {
        if (data.checked) {
            settingSelectedInspections(allInspections);
        }
        else {
            settingSelectedInspections([]);
        }
    };

    const toggleSelectSingle = (inspection): void => {
        if (inspection.status === 'report') {
            let newSeleted;
            if (selectedInspections.includes(inspection)) {
                newSeleted = selectedInspections.filter((ins) => ins !== inspection);
            }
            else {
                newSeleted = selectedInspections.concat(inspection);
            }
            settingSelectedInspections(newSeleted);
        }
    };
    useEffect(() => {
        if (bulkApproval) {
            if (bulkApproval.merged_approval_flow) {
                setStepsMap(approvalStepsIdMap(bulkApproval));
            }
            if (isFetching) {
                setIsFetching(false);
                setShowModal(true);
            }
        }
    }, [bulkApproval]);

    const toggleSeletable = (): void => {
        setIsSeletable(!isSeletable);
    };

    const openBulkApprovalDialog = (): void => {
        const selectedIds = selectedInspections.map((inspection) => inspection.inspection_id)
        setIsFetching(true);
        dispatch(getBulkApprovalFlow(selectedIds));
    };

    const approveSteps = (inspectionId: string, approvalStepId: string, conclusion: ApproveBulkStepsRequest): void => {
        conclusion.approval_step_ids = stepsMap[approvalStepId];
        dispatch(changeApprovalStepsBulk({ body: conclusion, inspectionId }));
        setShowModal(false);
        setIsSeletable(false);
        settingSelectedInspections([]);
    };

    const approveInspection = (inspectionId: string, conclusion: ApproveBulkInspectionRequest): void => {
        const inspectionIds = selectedInspections.map((inspection) => inspection.inspection_id);
        conclusion.inspection_ids = inspectionIds;
        dispatch(changeInspectionConclusionBulk({ body: conclusion, inspectionId }));
        setShowModal(false);
        setIsSeletable(false);
        settingSelectedInspections([]);
    };

    let content;
    if (!isGrouped) {
        content = [];
        order.inspections.forEach((inspection: Inspection, index: number) => {
            let linkCn = 'link noUnderline noColor';
            if (selectedInspectionId && selectedInspectionId === inspection.inspection_id) {
                linkCn = 'noLink';
            }
            const item = <InspectionItem key={index}
                orderId={order.order_id}
                inspection={inspection}
                hasInspectionTypes={showInspectionTypes}
                showDefects={showDefects}
                showInspector={true}
                inspectionTypesStatus={<InspectionTypeMarker inspections={[inspection]} type={'dashboard'} />}
                className={linkCn}
                isSelected={!!selectedInspections.find(inspectionEquals(inspection))}
                selectable={isSeletable}
                toggleSelect={toggleSelectSingle}
                showCombine={false}
            />;
            content.push(item);
        });
    } else {
        content = Object.values(inspections).map((inspectionsByGroup: Inspection[], index: number) => {
            const inspectionBySequence = getInspectionByTypeSequence(inspectionsByGroup);
            const inspection = inspectionBySequence || inspectionsByGroup[0];
            return <InspectionItem key={index}
                orderId={order.order_id}
                inspection={inspection}
                hasInspectionTypes={showInspectionTypes}
                showDefects={showDefects}
                showInspector={true}
                inspectionTypesStatus={<InspectionTypeMarker inspections={inspectionsByGroup} type={'dashboard'} />}
                className={getLinkColor(props, inspection)}
                isSelected={!!selectedInspections.find(inspectionEquals(inspection))}
                selectable={isSeletable}
                toggleSelect={toggleSelectSingle}
                showCombine={false}
            />;
        });
    }
    function getLinkColor(props, inspection): string {
        return isSelectedInspection(props, inspection) ? 'noLink' : 'link noUnderline noColor';
    }

    function isSelectedInspection(props, inspection): boolean {
        return props.selectedInspectionId && props.selectedInspectionId === inspection.inspection_id;
    }

    function inspectionEquals(inspection: Inspection) {
        return (otherInspection: Inspection): boolean => {
            return inspection.inspection_id === otherInspection.inspection_id
        }
    }

    const getHeaderItems = (): ListViewHeaderItem[] => {
        let headerItems: ListViewHeaderItem[] = [
            { label: undefined, className: widths.image, key: 'spacer.1' },
            { label: formatMessage({ id: 'order.inspections.item_number' }), className: widths.itemNo, key: 'item_number' },
            { label: formatMessage({ id: 'order.inspections.item_name' }), className: widths.itemName, key: 'item_name' },
            { label: formatMessage({ id: 'order.inspections.qty' }), className: widths.qty, key: 'qty' },
        ];
        if (showInspectionTypes) {
            headerItems.push({ label: formatMessage({ id: 'order.inspections.inspection_types' }), className: widths.types, key: 'inspection_types' })
        }
        if (showDefects) {
            headerItems.push({ label: formatMessage({ id: 'order.inspections.defects' }), className: widths.defects, key: 'defects' })
        }
        headerItems = headerItems.concat(
            { label: formatMessage({ id: 'order.inspections.date' }), className: widths.date, key: 'date' },
            { label: formatMessage({ id: 'order.inspections.inspector' }), className: widths.inspector, key: 'inspector' },
            { label: formatMessage({ id: 'order.inspections.status' }), className: widths.status, key: 'status' });

        if (isSeletable) {
            const allElementsSelectedAndNotEmpty = selectedInspections.length === allInspections.length && allInspections.length > 0

            const jsxElement = (
                <Checkbox checked={allElementsSelectedAndNotEmpty} onChange={toggleSelectAll} className='pl-2' />
            )

            return headerItems.concat({
                label: jsxElement,
                className: isGrouped ? 'select' : 'selectNonGrouped',
                key: 'toggleSelectAll.button',
            });
        }
        return headerItems;
    };
    const widths = config.widths;
    return <ListView className=''>
        <ListViewHeader className='border-b pb-4 mt-1' items={getHeaderItems()} />
        <ListViewLines className=''>{content}</ListViewLines>
        <Grid centered padded textAlign='center' stackable={true}>
            <Grid.Row columns={9} className='line'>
                <Grid.Column width={16} textAlign='center'>
                    <div>
                        {isSeletable && <Button className='positive button' disabled={selectedInspections.length === 0} onClick={openBulkApprovalDialog}>{formatMessage({ id: 'order.inspections.button.bulk_approve' })}</Button>}
                        <Button onClick={toggleSeletable}>{formatMessage({ id: isSeletable ? 'order.inspections.button.disable_selection' : 'order.inspections.button.enable_selection' })}</Button>
                    </div>
                    {showModal && <Modal
                        open={true}
                        onClose={(): void => setShowModal(false)}
                        closeIcon={true}
                    >
                        <Modal.Header>
                            {formatMessage({ id: 'order.inspections.bulk_approval.headertext' })}
                        </Modal.Header>
                        <Modal.Content>
                            {bulkApproval.merged_approval_flow && <Approval
                                inspection={selectedInspection}
                                changeApprovalStep={approveSteps}
                                changeInspectionConclusion={approveInspection}
                                users={users}
                                isBulk={true}
                                approvalFlow={bulkApproval.merged_approval_flow}
                            /> || formatMessage({ id: 'order.inspections.bulk_approval.incompatible' })}
                        </Modal.Content>
                    </Modal>}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </ListView>;
};

function approvalStepsIdMap(flow: BulkApprovalFlow): string[][] {
    const map = Array<string[]>();
    let index = 0;
    flow.merged_approval_flow.map((m: BulkApprovalStep[]) => {
        m.map((step: BulkApprovalStep) => {
            if (step.ids) {
                map[index] = step.ids;
            }
            else {
                map[index] = [step.id];
            }
            step.id = String(index);
            index++;
        })
    });
    return map;
}

export default OrderInspectionsList;
