import React from 'react';
import { usePress } from 'react-aria';
import { PressEvent } from 'react-aria-components';
import { Sizes } from '../../../components/types';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';
import Button from '../buttons/Button';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';
import { MicroInteractionClose } from '../MicroInteractions';

export type Props = {
    className?: string;
    removable?: boolean;
    label: ComponentOrStringType;
    size?: Sizes.XSmall | Sizes.Small | Sizes.Medium;
    icon?: Icons;
    onRemove?(e: PressEvent): void;
    empty?: boolean;
};

const textSizeMap = {
    medium: 'text-base',
    small: 'text-sm',
    xsmall: 'text-xs',
};

const paddingMap = {
    medium: 'px-6 py-2',
    small: 'px-3 py-1',
    xsmall: 'px-2 py-1',
};

const iconSizeMap = {
    medium: 'text-2xl',
    small: 'text-lg',
    xsmall: 'text-base',
};

const iconPaddingMap = {
    medium: 'px-2 py-1',
    small: 'px-2 py-0',
    xsmall: 'px-2 py-0',
};

const Chip = (props: Props): React.ReactElement => {
    const { className = '', label, removable, size = Sizes.Medium, icon, onRemove, empty } = props;
    const base = twMerge('h-fit inline-flex flex-wrap items-center gap-1 whitespace-nowrap', removable ? 'rounded-l-md' : 'rounded-md', empty ? 'italic text-secondary': null);
    const bg = 'bg-surface-chip';
    const border = 'border-default';
    const text = 'text-on-surface-chip'; // TODO: Figma color should probably change

    const iconSize = iconSizeMap[size];
    const textSize = textSizeMap[size];
    const padding = paddingMap[size];
    const iconPadding = iconPaddingMap[size];

    let { pressProps, isPressed } = usePress({
        onPress: (e) => {
            // e.continuePropagation();
            onRemove && onRemove(e);
        },
    });

    const removeAction = <div {...pressProps} className={twMerge(bg, iconPadding, 'flex items-center justify-center border-l rounded-r-md pl-1 cursor-pointer')}><MicroInteractionClose className={twMerge(iconSize, text, 'flex items-center')} /></div>;

    const clip = empty ? <div className={twMerge(base, textSize)}>{label}</div>
    :
    <div className={twMerge(base, padding, bg, border, text, textSize, className)}>
        {icon && <Icon className={twMerge(text, textSize, 'bg-surface-chip')} name={icon} />}
        {label}
    </div>

    return removable ? <div className='items-center flex gap-1'><div className='gap-0 flex'>{clip}{removable && removeAction}</div></div> : clip
    
};

export const ChipAddButton = (props: { size?: Sizes.XSmall | Sizes.Small | Sizes.Medium, show?: boolean, onAdd?(): void }) => {
    const { size, onAdd, show = true } = props;
    const padding = paddingMap[size];

    return show && <Button onPress={onAdd} className={padding} size={props.size}>Add<Icon size={size} className='' name='operation_add' /></Button>
};

export default Chip;
