import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DetailedSupplier } from '../../backend_api/models';
import { request2, requestCompliance } from '../../base/api';
import { AppState } from '../../base/types';
import { showSnackbar } from '../../base/ui/uiSlice';
import { AddDeadline, AddFileToDocument, Category, ChangeEndDate, ChangeStartDate, ConnectEvidence, CreateIgnoration, CreateNewDocument, CreateOrder, CreateProduct, CreateRequirement, DetailedDocumentView, DetailedOrder, DetailedRequirement, EditProduct, EditRequirement, FileResource, IgnorationIdResponse, IgnorationResponse, OrganizedProduct, ProductWithMatchingEvidence, RejectDocument, RemoveFilesFromDocument, RemoveProduct, ResetDocument, SetApproveResponsible, SetExpirationOnDocument, SetGroupsAsResponsible, SetReason, SetStartDateOnDocument, SetStateOnProduct, SetUploadResponsible, SetUsersAsResponsibles, ShallowOrder, ShallowProduct, ShallowRequirement, SkipRequiredDocument, SuggestProductsWithMatchingEvidence, TagAsList, UnskipRequiredDocument } from '../../compliance_api/models';
import { AddDocumentationTemplateToRequirementTemplateAction } from '../../compliance_outdated_api/models/AddDocumentationTemplateToRequirementTemplateAction';
import { ApplyRequirementTemplatesAction } from '../../compliance_outdated_api/models/ApplyRequirementTemplatesAction';
import { Product } from '../../compliance_outdated_api/models/Product';
import { RequirementTemplate, RequirementTemplateFromJSON } from '../../compliance_outdated_api/models/RequirementTemplate';
import { UpdateDocumentationTemplateAction, UpdateDocumentationTemplateActionToJSON } from '../../compliance_outdated_api/models/UpdateDocumentationTemplateAction';
import { UpdateProductAction } from '../../compliance_outdated_api/models/UpdateProductAction';
import { UpdateProductBundleAction } from '../../compliance_outdated_api/models/UpdateProductBundleAction';
import { UpdateRequirementTemplateAction, UpdateRequirementTemplateActionToJSON } from '../../compliance_outdated_api/models/UpdateRequirementTemplateAction';
import history from '../../store/history';
import { catchException } from '../errorHandling/handler';
import { sendErrorMessage, sendGlobalMessage, sendStatusMessage } from '../messages/actions';
import { ComplianceState } from './types';

const initialState: ComplianceState = {
    products: [],
    productBundles: [],
    product: null,
    order: null,
    requirements: [],
    requirement: null,
    requirementTemplate: null,
    categories: [],
    isFetching: false,
    previewRequirements: [],
    suggestedProducts: undefined,
    orders: [],
    suppliers: [],
    documentView: null,
    currentDocumentViewId: null,
    ignorations: null,
};

let currentReqId = 'none';
export const getComplianceRequirementTemplatesList = createAsyncThunk<ShallowRequirement[]>(
    'getComplianceRequirementTemplatesList',
    async (_, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('requirements', { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error listing compliance requirement templates list...');
            dispatch(sendErrorMessage(['error_message.compliance.requirements.list'], 3000));
            catchException('getComplianceRequirementTemplateRowsList', {
                endpoint: 'compliance/api/frontend/requirements',
                request: 'url',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        const data = await rq.json() as ShallowRequirement[];
        return data;
    });

export const getPreviewRequirementsForProduct = createAsyncThunk<DetailedRequirement[], TagAsList[]>(
    'getPreviewRequirementsForProduct',
    async (tags, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('requirements/preview_for_product', { method: 'post', body: JSON.stringify({ tags }) });
        if (!rq.ok) {
            console.log('There was an error listing compliance requirement templates list...');
            dispatch(sendErrorMessage(['error_message.compliance.requirements.list'], 3000));
            catchException('getPreviewRequirementsForProduct', {
                endpoint: 'compliance/api/frontend/requirements/preview_for_product',
                request: 'url',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        return await rq.json() as DetailedRequirement[];
    });

export const createRequirement = createAsyncThunk<DetailedRequirement, CreateRequirement>(
    'createRequirement',
    async (action: CreateRequirement, { dispatch, rejectWithValue }) => {
        // const json = CreateRequirementToJSON(action);
        const rq = await requestCompliance('requirements', { method: 'post', body: JSON.stringify(action) });
        if (!rq.ok) {
            console.log('There was an error creating the product...');
            dispatch(sendErrorMessage(['error_message.compliance.requirement_could_not_be_created'], 3000));
            rejectWithValue(rq as Response);
            return;
        }
        window.localStorage.removeItem('requirement-draft');
        dispatch(sendStatusMessage(['status_message.compliance.requirement_was_created_successfully'], 3000));
        const data = await rq.json() as DetailedRequirement;
        console.log('data ', data)
        history.push('/compliance/requirements/' + data.requirement_id);
        return data;
    });

export const editRequirement = createAsyncThunk<DetailedRequirement, { requirementId: string, data: EditRequirement }>(
    'editRequirement',
    async (editData, { dispatch, rejectWithValue }) => {
        console.log('editData ', editData)
        const rq = await requestCompliance('requirements/' + editData.requirementId + '/edit', { method: 'put', body: JSON.stringify(editData.data) });
        if (!rq.ok) {
            console.log('There was an error updating the requirement...');
            dispatch(sendErrorMessage(['error_message.compliance.requirement_could_not_be_updated'], 3000));
            rejectWithValue(rq as Response);
            return;
        }
        dispatch(sendStatusMessage(['status_message.compliance.requirement_was_updated_successfully'], 3000));
        const data = await rq.json() as DetailedRequirement;
        return data;
    });

export type UpdateDocumentationTemplateParams = {
    requirement_template_id: string;
    documentation_template_id: string;
    action: UpdateDocumentationTemplateAction;
}


const updateDocumentationTemplateName = 'updateDocumentationTemplate';
export const updateDocumentationTemplate = createAsyncThunk<RequirementTemplate, UpdateDocumentationTemplateParams>(
    updateDocumentationTemplateName,
    async (params: UpdateDocumentationTemplateParams, { dispatch, rejectWithValue }) => {

        const requestRoute = (
            'compliance/requirement_template/'
            + params.requirement_template_id
            + '/documentation_template/'
            + params.documentation_template_id
        );

        const json = UpdateDocumentationTemplateActionToJSON(params.action);

        const rq =
            await request2(
                requestRoute,
                {
                    method: 'put',
                    body: JSON.stringify(json)
                }
            );
        if (!rq.ok) {
            console.log('There was an error updating the documentation template...');
            dispatch(sendErrorMessage(['error_message.compliance.documentation_template_could_not_be_updated'], 3000));
            catchException(updateDocumentationTemplateName, {
                endpoint: 'compliance/requirement_template/[requirement_template_id]/documentation_template/[documentation_template_id]',
                request: requestRoute,
                status: rq.status,
                statusText: rq.statusText,
            }, {
                error: rq,
                json: json
            })
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.compliance.documentation_template_was_updated'], 3000));
        }
        const data = await rq.json();
        const result: RequirementTemplate = RequirementTemplateFromJSON(data);
        return result;
    });

const updateRequirementTemplateName = 'updateRequirementTemplate';
export type UpdateRequirementTemplateParams = {
    requirement_template_id: string;
    action: UpdateRequirementTemplateAction;
}

export const updateRequirementTemplate = createAsyncThunk<RequirementTemplate, UpdateRequirementTemplateParams>(
    updateRequirementTemplateName,
    async (params: UpdateRequirementTemplateParams, { dispatch, rejectWithValue }) => {

        const requestRoute = 'compliance/requirement_template/' + params.requirement_template_id
        const json = UpdateRequirementTemplateActionToJSON(params.action)

        const rq =
            await request2(
                requestRoute,
                {
                    method: 'put',
                    body: JSON.stringify(json)
                }
            );
        if (!rq.ok) {
            console.log('There was an error updating the requirement template...');
            dispatch(sendErrorMessage(['error_message.compliance.requirement_template_could_not_be_updated'], 3000));
            catchException(updateRequirementTemplateName, {
                endpoint: 'compliance/requirement_template/[id]',
                request: requestRoute,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, json: json });
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.compliance.requirement_template_was_updated'], 3000));
        }
        const data = await rq.json();
        const result: RequirementTemplate = RequirementTemplateFromJSON(data);
        return result;
    });

export const applyRequirementTemplatesToProductBundle = createAsyncThunk<void, ApplyRequirementTemplatesAction>(
    'applyRequirementTemplateToProductBundle',
    async (inputData: ApplyRequirementTemplatesAction, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/apply_requirement_templates_to_product_bundle', {
            method: 'post', body: JSON.stringify(inputData),
        });
        if (!rq.ok) {
            console.log('There was an error applying the product bundle...');
            dispatch(sendErrorMessage(['error_message.compliance.requirement_could_not_be_appliedto_product_bundle'], 3000));
            catchException('applyRequirementTemplateToProductBundle', {
                endpoint: 'compliance/apply_requirement_templates_to_product_bundle',
                request: 'compliance/apply_requirement_templates_to_product_bundle',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body: JSON.stringify(inputData) });
            rejectWithValue(rq as Response);
            return false;
        }
        dispatch(sendStatusMessage(['status_message.compliance.requirement_was_applied_to_product_bundle_successfully'], 3000));
        dispatch(getComplianceProduct(inputData.product_bundle_id));
        const data = await rq.json();
        return data;
    })

/* export const addFileToRequirementDocumentation = createAsyncThunk<void, { productBundleId: string, requirementId: string, documentationId: string, fileName: string, file: any }>(
    'addFileToRequirementDocumentation',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/add_file_to_documentation/' + params.productBundleId + '/' + params.requirementId + '/' + params.documentationId + '/' + params.fileName, {
            method: 'post', body: JSON.stringify(params.file)
        });
        if (!rq.ok) {
            console.log('There was an error applying the product...');
            dispatch(sendErrorMessage(['error_message.compliance.requirement_could_not_be_applied_to_product'], 3000));
            catchException('applyRequirementTemplateToProductBundle', {
                endpoint: 'compliance/apply_requirement_template_to_product_bundle',
                request: 'compliance/apply_requirement_template_to_product_bundle',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, params });
            rejectWithValue(rq as Response);
            return false;
        }
        dispatch(sendStatusMessage(['status_message.compliance.product.documentation_was_added_successfully'], 3000));
        dispatch(getComplianceProduct(params.productBundleId));
        const data = await rq.json();
        return data;
    }) */

/* export const removeFileFromRequirementDocumentation = createAsyncThunk<void, { productBundleId: string, requirementId: string, documentationId: string, fileId: string }>(
    'removeFileToRequirementDocumentation',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/remove_file_from_documentation', {
            method: 'post', body: JSON.stringify({
                documentation_id: params.documentationId,
                file_id: params.fileId,
                product_bundle_id: params.productBundleId,
                requirement_id: params.requirementId
            })
        });
        if (!rq.ok) {
            console.log('There was an error removing the file...');
            dispatch(sendErrorMessage(['error_message.compliance.documentation_file_cannot_be_removed'], 3000));
            catchException('applyRequirementTemplateToProductBundle', {
                endpoint: 'compliance/remove_file_from_documentation',
                request: 'compliance/remove_file_from_documentation',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, params });
            rejectWithValue(rq as Response);
            return false;
        }
        dispatch(sendStatusMessage(['status_message.compliance.documentation_file_removed_successfully'], 3000));
        dispatch(getComplianceProduct(params.productBundleId));
        const data = await rq.json();
        return data;
    }) */

/* export const verifyRequirementDocumentation = createAsyncThunk<void, { productBundleId: string, requirementId: string, documentationId: string }>(
    'verifyRequirementDocumentation',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/verify_documentation', {
            method: 'post', body: JSON.stringify({
                documentation_id: params.documentationId,
                product_bundle_id: params.productBundleId,
                requirement_id: params.requirementId,
            })
        });
        if (!rq.ok) {
            console.log('There was an error verifying the documentation...');
            dispatch(sendErrorMessage(['error_message.compliance.requirement_could_not_be_verified'], 3000));
            catchException('applyRequirementTemplateToProductBundle', {
                endpoint: 'compliance/verify_documentation',
                request: 'compliance/verify_documentation',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, params });
            rejectWithValue(rq as Response);
            return false;
        }
        dispatch(sendStatusMessage(['status_message.compliance.requirement_was_verified_successfully'], 3000));
        dispatch(getComplianceProduct(params.productBundleId));
        const data = await rq.json();
        return data;
    }) */

/* export const rejectRequirementDocumentation = createAsyncThunk<void, { productBundleId: string, requirementId: string, documentationId: string }>(
    'rejectRequirementDocumentation',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/require_documentation', {
            method: 'post', body: JSON.stringify({
                documentation_id: params.documentationId,
                product_bundle_id: params.productBundleId,
                requirement_id: params.requirementId,
            })
        });
        if (!rq.ok) {
            console.log('There was an error requiring the documentation...');
            dispatch(sendErrorMessage(['error_message.compliance.more_documentations_could_not_be_required'], 3000));
            catchException('rejectRequirementDocumentation', {
                endpoint: 'compliance/require_documentation',
                request: 'compliance/require_documentation',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, params });
            rejectWithValue(rq as Response);
            return false;
        }
        dispatch(sendStatusMessage(['status_message.compliance.asking_for_more_documentation_successfully'], 3000));
        dispatch(getComplianceProduct(params.productBundleId));
        const data = await rq.json();
        return data;
    })
 */
export const deleteComplianceRequirementTemplate = createAsyncThunk<any, string>(
    'getComplianceRequirement',
    async (requirementId, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('requirement/' + requirementId, { method: 'delete' });
        if (!rq.ok) {
            console.log('There was an error deleting the compliance requirement by id');
            dispatch(sendErrorMessage(['error_message.compliance.requirements.delete_requirement_template'], 3000));
            catchException('getComplianceRequirement', {
                endpoint: 'requirements/[requirementId]',
                request: 'requirements/' + requirementId,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        history.push('/compliance/requirements');
        return null;
    });

export const getComplianceRequirementTemplate = createAsyncThunk<DetailedRequirement, string>(
    'getComplianceRequirement',
    async (requirementId, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('requirements/' + requirementId, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting the compliance requirement by id');
            dispatch(sendErrorMessage(['error_message.compliance.requirements.get_template_by_id'], 3000));
            catchException('getComplianceRequirement', {
                endpoint: 'requirements/[requirementId]',
                request: 'requirements/' + requirementId,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        const requirementTemplateData = await rq.json();
        return requirementTemplateData as DetailedRequirement;
    });

export const addDocumentTemplateToRequirementTemplate = createAsyncThunk<RequirementTemplate, AddDocumentationTemplateToRequirementTemplateAction>(
    'addDocumentTemplateToRequirementTemplate',
    async (documentData: AddDocumentationTemplateToRequirementTemplateAction, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/add_documentation_template_to_requirement_template', { method: 'post', body: JSON.stringify(documentData) });
        if (!rq.ok) {
            console.log('There was an error adding the documentation template...');
            dispatch(sendErrorMessage(['error_message.compliance.requirement_template.add_documentation_template'], 3000));
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.compliance.the_requirement_documentation_was_added_successfully'], 3000));
        }
        const data = await rq.json() as RequirementTemplate;
        return data;
    });

export const getComplianceProductsList = createAsyncThunk<ShallowProduct[], string>(
    'getComplianceProductsList',
    async (status, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance(status === 'todo' ? 'products/todo' : 'products', { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error listing compliance products list...');
            dispatch(sendErrorMessage(['error_message.compliance.products.list'], 3000));
            catchException('getComplianceProductsList', {
                endpoint: 'products',
                request: 'url',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    });

export const getComplianceOrder = createAsyncThunk<DetailedOrder, string>(
    'getComplianceOrder',
    async (orderId, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('orders/' + orderId, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting order details...');
            dispatch(sendErrorMessage(['error_message.compliance.order.detail'], 3000));
            catchException('getComplianceOrder', {
                endpoint: 'orders',
                request: 'url',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    });

type AddProductDeadlineParam = {
    product_id: string,
    params: AddDeadline,
}

export const addProductDeadline = createAsyncThunk<OrganizedProduct, AddProductDeadlineParam>(
    'addProductDeadline',
    async (inputData: AddProductDeadlineParam, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance(`products/${inputData.product_id}/deadlines`, {
            method: 'post', body: JSON.stringify({
                date: inputData.params.date,
                name: inputData.params.name,
                supplier_type: inputData.params.supplier_type,
                supplier_id: inputData.params.supplier_id
            })
        });
        if (!rq.ok) {
            console.log('There was an error adding the deadline...');
            dispatch(sendErrorMessage(['error_message.compliance.deadline_cannot_be_added'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.deadline_was_added_successfully'], 3000));
        dispatch(getComplianceProduct(inputData.product_id));
        const data = await rq.json() as OrganizedProduct;
        return data;
    });

type UpdateProductImageParam = {
    product_id: string,
    image_id: string
}
export const updateProductImage = createAsyncThunk<FileResource[], UpdateProductImageParam>(
    'addProductAttachment',
    async (inputData: UpdateProductImageParam, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance(`products/${inputData.product_id}/update_image`, { method: 'post', body: JSON.stringify({ image_id: inputData.image_id }) });
        if (!rq.ok) {
            console.log('There was an error updating the product image...');
            dispatch(sendErrorMessage(['error_message.compliance.product_image_cannot_be_updated'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.product_image_was_updated_successfully'], 3000));
        dispatch(getComplianceProduct(inputData.product_id));
        const data = await rq.json() as FileResource[];
        return data;
    });

type AddProductAttachmentParam = {
    product_id: string,
    file_id: string
}
export const addProductAttachment = createAsyncThunk<FileResource[], AddProductAttachmentParam>(
    'addProductAttachment',
    async (inputData: AddProductAttachmentParam, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance(`products/${inputData.product_id}/add_attachment`, { method: 'post', body: JSON.stringify({ file_id: inputData.file_id }) });
        if (!rq.ok) {
            console.log('There was an error adding the attachment...');
            dispatch(sendErrorMessage(['error_message.compliance.attachment_cannot_be_added'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.attachment_was_added_successfully'], 3000));
        dispatch(getComplianceProduct(inputData.product_id));
        const data = await rq.json() as FileResource[];
        return data;
    });

type RemoveProductAttachmentParam = {
    product_id: string,
    file_id: string
}
export const removeProductAttachment = createAsyncThunk<FileResource[], RemoveProductAttachmentParam>(
    'removeProductAttachment',
    async (inputData: AddProductAttachmentParam, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance(`products/${inputData.product_id}/remove_attachment`, { method: 'post', body: JSON.stringify({ file_id: inputData.file_id }) });
        if (!rq.ok) {
            console.log('There was an error removing the attachment...');
            dispatch(sendErrorMessage(['error_message.compliance.attachment_cannot_be_removed'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.attachment_was_removed_successfully'], 3000));
        dispatch(getComplianceProduct(inputData.product_id));
        const data = await rq.json() as FileResource[];
        return data;
    });

export const createProduct = createAsyncThunk<OrganizedProduct, CreateProduct>(
    'createProduct',
    async (productData: CreateProduct, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('products', { method: 'post', body: JSON.stringify(productData) });
        if (!rq.ok) {
            console.log('There was an error creating the product...');
            dispatch(sendErrorMessage(['error_message.compliance.product_could_not_be_created'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.product_was_created_successfully'], 3000));
        /* dispatch(getComplianceProductsList());
        window.localStorage.removeItem('product-draft'); */
        const data = await rq.json() as OrganizedProduct;
        history.replace('/compliance/products/' + data.product_id);
        return data;
    });

export const editProduct = createAsyncThunk<OrganizedProduct, { product_id: string, product: EditProduct }>(
    'editProduct',
    async (productData, { dispatch, rejectWithValue }) => {
        console.log('productData ', productData)
        const rq = await requestCompliance('products/' + productData.product_id + '/edit', { method: 'put', body: JSON.stringify(productData.product) });
        if (!rq.ok) {
            console.log('There was an error editing the product...');
            dispatch(sendErrorMessage(['error_message.compliance.product_could_not_be_edited'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.product_was_edited_successfully'], 3000));
        // window.localStorage.removeItem('product-draft');
        const data = await rq.json() as OrganizedProduct;
        return data;
    });

export const complianceDeleteProduct = createAsyncThunk<null, RemoveProduct>(
    'complianceDeleteProduct',
    async (params: RemoveProduct, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('products/' + params.product_id + '/delete', { method: 'delete' });
        if (!rq.ok) {
            console.log('There was an error deleting the product...');
            dispatch(sendErrorMessage(['error_message.compliance.product_could_not_be_deleted'], 3000));
            catchException('complianceDeleteProduct', {
                endpoint: 'products/[product_id]/delete',
                request: 'products/' + params.product_id + '/delete',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body: JSON.stringify(params) });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.product_was_deleted_successfully'], 3000));
        dispatch(getComplianceProductsList('all'));
        await rq.json();
    });

export const createOrder = createAsyncThunk<DetailedOrder, CreateOrder>(
    'createOrder',
    async (orderData: CreateOrder, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('orders', { method: 'post', body: JSON.stringify(orderData) });
        if (!rq.ok) {
            console.log('There was an error creating the order...');
            dispatch(sendErrorMessage(['error_message.compliance.order_could_not_be_created'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.order_was_created_successfully'], 3000));
        dispatch(getComplianceProductsList('all'));
        window.localStorage.removeItem('order-draft');
        const data = await rq.json() as DetailedOrder;
        history.push('/compliance/orders/' + data.order_id);
        return data;
    });

export const deleteOrder = createAsyncThunk<DetailedOrder, CreateOrder>(
    'deleteOrder',
    async (orderData: CreateOrder, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('orders', { method: 'post', body: JSON.stringify(orderData) });
        if (!rq.ok) {
            console.log('There was an error creating the order...');
            dispatch(sendErrorMessage(['error_message.compliance.order_could_not_be_created'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.order_was_created_successfully'], 3000));
        dispatch(getComplianceProductsList('all'));
        const data = await rq.json() as DetailedOrder;
        history.push('/compliance/orders');
        return data;
    });

export const removeProductFromOrder = createAsyncThunk<DetailedOrder, { product_id: string, order_id: string }>(
    'removeProductFromOrder',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('orders/' + params.order_id + '/remove_product', { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error creating the order...');
            dispatch(sendErrorMessage(['error_message.compliance.remove_product_from_order_error'], 3000));
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.remove_product_from_order_success'], 3000));
        const data = await rq.json() as DetailedOrder;
        return data;
    });

type UpdateProductParam = {
    product_id: string,
    product_bundle_id: string,
    data: UpdateProductAction
}

export const updateProduct = createAsyncThunk<Product, UpdateProductParam>(
    'updateProduct',
    async (inputData: UpdateProductParam, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/product_bundle/' + inputData.product_bundle_id + '/product/' + inputData.product_id,
            { method: 'put', body: JSON.stringify(inputData.data) });
        if (!rq.ok) {
            console.log('There was an error updating the product...');
            dispatch(sendErrorMessage(['error_message.compliance.product_could_not_be_updated'], 3000));
            catchException('updateProduct', {
                endpoint: 'compliance/product_bundle/[bundle_id]/product/[productData.product_id]',
                request: 'compliance/product_bundle/' + inputData.product_bundle_id + '/product/' + inputData.product_id,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body: JSON.stringify(inputData.data) });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.product_was_updated_successfully'], 3000));
        const data = await rq.json() as Product;
        return data;
    });

export type UpdateProductBundleParam = {
    bundle_id: string,
    data: UpdateProductBundleAction
}

/* export const updateProductBundle = createAsyncThunk<ProductBundle, UpdateProductBundleParam>(
    'updateProductBundle',
    async (inputData: UpdateProductBundleParam, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/product_bundle/' + inputData.bundle_id, {
            method: 'put',
            body: JSON.stringify(UpdateProductBundleActionToJSON(inputData.data))
        });
        if (!rq.ok) {
            console.log('There was an error updating the product bundle...');
            dispatch(sendErrorMessage(['error_message.compliance.product_bundle_cannot_be_updated'], 3000));
            rejectWithValue(rq as Response);
            return;
        }
        dispatch(sendStatusMessage(['status_message.compliance.product_bundle_was_updated_successfully'], 3000));
        dispatch(getComplianceProduct(inputData.bundle_id));
        const data = await rq.json() as ProductBundle;
        return data;
    });


export const addProductToProductBundle = createAsyncThunk<ProductBundle, CreateProductInBundle>(
    'addProductToProductBundle',
    async (inputData: CreateProductInBundle, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/create_product_in_product_bundle', {
            method: 'post',
            body: JSON.stringify((inputData))
        });
        if (!rq.ok) {
            console.log('There was an error updating the product bundle...');
            dispatch(sendErrorMessage(['error_message.compliance.product_bundle_cannot_be_updated'], 3000));
            rejectWithValue(rq as Response);
            return;
        }
        dispatch(sendStatusMessage(['status_message.compliance.product_bundle_was_updated_successfully'], 3000));
        dispatch(getComplianceProduct(inputData.product_bundle_id));
        dispatch(getComplianceProductBundles());
        dispatch(getComplianceProductsList());
        const data = await rq.json() as ProductBundle;
        return data;
    });
 */
/* export const markProductBundleReady = createAsyncThunk<ProductBundle, string>(
    'markProductBundleReady',
    async (productBundleId, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/ready_product_bundle', { method: 'post', body: JSON.stringify({ product_bundle_id: productBundleId }) });
        if (!rq.ok) {
            console.log('There was an error marking the product bundle ready');
            dispatch(sendErrorMessage(['error_message.compliance.products.mark_product_bundle_as_ready'], 3000));
            catchException('markProductBundleReady', {
                endpoint: 'compliance/ready_product_bundle',
                request: 'compliance/ready_product_bundle/',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        const productBundleData = await rq.json();
        return productBundleData as ProductBundle;
    });
 */
export const getComplianceProduct = createAsyncThunk<OrganizedProduct, string>(
    'getComplianceProduct',
    async (productId, { dispatch, rejectWithValue }) => {
        const rq = await requestCompliance('products/' + productId + '/organized', { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting the compliance product');
            dispatch(sendErrorMessage(['error_message.compliance.products.get_product'], 5000));
            catchException('getComplianceProduct', {
                endpoint: 'products',
                request: 'products/' + productId,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        const productBundleData = await rq.json();
        return productBundleData as OrganizedProduct;
    });

/* export const getComplianceProductBundles = createAsyncThunk<ProductBundle[]>(
    'getComplianceProductBundles',
    async (_, { dispatch, rejectWithValue }) => {
        const rq = await request2('compliance/product_bundle_rows', { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting the compliance product bundle');
            dispatch(sendErrorMessage(['error_message.compliance.products.get_product_bundle'], 3000));
            catchException('getComplianceProductBundle', {
                endpoint: 'compliance/product_bundle_rows',
                request: 'compliance/product_bundle_rows',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        const productBundleData = await rq.json();
        return productBundleData as ProductBundle[];
    }); */

export const getComplianceCategories = createAsyncThunk<Category[]>(
    'getComplianceCategories',
    async (_, { dispatch, rejectWithValue }) => {
        const url = 'categories';
        const rq = await requestCompliance(url, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting the categories');
            dispatch(sendErrorMessage(['error_message.compliance.tags.get_categories'], 3000));
            catchException('getComplianceCategories', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        return await rq.json() as Category[];
    });

export const addComplianceTag = createAsyncThunk<Category[], { categoryId: string, parent_tag_id: string, tag_name: string }>(
    'addComplianceTag',
    async (params, { dispatch, rejectWithValue }) => {
        const path = { tag_name: params.tag_name, parent_tag_id: params.parent_tag_id ? params.parent_tag_id : null }
        const url = 'categories/' + params.categoryId + '/tags';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(path) });
        if (!rq.ok) {
            console.log('There was an error adding the tag');
            dispatch(sendErrorMessage(['error_message.compliance.tags.add_tag'], 3000));
            catchException('getComplianceTags', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.compliance.tags.add_tag_was_succesfully'], 3000));

        return await rq.json() as Category[];
    });

export const complianceAddFileToDocument = createAsyncThunk<DetailedDocumentView, {
    appliesTo: any,
    requirementId: string,
    documentTemplateId: string,
    productId: string,
    documentId?: string,
    fileIds?: string[],
    orderId?: string,
    supplierId: string,
    supplierType: 'supplier' | 'production_unit'
}>(
    'complianceAddFileToDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const body: AddFileToDocument = {
            document_id: params.documentId,
            document_template_id: params.documentTemplateId,
            order_id: params.orderId,
            file_ids: params.fileIds,
            requirement_id: params.requirementId,
            applies_to: params.appliesTo,
            supplier_id: params.supplierId,
            supplier_type: params.supplierType
        };
        const url = 'documents/add_files_to_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(body) });
        if (!rq.ok) {
            console.log('There was an error adding the file to the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_adding_file_to_document'], 3000));
            catchException('complianceAddFileToDocument', {
                endpoint: 'products/[params.productId]/add_file_to_document',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body });
            return rejectWithValue(rq as Response);
        }
        //dispatch(sendStatusMessage(['status_message.compliance.document.add_file_was_successful'], 3000));
        dispatch(getComplianceProduct(params.productId));
        dispatch(showSnackbar({ content: 'The file was added succesfully', hideAfter: 5000 }));
        return await rq.json() as DetailedDocumentView;
    });
interface RemoveFilesFromDocumentType extends RemoveFilesFromDocument { productId: string };
export const complianceRemoveFilesFromDocument = createAsyncThunk<DetailedDocumentView, RemoveFilesFromDocumentType>(
    'complianceRemoveFilesFromDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'documents/remove_files_from_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error removing the file(s) from the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_removing_file_from_document'], 3000));
            catchException('complianceRemoveFilesFromDocument', {
                endpoint: 'products/[params.productId]/remove_files_from_document',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, params });
            return rejectWithValue(rq as Response);
        }
        // dispatch(sendStatusMessage(['status_message.compliance.document.removing_files_was_successful'], 3000));
        dispatch(getComplianceProduct(params.productId));
        dispatch(showSnackbar({ content: 'The file was removed', hideAfter: 5000 }));
        return await rq.json() as DetailedDocumentView;
    });

export const complianceRemoveTag = createAsyncThunk<Category[], { category_id: string, tag_id: string }>(
    'complianceRemoveTag',
    async (params, { dispatch, rejectWithValue }) => {
        const body = params;
        const url = 'categories/' + params.category_id + '/tags/' + params.tag_id;
        const rq = await requestCompliance(url, { method: 'delete', body: JSON.stringify(body) });
        if (!rq.ok) {
            console.log('There was an error removing the tag');
            dispatch(sendErrorMessage(['error_message.compliance.error_removing_tag'], 3000));
            catchException('complianceRemoveTag', {
                endpoint: 'categories/[category_id]/tags/[tag_id]',
                request: 'categories/' + params.category_id + '/tags/' + params.tag_id,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.remove_tag_action_was_successful'], 3000));
        return await rq.json() as Category[];
    });

export const setComplianceEvidenceStatus = createAsyncThunk<OrganizedProduct, { status: 'approve' | 'reject', productId: string, documentId: string }>(
    'setComplianceEvidenceStatus',
    async (params, { dispatch, rejectWithValue }) => {
        const isApprove = params.status === 'approve';
        const body = { document_id: params.documentId, comment: '' };
        const url = 'products/' + params.productId + '/' + (isApprove ? 'approve_document' : 'reject_document');
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(body) });
        if (!rq.ok) {
            console.log('There was an error approving/rejecting the document');
            dispatch(sendErrorMessage([isApprove ? 'error_message.compliance.error_approving_document' : 'error_message.compliance.error_rejecting_document'], 3000));
            catchException('setComplianceEvidenceStatus', {
                endpoint: 'products/[params.productId]/' + (isApprove ? + 'approve_document' : 'reject_document'),
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body });
            rejectWithValue(rq as Response);
        }
        const messageId = params.status === 'approve' ? 'status_message.compliance.evidence.verify_status_successfully' : 'status_message.compliance.evidence.reject_status_successfully'
        dispatch(sendStatusMessage([messageId], 3000));
        return await rq.json() as OrganizedProduct;
    });

export const setComplianceDocumentExpiration = createAsyncThunk<OrganizedProduct, { productId: string, data: SetExpirationOnDocument }>(
    'setComplianceDocumentExpiration',
    async (params, { dispatch, rejectWithValue }) => {
        params.data.expiration_date = params.data.expiration_date == '' ? null : params.data.expiration_date
        const body = params.data;
        const url = 'products/' + params.productId + '/set_expiration_on_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(body) });
        if (!rq.ok) {
            console.log('There was an error setting the expiration on the the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_expiration_on_document'], 3000));
            catchException('setComplianceDocumentExpiration', {
                endpoint: 'products/[params.productId]/add_file_to_document',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.successfully_set_expiration_on_document'], 3000));
        return await rq.json() as OrganizedProduct;
    });

export const setComplianceDocumentStartDate = createAsyncThunk<OrganizedProduct, { productId: string, data: SetStartDateOnDocument }>(
    'setComplianceDocumentStartDate',
    async (params, { dispatch, rejectWithValue }) => {
        const body = params.data;
        const url = 'products/' + params.productId + '/set_start_date_on_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(body) });
        if (!rq.ok) {
            console.log('There was an error setting the start date on the the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_start_date_on_document'], 3000));
            catchException('setComplianceDocumentStartdate', {
                endpoint: 'products/[params.productId]/set_start_date_on_document',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.successfully_set_start_date_on_document'], 3000));
        return await rq.json() as OrganizedProduct;
    });

export const skipComplianceDocument = createAsyncThunk<OrganizedProduct, SkipRequiredDocument>(
    'skipComplianceDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'products/skip_required_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error skipping the the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_skipping_document'], 3000));
            catchException('skipComplianceDocument', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, params });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.evidence.successfully_skipping_document'], 3000));
        return await rq.json() as OrganizedProduct;
    });

export const unskipComplianceDocument = createAsyncThunk<OrganizedProduct, UnskipRequiredDocument>(
    'unskipComplianceDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'products/unskip_required_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error un-skipping the the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_unskipping_document'], 3000));
            catchException('unskipComplianceDocument', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, params });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.evidence.successfully_unskipping_document'], 3000));
        return await rq.json() as OrganizedProduct;
    });

export const getComplianceProductEvidenceMatchingSuggestions = createAsyncThunk<ProductWithMatchingEvidence[], { productId: string, documentId: string, evidenceId: string, requirementId: string }>(
    'getComplianceProductEvidenceMatchingSuggestions',
    async (params, { dispatch, rejectWithValue }) => {
        const body: SuggestProductsWithMatchingEvidence = { requirement_id: params.requirementId, document_id: params.documentId, evidence_id: params.evidenceId, product_id: params.productId };
        const url = 'products/' + params.productId + '/suggestions';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(body) });
        if (!rq.ok) {
            console.log('getting products with matching evidence');
            dispatch(sendErrorMessage(['error_message.compliance.error_skipping_evidence'], 3000));
            catchException('skipComplianceEvidence', {
                endpoint: 'products/[params.productId]/skip_evidence',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.evidence.successfully_skipping_evidence'], 3000));
        return await rq.json() as ProductWithMatchingEvidence[];
    });

export const complianceConnectProductEvidence = createAsyncThunk<OrganizedProduct, { evidenceToConnect: ConnectEvidence }>(
    'complianceConnectProductEvidence',
    async (params, { dispatch, rejectWithValue }) => {
        const body: ConnectEvidence = params.evidenceToConnect;
        const url = 'products/connect_evidence';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(body) });
        if (!rq.ok) {
            console.log('getting products with matching evidence');
            dispatch(sendErrorMessage(['error_message.compliance.error_connecting_evidence'], 3000));
            catchException('complianceConnectProductEvidence', {
                endpoint: 'products/connect_evidence',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, body });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.evidence.successfully_connecting_evidence'], 5000));
        return await rq.json() as OrganizedProduct;
    });

export const getComplianceOrderList = createAsyncThunk<ShallowOrder[]>(
    'getComplianceOrderList',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'orders';
        const rq = await requestCompliance(url, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting the order list');
            dispatch(sendErrorMessage(['error_message.compliance.error_getting_order_list'], 3000));
            catchException('getComplianceOrderList', {
                endpoint: 'orders',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        return await rq.json() as ShallowOrder[];
    });

export const resetComplianceData = createAsyncThunk(
    'resetComplianceData',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'demo/create_defaults';
        const rq = await requestCompliance(url, { method: 'post' });
        if (!rq.ok) {
            console.log('There was an error resetting the data');
            dispatch(sendErrorMessage(['error_message.compliance.error_resetting_data'], 3000));
            catchException('resetComplianceData', {
                endpoint: 'orders',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendGlobalMessage(['status_message.compliance.successfully_resetting_demo_data', 'PLEASE WAIT A FEW MINUTES FOR DATA TO BE READY AGAIN'], 10000));
        setTimeout(() => {
            window.location.reload();
        }, 5000);
        return await rq.json() as ShallowOrder[];
    });

export const getSuppliers = createAsyncThunk<DetailedSupplier[]>(
    'getSuppliers',
    async (_, { dispatch, rejectWithValue }) => {
        const url = 'suppliers2';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting the supplier list');
            dispatch(sendErrorMessage(['error_message.compliance.error_getting_supplier_list'], 3000));
            catchException('getSuppliers', {
                endpoint: 'suppliers2',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as DetailedSupplier[];
    });

export const getComplianceDetailedDocument = createAsyncThunk<DetailedDocumentView, { id: string }>(
    'getComplianceDetailedDocument',
    async (params, { dispatch, rejectWithValue }) => {
        console.log('getComplianceDetailedDocument ', params.id)
        const url = 'documents/' + params.id + '/detailed';
        const rq = await requestCompliance(url, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error getting the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_getting_document'], 3000));
            catchException('getDetailedDocument', {
                endpoint: 'documents/:id/detailed',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as DetailedDocumentView;
    });

export const addComplianceDocumentFiles = createAsyncThunk<DetailedDocumentView, { id: string }>(
    'addComplianceDocumentFiles',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'documents/' + params.id + '/detailed';
        const rq = await requestCompliance(url, { method: 'post' });
        if (!rq.ok) {
            console.log('There was an error adding files to the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_adding_files_to_the_document'], 3000));
            catchException('addComplianceDocumentFiles', {
                endpoint: 'documents/:id/detailed',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as DetailedDocumentView;
    });

export const complianceSubmitDocumentForApproval = createAsyncThunk<DetailedDocumentView, { productId: string, documentId: string }>(
    'complianceSubmitDocumentForApproval',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'documents/ready_for_approval';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify({ document_id: params.documentId }) });
        if (!rq.ok) {
            console.log('There was an error submitting the document for approval');
            dispatch(sendErrorMessage(['error_message.compliance.error_submitting_the_document_for_approval'], 3000));
            catchException('complianceSubmitDocumentForApproval', {
                endpoint: 'documents/:productId/submit_for_approval',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(getComplianceProduct(params.productId));
        return await rq.json() as DetailedDocumentView;
    });

export const complianceApproveDocument = createAsyncThunk<DetailedDocumentView, { productId: string, documentId: string }>(
    'complianceApproveDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'documents/approve_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify({ document_id: params.documentId }) });
        if (!rq.ok) {
            dispatch(showSnackbar({ content: 'Approve was not foo', hideAfter: 3000 }));
            console.log('There was an error submitting the document for approval');
            dispatch(sendErrorMessage(['error_message.compliance.error_submitting_the_document_for_approval'], 3000));
            catchException('complianceSubmitDocumentForApproval', {
                endpoint: 'documents/:productId/submit_for_approval',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(showSnackbar({ content: 'The document was approved', hideAfter: 5000 }));
        dispatch(getComplianceProduct(params.productId));
        return await rq.json() as DetailedDocumentView;
    });

interface RejectDocumentType extends RejectDocument { product_id: string };
export const complianceRejectDocument = createAsyncThunk<DetailedDocumentView, RejectDocumentType>(
    'complianceRejectDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'documents/reject_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error submitting the document for approval');
            dispatch(sendErrorMessage(['error_message.compliance.error_submitting_the_document_for_approval'], 3000));
            catchException('complianceSubmitDocumentForApproval', {
                endpoint: 'documents/:productId/submit_for_approval',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(showSnackbar({ content: 'The document was rejected', hideAfter: 5000 }));
        dispatch(getComplianceProduct(params.product_id));
        return await rq.json() as DetailedDocumentView;
    });
export interface CreateNewDocumentType extends CreateNewDocument { productId: string };
export const complianceCreateDocument = createAsyncThunk<DetailedDocumentView, CreateNewDocumentType>(
    'complianceCreateDocument',
    async (params, { dispatch, rejectWithValue }) => {
        /*
        {
            "applies_to": {
                "product_id": "string",
                "type": "document_applies_to_product"
            },
            "document_id": "string",
            "document_specification_id": "string",
            "requirement_specification_id": "string",
            "supplier": {
                "supplier_id": "string",
                "type": "supplier"
            },
            "timeframe": {
                "from": "2020-01-01",
                "type": "time_frame_interval",
                "until": "string"
            }
            }*/
        const url = 'documents';
        console.log('params ', params)
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error creating the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_creating_the_document'], 3000));
            catchException('complianceCreateDocument', {
                endpoint: 'documents',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(getComplianceProduct(params.productId));

        return await rq.json() as DetailedDocumentView;
    });

export const complianceSetDocumentExpiration = createAsyncThunk<DetailedDocumentView, SetExpirationOnDocument>(
    'complianceSetDocumentExpiration',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'documents/set_expiration_on_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error setting expiration on the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_the_document_expiration'], 3000));
            catchException('complianceSetDocumentExpiration', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as DetailedDocumentView;
    });

export const complianceResetDocument = createAsyncThunk<DetailedDocumentView, ResetDocument>(
    'complianceResetDocument',
    async (params, { dispatch, getState, rejectWithValue }) => {
        const url = 'documents/reset_document';
        const state = (getState() as AppState);
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error resetting the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_resetting_the_document'], 3000));
            catchException('complianceResetDocument', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.compliance.requirement_was_created_successfully'], 3000));
        dispatch(getComplianceProduct(state.app.compliance.product.product_id));

        return await rq.json() as DetailedDocumentView;
    });

export const complianceSetDocumentStartDate = createAsyncThunk<DetailedDocumentView, SetStartDateOnDocument>(
    'complianceSetDocumentStartDate',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'documents/set_start_date_on_document';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error setting start date on the document');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_the_document_start_date'], 3000));
            catchException('complianceSetDocumentStartDate', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as DetailedDocumentView;
    });

interface SetUsersAsResponsiblesType extends SetUsersAsResponsibles { product_id: string };
export const complianceSetProductResponsibleUsers = createAsyncThunk<OrganizedProduct, SetUsersAsResponsiblesType>(
    'complianceSetProductResponsibleUsers',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'products/' + params.product_id + '/set_users_as_responsibles';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error setting responsible user(s) on the product');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_the_product_responsible_users'], 3000));
            catchException('complianceSetProductResponsibleUsers', {
                endpoint: '/api/frontend/products/:params.product_id/set_users_as_responsibles',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(showSnackbar({ content: 'The responsible was updated', hideAfter: 3000 }));
        return await rq.json() as OrganizedProduct;
    });

interface SetGroupsAsResponsiblesType extends SetGroupsAsResponsible { product_id: string };
export const complianceSetProductResponsibleGroups = createAsyncThunk<OrganizedProduct, SetGroupsAsResponsiblesType>(
    'complianceSetProductResponsibleGroups',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'products/' + params.product_id + '/set_groups_as_responsibles';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error setting responsible groups(s) on the product');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_the_product_responsible_groups'], 3000));
            catchException('complianceSetProductResponsibleGroups', {
                endpoint: '/api/frontend/products/:params.product_id/set_groups_as_responsibles',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(showSnackbar({ content: 'The responsible was updated', hideAfter: 3000 }));
        return await rq.json() as OrganizedProduct;
    });

interface SetApproveResponsibleType extends SetApproveResponsible { requirement_id: string };
export const complianceRequirementSetApproveResponsible = createAsyncThunk<OrganizedProduct, SetApproveResponsibleType>(
    'complianceRequirementSetApproveResponsible',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'requirement/' + params.requirement_id + '/set_approve_responsible';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error setting approve responsible on the requirement');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_the_requirement_approval_responsible'], 3000));
            catchException('complianceRequirementSetApproveResponsible', {
                endpoint: 'requirement/:requirementId/set_approve_responsible',
                request: 'requirement/' + params.requirement_id + '/set_approve_responsible',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        // dispatch(showSnackbar({ content: 'The responsible was updated', hideAfter: 3000 }));
        return await rq.json() as OrganizedProduct;
    });

interface SetUploadResponsibleType extends SetUploadResponsible { requirement_id: string };
export const complianceRequirementSetUploadResponsible = createAsyncThunk<OrganizedProduct, SetUploadResponsibleType>(
    'complianceRequirementSetUploadResponsible',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'requirement/' + params.requirement_id + '/set_upload_responsible';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error setting upload responsible on the requirement');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_the_requirement_upload_responsible'], 3000));
            catchException('complianceRequirementSetUploadResponsible', {
                endpoint: 'requirement/:requirementId/set_upload_responsible',
                request: 'requirement/' + params.requirement_id + '/set_upload_responsible',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        // dispatch(showSnackbar({ content: 'The responsible was updated', hideAfter: 3000 }));
        return await rq.json() as OrganizedProduct;
    });

interface SetStateOnProductType extends SetStateOnProduct { product_id: string };
export const complianceSetProductState = createAsyncThunk<OrganizedProduct, SetStateOnProductType>(
    'complianceSetProductState',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'products/' + params.product_id + '/set_state';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error setting state on the product');
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_the_product_state'], 3000));
            catchException('complianceSetProductState', {
                endpoint: '/api/frontend/products/:params.product_id/set_state',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(showSnackbar({ content: 'The product state was changed', hideAfter: 3000 }));
        return await rq.json() as OrganizedProduct;
    });
export const complianceIgnoreRequirement = createAsyncThunk<OrganizedProduct, CreateIgnoration>(
    'complianceIgnoreRequirement',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'ignorations/create';
        const rq = await requestCompliance(url, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error ignoring the requirement');
            dispatch(sendErrorMessage(['error_message.compliance.error_ignoring_the_product'], 3000));
            catchException('complianceIgnoreRequirement', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        console.log('getComplianceProduct')
        // dispatch(complianceGetIgnorations(params.product_ids[0]));
        dispatch(getComplianceProduct(params.product_ids[0]));
        dispatch(showSnackbar({ content: 'The requirement was ignored', icon: 'status_skipped', hideAfter: 3000 }));
        /* setTimeout(() => {
            dispatch(getComplianceProduct(params.product_ids[0]));
        }, 500) */
        return await rq.json() as OrganizedProduct;
    });

export const complianceGetIgnorations = createAsyncThunk<IgnorationResponse[], string>(
    'complianceGetIgnorations',
    async (productId, { dispatch, rejectWithValue }) => {
        const url = 'ignorations/' + productId;
        const rq = await requestCompliance(url, {});
        if (!rq.ok) {
            console.log('There was an error getting the ignorations ');
            dispatch(sendErrorMessage(['error_message.compliance.error_getting_ignorations'], 3000));
            catchException('complianceGetIgnorations', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as IgnorationResponse[];
    });

type DeleteIgnorationRequest = { ruleId: string, productId: string };

export const complianceDeleteIgnoration = createAsyncThunk<IgnorationIdResponse, DeleteIgnorationRequest>(
    'complianceDeleteIgnoration',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'ignorations/' + params.ruleId + '/delete';
        const rq = await requestCompliance(url, { method: 'delete' });
        if (!rq.ok) {
            console.log('There was an error deleting the ignoration with rule id ', params.ruleId);
            dispatch(sendErrorMessage(['error_message.compliance.error_deleting_ignoration_rule'], 3000));
            catchException('complianceDeleteIgnoration', {
                endpoint: 'ignorations/:ruleId/delete',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        dispatch(getComplianceProduct(params.productId));
        dispatch(showSnackbar({ content: 'Requirement no longer ignored', hideAfter: 3000 }));
        return await rq.json() as IgnorationIdResponse;
    });
export const complianceIgnorationChangeStartDate = createAsyncThunk<IgnorationIdResponse, ChangeStartDate>(
    'complianceIgnorationChangeStartDate',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'ignorations/change_start_date';
        const rq = await requestCompliance(url, { method: 'put', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error updating the ignoration start date ', params);
            dispatch(sendErrorMessage(['error_message.compliance.error_updating_ignoration_rule_start_date'], 3000));
            catchException('complianceIgnorationChangeStartDate', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as IgnorationIdResponse;
    });

export const complianceIgnorationChangeEndDate = createAsyncThunk<IgnorationIdResponse, ChangeEndDate>(
    'complianceIgnorationChangeEndDate',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'ignorations/change_end_date';
        const rq = await requestCompliance(url, { method: 'put', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error updating the ignoration end date ', params);
            dispatch(sendErrorMessage(['error_message.compliance.error_updating_ignoration_rule_end_date'], 3000));
            catchException('complianceIgnorationChangeEndDate', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as IgnorationIdResponse;
    });
export const complianceIgnorationChangeReason = createAsyncThunk<IgnorationIdResponse, SetReason>(
    'complianceIgnorationChangeReason',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'ignorations/set_reason';
        const rq = await requestCompliance(url, { method: 'put', body: JSON.stringify(params) });
        if (!rq.ok) {
            console.log('There was an error setting the ignoration reason ', params);
            dispatch(sendErrorMessage(['error_message.compliance.error_setting_ignoration_rule_reason'], 3000));
            catchException('complianceIgnorationChangeReason', {
                endpoint: url,
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            return rejectWithValue(rq as Response);
        }
        return await rq.json() as IgnorationIdResponse;
    });

export const complianceSlice = createSlice({
    name: 'compliance',
    initialState,
    reducers: {
        complianceClearSuggestedProducts: (state): void => { state.suggestedProducts = {} },
        complianceClearPreviewRequirements: (state): void => { state.previewRequirements = [] },
        complianceClearDocument: (state): void => { state.documentView = null },
    },
    extraReducers: builder => {
        builder.addCase(getComplianceProductsList.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getComplianceProductsList.fulfilled, (state, action) => {
            state.products = action.payload;
            state.isFetching = false;
        });
        builder.addCase(getComplianceProduct.pending, (state) => {
            state.product = null;
            state.isFetching = false;
        });
        builder.addCase(getComplianceProduct.fulfilled, (state, action) => {
            state.product = action.payload;
            state.isFetching = false;
        });
        builder.addCase(getComplianceProduct.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getComplianceRequirementTemplatesList.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getComplianceRequirementTemplatesList.fulfilled, (state, action) => {
            state.isFetching = false;
            state.requirements = action.payload;
        });
        builder.addCase(getComplianceRequirementTemplatesList.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getPreviewRequirementsForProduct.pending, (state, action) => {
            currentReqId = action.meta.requestId;
            state.isFetching = true;
        });
        builder.addCase(getPreviewRequirementsForProduct.fulfilled, (state, action) => {
            state.isFetching = false;
            if (currentReqId === 'none' || action.meta.requestId === currentReqId) {
                state.previewRequirements = action.payload;
            }
        });
        builder.addCase(getPreviewRequirementsForProduct.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(createRequirement.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(createRequirement.fulfilled, (state, action) => {
            state.isFetching = false;
            state.requirement = action.payload;
        });

        builder.addCase(applyRequirementTemplatesToProductBundle.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(applyRequirementTemplatesToProductBundle.fulfilled, (state) => {
            state.isFetching = false;
        });
        builder.addCase(applyRequirementTemplatesToProductBundle.rejected, (state) => {
            state.isFetching = false;
        });

        builder.addCase(getComplianceRequirementTemplate.pending, (state) => {
            state.isFetching = true;
            state.requirement = null;
        });
        builder.addCase(getComplianceRequirementTemplate.fulfilled, (state, action) => {
            state.isFetching = false;
            state.requirement = action.payload;
        });

        builder.addCase(getComplianceRequirementTemplate.rejected, (state) => {
            state.isFetching = false;
            state.requirement = null;
        });

        builder.addCase(addDocumentTemplateToRequirementTemplate.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(addDocumentTemplateToRequirementTemplate.fulfilled, (state) => {
            state.isFetching = false;
        });

        /* builder.addCase(markProductBundleReady.fulfilled, (state) => {
            state.isFetching = false;
        }); */

        /* builder.addCase(rejectRequirementDocumentation.fulfilled, (state) => {
            state.isFetching = false;
        });
 */
        /* builder.addCase(getComplianceProductBundles.fulfilled, (state, action) => {
            state.productBundles = action.payload;
        }); */
        builder.addCase(getComplianceCategories.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getComplianceCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.isFetching = false;
        });
        builder.addCase(addComplianceTag.fulfilled, (state, action) => {
            state.categories = action.payload;
        });
        builder.addCase(complianceRemoveTag.fulfilled, (state, action) => {
            state.categories = action.payload;
        });
        builder.addCase(setComplianceEvidenceStatus.fulfilled, (state, action) => {
            state.product = action.payload;
        });
        builder.addCase(complianceAddFileToDocument.fulfilled, (state, action) => {
            state.documentView = action.payload;
        });
        builder.addCase(setComplianceDocumentExpiration.fulfilled, (state, action) => {
            state.product = action.payload;
        });
        builder.addCase(setComplianceDocumentStartDate.fulfilled, (state, action) => {
            state.product = action.payload;
        });
        builder.addCase(skipComplianceDocument.fulfilled, (state, action) => {
            state.product = action.payload;
        });
        builder.addCase(unskipComplianceDocument.fulfilled, (state, action) => {
            state.product = action.payload;
        });
        builder.addCase(addProductDeadline.pending, (state) => {
            state.isFetching = false;
        });
        builder.addCase(addProductDeadline.fulfilled, (state, action) => {
            state.product = action.payload;
            state.isFetching = false;

        });
        builder.addCase(addProductDeadline.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getComplianceOrderList.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getComplianceOrderList.fulfilled, (state, action) => {
            state.orders = action.payload;
            state.isFetching = false;
        });
        builder.addCase(getComplianceOrderList.rejected, (state) => {
            state.isFetching = false;
            state.orders = [];
        });
        builder.addCase(getComplianceOrder.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getComplianceOrder.fulfilled, (state, action) => {
            state.order = action.payload;
            state.isFetching = false;
        });
        builder.addCase(getComplianceOrder.rejected, (state) => {
            state.isFetching = false;
            state.order = null;
        });
        builder.addCase(getSuppliers.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getSuppliers.fulfilled, (state, action) => {
            state.isFetching = false;
            state.suppliers = action.payload;
        });
        builder.addCase(getSuppliers.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(getComplianceProductEvidenceMatchingSuggestions.fulfilled, (state, action) => {
            state.suggestedProducts = { [action.meta.arg.documentId]: action.payload };
        });
        builder.addCase(editProduct.fulfilled, (state, action) => {
            state.product = action.payload;
        });
        builder.addCase(complianceRemoveFilesFromDocument.fulfilled, (state, action) => {
            state.documentView = action.payload;
        });
        builder.addCase(getComplianceDetailedDocument.fulfilled, (state, action) => {
            state.documentView = action.payload;
            state.currentDocumentViewId = action.meta.arg.id;
            console.log('currentDocumentViewId ', action.meta.arg.id)
        });
        builder.addCase(complianceSubmitDocumentForApproval.fulfilled, (state, action) => {
            state.documentView = action.payload;
        });
        builder.addCase(complianceApproveDocument.fulfilled, (state, action) => {
            state.documentView = action.payload;
            state.isFetching = false;
        });
        builder.addCase(complianceApproveDocument.pending, (state, action) => {
            state.isFetching = true;
        });
        builder.addCase(complianceApproveDocument.rejected, (state, action) => {
            state.isFetching = false;
        });
        builder.addCase(complianceCreateDocument.fulfilled, (state, action) => {
            state.documentView = action.payload;
            state.currentDocumentViewId = action.meta.arg.document_id;
        });
        builder.addCase(complianceSetDocumentExpiration.fulfilled, (state, action) => {
            state.documentView = action.payload;
        });
        builder.addCase(complianceSetDocumentStartDate.fulfilled, (state, action) => {
            state.documentView = action.payload;
        });
        builder.addCase(complianceResetDocument.fulfilled, (state, action) => {
            state.documentView = action.payload;
        });
        builder.addCase(complianceResetDocument.rejected, (state, action) => { });
        builder.addCase(complianceSetProductResponsibleUsers.fulfilled, (state, action) => {
            state.product = action.payload;
        });
        builder.addCase(complianceSetProductResponsibleGroups.fulfilled, (state, action) => {
            // state.product = action.payload;
        });
        builder.addCase(complianceSetProductState.fulfilled, (state, action) => {
            state.product = action.payload;
        });
        builder.addCase(complianceGetIgnorations.fulfilled, (state, action) => {
            state.ignorations = action.payload;
        });
    }
});
export const { complianceClearSuggestedProducts, complianceClearPreviewRequirements, complianceClearDocument } = complianceSlice.actions;
export default complianceSlice.reducer;
