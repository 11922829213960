import classnames from 'classnames';
import React, { Component, Fragment, useState } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Modal, Popup } from 'semantic-ui-react';
import Label from '../../../base/components/basic/Label';
import {
    getLocaleLanguageString,
    getLocaleSpecificString,
    getPrettyDatetime,
    prettyClipText,
    preventDefaultAndStopPropagation
} from '../../../base/utils';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { MultiLanguageText } from '../../globals/types';
import { Inspection } from '../../inspections/types';
import users from '../../users';
import { storeShowTranslateOption } from '../actions';
import { CommentTarget, Comment as CommentType, CommentTypes, Snapshot, SnapshotV2, SnapshotV3 } from '../types';
import CommentText from './CommentText';

const UserLine = users.components.Widgets.UserLine;
const TRANSLATIONS_CLICKED_BEFORE_SHOWING__BOX = 2;

type GetLabelFn = (type: CommentTarget, id: string, status?: string, text?: string) => React.ReactElement

type QCommentProps = {
    comment: CommentType;
    children?: React.ReactNode;
    userId: string;
    level?: number;
    isRoot: boolean;
    isFUT: boolean;
    showLabels: boolean;
    showTranslatedComments: boolean;
    showTranslatedCommentsAsked: boolean;
    showReply: boolean;
    storeShowTranslateOption: typeof storeShowTranslateOption;
    getLabel: GetLabelFn;
    writeComment(commentId: string, parentId: string): React.ReactElement;
    toggleReply(commentId: string): void;
    sendCommentNotifications(inspectionId: string);
    inspection: Inspection;
    isLast?: boolean;
}

const initialState = {
    translationVisible: false,
    prevVisible: false,
    translateOption: undefined,
    showTranslatedCommentsInitialMessage: false
};
type State = Readonly<typeof initialState>;

class QComment extends Component<QCommentProps & WrappedComponentProps, State> {
    public constructor(props) {
        super(props);
        this.state = initialState;
        this.showTranslation = this.showTranslation.bind(this);
        this.hideTranslation = this.hideTranslation.bind(this);
        this.handleTranslateOption = this.handleTranslateOption.bind(this);
        this.storeTranslateOption = this.storeTranslateOption.bind(this);
    }

    public render(): React.ReactElement {
        const comment: CommentType = this.props.comment;
        const { isRoot, isFUT, showLabels, showReply, level = 0, isLast = false } = this.props;
        const currentLang = getLocaleLanguageString(this.props.intl.locale);

        const hasNotificationBeenSent = comment.notification_sent;
        const indent = level > 1 ? ((level < 4) ? 15 : 10) : 0;
        return (
            <Fragment key={'qcomment_' + comment.id}>
                {isFUT && <a id={['follow_up_task_comment_', comment.follow_up_task_id].join('')} />}
                <div key={comment.id} style={{ paddingLeft: indent + 'px' }} className={classnames({ 'border-b': isRoot && !isLast, 'border-l border-dotted': !isRoot })}>
                    <div>
                        <div className='flex justify-between mt-4 items-center mr-1'>
                            {comment.user && <div className='text-secondary font-medium'>
                                {comment.user.firstname || '-'} {comment.user.lastname || '-'}
                            </div>}
                            {!comment.user && <div>-</div>}
                            <div className='text-sm text-gray-500'>{getPrettyDatetime(comment.timestamp, this.props.intl.locale || null)}</div>
                        </div>
                        <CommentText
                            comment={comment}
                            isFUT={isFUT}
                            isRoot={isRoot}
                            showTranslatedCommentsInitialMessage={this.state.showTranslatedCommentsInitialMessage}
                            showTranslation={this.showTranslation}
                            hideTranslation={this.hideTranslation}
                            translationVisible={this.state.translationVisible}
                            handleTranslateOption={this.handleTranslateOption}
                            translateOption={this.state.translateOption}
                            storeTranslateOption={this.storeTranslateOption}
                        >

                        </CommentText>
                        <div className={'hideOnPrint pb-4 flex justify-between items-end'}>
                            <HasFeatureAccess feature='create_comments' type='inspection'
                                inspection={this.props.inspection}>
                                <div className='link noUnderline text-sm'
                                    data-test-id='btn-comment-reply'
                                    onClick={(e): void => {
                                        preventDefaultAndStopPropagation(e);
                                        this.props.toggleReply(comment.id);
                                    }}>
                                    <FormattedMessage id='comments.reply' />
                                </div>
                            </HasFeatureAccess>
                            {hasNotificationBeenSent
                                ? (<CommentRelationLabel showLabels={showLabels}
                                    root={isRoot}
                                    comment={comment}
                                    currentLang={currentLang}
                                />)
                                : (<div className='link noUnderline text-sm'>
                                    <CommentRelationLabel showLabels={showLabels}
                                        root={isRoot}
                                        comment={comment}
                                        currentLang={currentLang}
                                    />
                                    <NoNotificationSent
                                        comment={comment}
                                        sendCommentNotifications={this.props.sendCommentNotifications} />
                                </div>)}
                        </div>
                        {showReply &&
                            <div className='mb-6'>{this.props.writeComment(comment.id, comment.id)}</div>}
                        {this.props.children}
                    </div>
                </div>
            </Fragment>
        );

    }

    public componentDidMount(): void {
        this.setState({ translationVisible: this.props.showTranslatedComments });
    }

    public componentDidUpdate(prevProps): void {
        if (prevProps.showTranslatedComments !== this.props.showTranslatedComments) {
            this.setState({ translationVisible: true });
        }
    }

    public showTranslation(): void {
        let showTranslatedCommentsInitialMessage = false;
        if (!this.props.showTranslatedCommentsAsked) {
            let translationsClicked = localStorage.getItem('translationsClicked') ? Number(localStorage.getItem('translationsClicked').valueOf()) : 0;
            if (translationsClicked >= TRANSLATIONS_CLICKED_BEFORE_SHOWING__BOX) {
                showTranslatedCommentsInitialMessage = true;
                translationsClicked = -1;
            }
            localStorage.setItem('translationsClicked', (translationsClicked + 1).toString());
        }
        this.setState({ translationVisible: true, showTranslatedCommentsInitialMessage });
    }

    public hideTranslation(): void {
        this.setState({ translationVisible: false });
    }

    public handleTranslateOption(translateOption: boolean): void {
        this.setState({ translateOption });
    }

    public storeTranslateOption(store: boolean): void {
        this.setState({ showTranslatedCommentsInitialMessage: false });
        if (store !== undefined) {
            this.props.storeShowTranslateOption(store);
        }
    }
}

function NoNotificationSent(props: { comment: CommentType; sendCommentNotifications: (id: string) => Promise<any> }): React.ReactElement {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    function sendNotification(): Promise<any> {
        return props.sendCommentNotifications(props.comment.inspection_id)
    }

    const userWantsToNotify = React.useCallback(function userWantsToNotify() {
        setShowConfirmationModal(true);
    }, []);

    return ((props.comment.notification_sent || props.comment.approval_step_id) ? null :
        <>
            <ConfirmNotificationModal
                open={showConfirmationModal}
                cancel={(): void => {
                    setShowConfirmationModal(false);
                }}
                confirm={(): void => {
                    setShowConfirmationModal(false);
                    sendNotification();
                }}
            />
            <Popup
                on='hover'
                position='top right'
                trigger={
                    <div className='link text-sm noUnderline'
                        data-test-id='btn-comment-notify'
                        onClick={userWantsToNotify}>
                        <FormattedMessage id='comments.notify' />
                    </div>
                }>
                <Popup.Content>
                    <FormattedMessage id="comments.notify.tooltip" />
                </Popup.Content>
            </Popup>
        </>
    )
}


function ApprovalStepLabel(props: { comment: CommentType }): React.ReactElement {
    const intl = useIntl();
    const conclusionSetToTranslation = intl.formatMessage({ id: 'comments.conclusion_set_to' });
    return (
        <div className='labelContainer approvalStep' style={{}}>
            <ApprovalStepOnBehalfOf comment={props.comment} />
            <div className='conclusion'>
                <span style={{ color: '#3c434a' }}>{conclusionSetToTranslation}</span>
                <Label className={['text-xxs inline px-2 py-1 border-0 items-center', getSnapshotConclusionCategory(props.comment.snapshot)].join(' ')}>
                    <ApprovalStepConclusion comment={props.comment} />
                </Label>
            </div>
        </div>
    );
}

function ApprovalStepOnBehalfOf(props: { comment: CommentType }): React.ReactElement {
    const showOnBehalfOf = props.comment.snapshot.intended_user && props.comment.user.id !== props.comment.snapshot.intended_user.id;
    if (!showOnBehalfOf) {
        return null;
    }
    return (
        <div className='onBehalfOf' style={{ textAlign: 'right' }}>
            <FormattedMessage id='approval.approval_step.on_behalf_of' />
            {' '}
            <UserLine className='author' user={props.comment.snapshot.intended_user} />
        </div>
    );
}

function getSnapshotConclusionCategory(snapshot: Snapshot) {
    if (snapshot.type.version === '2') {
        return (snapshot as SnapshotV2).conclusion;
    }
    else if (snapshot.type.version === '3') {
        return (snapshot as SnapshotV3).conclusion.conclusion_category;
    }
}


function ApprovalStepConclusion(props: { comment: CommentType }): React.ReactElement {
    const snapshot = props.comment.snapshot;
    const snapshotConclusion = props.comment.snapshot && props.comment.snapshot.conclusion;

    if (!snapshotConclusion) {
        return <span>-</span>;
    }
    if (snapshot.type.version === '2') {
        return <span>{(snapshot as SnapshotV2).conclusion}</span>
    } else {
        const fullName = (snapshot as SnapshotV3).conclusion.full_name as MultiLanguageText;
        return <span>{getLocaleSpecificString(fullName)}</span>;
    }
}


function InspectorCommentLabel(): React.ReactElement {
    return (
        <div className=''>
            <Label className='text-xxs inline px-2 py-1 border-0 items-center'>
                <FormattedMessage id='comments.inspectors_conclusion' />
            </Label>
        </div>
    );
}

function CommentRelationLabel(props: {
    showLabels: boolean;
    className?: string;
    root: boolean;
    comment: CommentType;
    currentLang: string;
    style?: React.CSSProperties;
}): React.ReactElement {
    const comment = props.comment;
    const currentLang = props.currentLang;
    const showLabels = props.showLabels && props.root;

    const showDefectLabel = showLabels && props.comment.defect_id !== null;
    const defectLabel =
        showDefectLabel
            ? (<ItemLabel
                type={'defect'}
                id={comment.defect_id}
                status={null}
                text={defectTitle(comment.defect)}
            />)
            : null

    const showCheckpointLabel = showLabels && props.comment.checkpoint_id !== null && props.comment.image_url === null
    const checkpointLabel =
        showCheckpointLabel
            ? (<ItemLabel
                type={'checkpoint'}
                id={comment.checkpoint_id}
                status={null}
                text={translateMultiLingualText(comment.checkpoint.instruction_text, currentLang)}
            />)
            : null;

    const showCheckpointImageLabel = showLabels && props.comment.checkpoint_id !== null && props.comment.image_url !== null
    const checkpointImageLabel =
        showCheckpointImageLabel
            ? (<ItemLabel
                type={'checkpoint_image'}
                id={comment.checkpoint_id}
                status={comment.image_url}
                text={translateMultiLingualText(comment.checkpoint.instruction_text, currentLang)}
            />)
            : null

    const showFUTLabel = showLabels && props.comment.follow_up_task_id !== null
    const futLabel =
        showFUTLabel
            ? (<FUTLabel id={comment.follow_up_task_id}
                status={comment.follow_up_task.status}
            />)
            : null;

    const showInspectorComment = props.comment.inspector_comment && props.root
    const inspectorCommentLabel =
        showInspectorComment
            ? <InspectorCommentLabel />
            : null;

    const showApprovalStepLabel = comment.approval_step_id !== null && props.root;
    const approvalStepLabel =
        showApprovalStepLabel
            ? <ApprovalStepLabel comment={comment} />
            : null

    const showAnything =
        showDefectLabel ||
        showCheckpointImageLabel ||
        showCheckpointLabel ||
        showFUTLabel ||
        showInspectorComment ||
        showApprovalStepLabel

    if (!showAnything) {
        return null;
    }

    return (
        <div className={'items-center comment-footer text-sm mb-2 text-right' + (props.className || '')} style={{ color: 'white', ...props.style }}>
            {defectLabel}
            {checkpointLabel}
            {checkpointImageLabel}
            {futLabel}
            {inspectorCommentLabel}
            {approvalStepLabel}
        </div>
    );
}

function defectTitle(defect): string {
    if (defect.type) {
        const codeAndName = `${defect.type.code}. ${defect.type.name}`

        if (defect.description) {
            return `${codeAndName}: ${defect.description}`;
        } else {
            return codeAndName;
        }
    } else {
        return defect.description;
    }
}

function translateMultiLingualText(multiLingual, currentLang): string {
    return multiLingual[currentLang] || multiLingual['C'];
}

function ItemLabel(props: { type: CommentTypes; id: string; status?: string; text?: string }): React.ReactElement {
    const { type, id, status, text } = props;

    if (!text) {
        return null;
    }

    return (
        <div className='labelContainer'>
            <Popup
                on='hover'
                position='top center'
                trigger={triggerContent()}>
                <Popup.Content>
                    {popupContent()}
                </Popup.Content>
            </Popup>
        </div>
    );

    function triggerContent(): React.ReactElement {
        return (
            <span><Label className={classnames('text-xxs inline px-2 py-1 border-0 items-center hover:text-white')}>
                {type === 'checkpoint_image'
                    ? <ImageLink text={text} id={id} type={type} status={status} />
                    : <ItemLink type={type} id={id} text={text} />}
            </Label></span>
        );
    }

    function popupContent(): React.ReactElement {
        return (<>
            <h4><FormattedMessage id={'report.label.' + type} /></h4>
            {text}
        </>);
    }
}

function FUTLabel(props: { id: string; status?: string }): React.ReactElement {
    const { status } = props;

    const cn = ['futStatus', status].join(' ');

    return (
        <div className='labelContainer'>
            <Label className={classnames(cn, 'text-xxs inline px-2 py-1 border-0 items-center')}>
                <FormattedMessage id={['report.label.follow_up_task.', status].join('')} />
            </Label>
        </div>
    );
}

function ItemLink(props: { type: CommentTypes; id: string; text: string }): React.ReactElement {
    const { type, id, text } = props;
    const href = `#${type}_${id}`
    return (
        <a href={href} className='text-white hover:text-white'>
            {prettyClipText(text, 20)}
        </a>
    )
}

function ImageLink(props: { status: string; type: CommentTypes; id: string; text: string }): React.ReactElement {
    const { type, id, text, status } = props;
    const toUrl = `?imageURL=${status}#${type}_${id}`;
    return (
        <Link to={toUrl} className='text-white hover:text-white'>
            {prettyClipText(text, 20)}
        </Link>
    )
}


function ConfirmNotificationModal(props: {
    open: boolean;
    cancel: () => void;
    confirm: () => void;
}): React.ReactElement {
    const intl = useIntl();

    const title = intl.formatMessage({ id: 'report.send_notification.modal.title' })
    const areYouSure = intl.formatMessage({ id: 'report.send_notification.modal.are_you_sure' })
    const yesTitle = intl.formatMessage({ id: 'report.send_notification.modal.yes_send' })
    const noTitle = intl.formatMessage({ id: 'report.send_notification.modal.no_do_not_send' })

    return (
        <Modal
            closeIcon={true}
            open={props.open}
            onClose={props.cancel}
            size='tiny'
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <p>{areYouSure}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={props.cancel}>
                    {noTitle}
                </Button>
                <Button positive onClick={props.confirm}>
                    {yesTitle}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default injectIntl(QComment);
