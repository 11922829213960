import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Image from '../../../base/components/Image';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { ShallowProduct } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import ComplianceRequirementsStatusPie from '../../correctiveActions/components/ComplianceRequirementsStatusPie';
import { ListViewItem, ListViewLine } from '../../globals/components/views/list/ListView';
import { complianceDeleteProduct } from '../complianceSlice';
import ComplianceProductDeadline from './ComplianceProductDeadline';

type Props = {
    product: ShallowProduct;
    canDelete?: boolean;
    canRemove?: boolean;
    removeFunction?(productId: string);
    onClick?(productId: string): void;
};

const ComplianceProductsItem = (props: Props): React.ReactElement => {
    const { product, onClick, canDelete = true, canRemove = false } = props;
    const dispatch = useAppDispatch();
    const deadline = <ComplianceProductDeadline product={product} />;
    return <ListViewLine onClick={onClick ? () => onClick(product.product_id) : null} key={product.product_id} className='bg-white border-b cursor-pointer items-center hover:bg-highlight-green'>
        <div className={twMerge('md:hidden grid grid-cols-12 w-full gap-y-4')} >
            <div className='flex col-span-10 space-x-2 justify-between'>
                {product.product_name}
            </div>
            <div className='col-span-2 row-span-2 flex justify-end items-start'>
                {product && product.product_image && <Image squared className='rounded bg-cover bg-center w-12 h-12' url={product.product_image.preview_url} />}
            </div>
            <div className='col-span-10 text-secondary space-x-2'>
                {product.product_number}
            </div>
            <div className='col-span-10 text-secondary space-x-2 gap-y-8'>{deadline}</div>
            <div className='col-span-2 row-span-2 flex justify-end text-secondary space-x-2'>
                <ComplianceRequirementsStatusPie requirementData={product.statistics} />
            </div>
        </div >
        <ListViewItem className={twMerge('hidden sm:flex pt-4 md:pt-0 w-full md:w-1/16')}>
            {product && product.product_image && <Image squared className='rounded bg-cover bg-center w-12 h-12' url={product.product_image.preview_url} />}
        </ListViewItem>
        <ListViewItem className='hidden sm:flex w-full md:w-6/16 font-bold md:font-normal'>{product.product_name}</ListViewItem>
        <ListViewItem className='hidden sm:flex w-full md:w-4/16 font-bold md:font-normal'>{product.product_number}</ListViewItem>
        <ListViewItem className='hidden sm:flex w-full md:w-1/16 font-bold md:font-normal'><ComplianceRequirementsStatusPie primaryDeadline={product.primary_deadline} requirementData={product.statistics} /></ListViewItem>
        <ListViewItem className={twMerge('hidden sm:flex w-full font-bold md:font-normal items-center', (canDelete || canRemove) ? 'md:w-3/16' : 'md:w-4/16 ')}>{deadline}</ListViewItem>
        {canRemove && <ListViewItem className='hidden sm:flex w-full md:w-1/16 font-bold md:font-normal justify-end'><RemoveIcon onClick={(e) => {
            props.removeFunction(product.product_id);
            preventDefaultAndStopPropagation(e);
        }} /></ListViewItem>}
        {canDelete && <ListViewItem className='hidden sm:flex w-full md:w-1/16 font-bold md:font-normal justify-end'>
            <DeleteConfirm
                trigger={<span onClick={(e) => preventDefaultAndStopPropagation(e)}><Popup trigger={<div className='p-1 hover:border-secondary rounded border-transparent border text-secondary '><RemoveIcon /></div>}><FormattedMessage id='compliance.products_list.click_to_delete_product' /></Popup></span>}
                deleteFunction={() => { dispatch(complianceDeleteProduct({ product_id: product.product_id })); }}
            />
        </ListViewItem>}
    </ListViewLine>
}
export default ComplianceProductsItem;
