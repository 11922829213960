import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { useIsMobileDevicePortrait } from '../../../../base/utils';
import { TextItem } from '../../../globals/components';
import { Inspection } from '../../types';
import SplitShipmentsInfo from '../shared/SplitShipmentsInfo';

type TotalQtyProps = {
    inspection: Inspection;
    isSubinspection?: boolean;
};
const TotalQty = (props: TotalQtyProps): React.ReactElement => {
    const intl = useIntl();
    const isMobile = useIsMobileDevicePortrait();
    const { inspection } = props;
    const hasPacked = inspection.packed_quantity !== null && inspection.packed_quantity > 0;
    const orderQty = (inspection.original_total_quantity !== null && inspection.original_total_quantity > 0) ? inspection.original_total_quantity : inspection.total_quantity;
    const showAvailable = hasPacked || (orderQty !== null && orderQty > 0 && inspection.original_total_quantity != inspection.total_quantity);
    const available = hasPacked ? inspection.packed_quantity : inspection.total_quantity;
    const isPartOfSplit = inspection.involved_in_split_shipment;
    const isSource = inspection.destination_split_shipments.length > 0;
    let label = showAvailable ? intl.formatMessage({ id: 'inspections.order_qty_packed' }) : intl.formatMessage({ id: 'inspections.order_qty_no_packed' });
    let originalPostfix = '';
    if (isPartOfSplit) {
        if (props.isSubinspection) {
            originalPostfix = '(Orig.)';
        }
        if (showAvailable) {
            label = intl.formatMessage({ id: 'inspections.original_qty_available' })
        }
        else {
            label = intl.formatMessage({ id: 'inspections.original_qty' })
        }
    }
    let trigger;
    if (orderQty && orderQty > 0) {
        trigger = showAvailable ? <span>{orderQty}{originalPostfix}/ {available}</span> : <span>{orderQty}{originalPostfix}</span>;
    }
    else {
        trigger = <div>{orderQty === null ? '-' : 0} {showAvailable && <Fragment>/ {available}</Fragment>}</div>;
    }
    const text =
        <Popup
            className=''
            hoverable={true}
            flowing
            on='hover'
            position='top center'
            trigger={trigger}
        >
            <Popup.Content>
                <div className=''>
                    {!isPartOfSplit && <p><FormattedMessage id={orderQty === null ? (showAvailable ? 'report.inspections_packed_no_total_qty' : 'report.inspections_packed_no_qty') : (showAvailable ? 'report.inspections_packed' : 'report.inspections_packed_no_qty')} values={{ packedQty: available, totalQty: orderQty }} /></p>}
                    {isPartOfSplit && <Fragment>
                        <SplitShipmentsInfo
                            showLoader
                            extraInfo={<FormattedMessage id={isSource ? 'report.inspections_split_source' : 'report.inspections_split_destination'} values={{ originalQty: orderQty, shipQty: available }} />}
                            inspection={inspection}
                            selectedInspectionId={inspection.inspection_id}
                            inline={true} />
                    </Fragment>}
                </div>
            </Popup.Content>
        </Popup>;
    if (!showAvailable && !props.isSubinspection) {
        return <TextItem column={isMobile} label={label} text={trigger} />
    }
    return (
        <Fragment>
            {props.isSubinspection ? text :
                <TextItem column={isMobile} label={label} text={text} />}
        </Fragment>
    );
};

export default TotalQty;
