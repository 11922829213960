import React, { ButtonHTMLAttributes } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { twMerge } from '../../utils';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    noBorder?: boolean;
    primary?: boolean;
    secondary?: boolean;
    negative?: boolean;
    alert?: boolean;
    link?: string;
    dataTestId?: string;
    border?: 1 | 2 | 4 | 8;
    behaveAsLink?: boolean;
    small?: boolean;
    medium?: boolean;
    large?: boolean;
    className?: string;
    
}

const Button = (props: ButtonProps): any => {
    const history = useHistory();
    const { negative, alert, className, children, style, onClick, onBlur, link, primary, secondary, small, medium, large, disabled, dataTestId, noBorder = false, border = 1, tabIndex, name, behaveAsLink = false, ...rest } = props;
    let bg = 'bg-surface-plain';
    let opacity = '';
    let textColor = 'text-primary';
    let textHoverColor = 'hover:text-primary';
    const borderColor = 'border-default';
    let textSize = 'text-sml';
    let padding = 'px-6 py-3';
    let borderItem = 'border';
    const cf = onClick ? disabled ? null : onClick : null;
    const gotoLink = (link: string): void => {
        history.push(link);
    }
    const clickAndLinkFunction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (cf) {
            cf(event);
        }
        if (link) {
            gotoLink(link);
        }
    }
    if (primary) {
        bg = 'bg-branding-brand';
        textColor = 'text-white';
        textHoverColor = 'hover:text-white';
    }
    if (secondary) {
        bg = 'bg-surface-secondary';
        textColor = 'text-white';
        textHoverColor = 'hover:text-white';
    }
    if (negative) {
        bg = 'bg-surface-danger';
        textColor = 'text-white';
        textHoverColor = 'hover:text-white';
    }
    if (alert) {
        bg = 'bg-surface-danger';
        textColor = 'text-white';
        textHoverColor = 'hover:text-white';
    }
    if (disabled) {
        opacity = 'opacity-75'
    }
    if (small) {
        textSize = 'text-sm';
        padding = 'px-3 py-1'
    }
    if (medium) {
        textSize = 'text-sm';
        padding = 'px-4 py-2'
    }
    if (large) {
        textSize = 'text';
        padding = 'px-8 py-4'
    }
    if (noBorder) {
        borderItem = 'no-border';
    }
    if (border && !noBorder) {
        const borderThickness = border === 1 ? 'border' : 'border-' + border;
        borderItem = borderColor + ' ' + borderThickness;
    }
    const cursor = disabled ? 'cursor-default' : 'cursor-pointer'
    const cn = twMerge(
        'outline-none rounded-md flex items-center px-4 py-3 hover:shadow-sm mr-1',
        borderItem,
        bg,
        textColor,
        textHoverColor,
        textSize,
        padding,
        opacity,
        behaveAsLink && 'behaveAsLink leading-none text-on-surface-plain',
        cursor,
        className,
    );
    if (behaveAsLink) {
        return <Link style={style} className={cn} onClick={disabled? null : clickAndLinkFunction} to={disabled ? null : link} data-test-id={dataTestId} tabIndex={tabIndex || 0} {...rest}>{children}</Link>
    }
    return <button style={style} className={cn} onClick={clickAndLinkFunction} data-test-id={dataTestId} tabIndex={tabIndex || 0} name={name} {...rest}>
    
        {children}
    </button>;

}
export default Button;
