import classnames from 'classnames';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Loader from '../../../base/components/Loader';
import Button from '../../../base/components/basic/Button';
import { getPrettyDate } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { isFetchingSelector } from '../../correctiveActions/selectors/correctiveActionSelectors';
import PageContainer from '../../globals/components/PageContainer';
import { ListView, ListViewHeader, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import { getComplianceRequirementTemplatesList } from '../complianceSlice';
import { getComplianceRequirementsSelector } from '../selectors/ComplianceSelectors';
import ComplianceTag from './ComplianceTag';


const ComplianceRequirementTemplateList = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const history = useHistory();

    const isFetching = useSelector(isFetchingSelector);
    const requirements = useSelector(getComplianceRequirementsSelector);
    const hasRequirements = requirements.length > 0;
    const openRequirementTemplate = (id: string) => {
        history.push('/compliance/requirements/' + id);
    }

    useEffect(() => {
        dispatch((getComplianceRequirementTemplatesList()));
    }, []);

    return <PageContainer header={<h2 className='pl-4 md:pl-0'><FormattedMessage id='compliance.requirements_list.title' /></h2>}>
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.compliance.requirements' })} />
        <ListView className=''>
            <Loader active={isFetching}>
                {hasRequirements && <ListViewHeader
                    className={classnames('top-0')}
                    items={[
                        { label: intl.formatMessage({ id: 'compliance.requirements_list.header.name' }), className: 'w-4/16', key: 'name' },
                        { label: intl.formatMessage({ id: 'compliance.requirements_list.header.description' }), className: 'w-6/16', key: 'description' },
                        { label: intl.formatMessage({ id: 'compliance.requirements_list.header.start_date' }), className: 'w-2/16', key: 'start_date' },
                        { label: intl.formatMessage({ id: 'compliance.requirements_list.header.tags' }), className: 'w-4/16', key: 'start_date' },
                    ]} />}
                {hasRequirements && <ListViewLines className='border border-b-0'>
                    {requirements.map((req, i) => {
                        return <ListViewLine onClick={() => openRequirementTemplate(req.requirement_id)} key={i} className='bg-white border-b md:border-b-0 cursor-pointer hover:bg-highlight-green py-4'>
                            <ListViewItem className='w-full md:w-4/16 font-bold md:font-normal whitespace-pre-line'>{req.requirement_name.text}</ListViewItem>
                            <ListViewItem className='w-full md:w-6/16 text-secondary md:text-primary whitespace-pre-line'>{req.requirement_description && req.requirement_description.text}</ListViewItem>
                            <ListViewItem className='w-full md:w-2/16 text-secondary md:text-primary'>{getPrettyDate(req.requirement_start_date)}</ListViewItem>
                            <ListViewItem className='w-full md:w-4/16 text-secondary md:text-primary leading-8 flex-wrap'>
                                {req.requirement_tags.map((tag, index) => <ComplianceTag onlyShowLast className='text-xs' key={'tag_' + tag.category_id + tag.path} tag={tag} index={index} isEditing={false} />)}
                            </ListViewItem>
                        </ListViewLine>
                    })}
                </ListViewLines>}
                {!hasRequirements && <div className='justify-center md:justify-start flex py-8 px-2 md:px-0'><FormattedMessage id='compliance.requirements_list.no_requirements_found' /></div>}
            </Loader>
        </ListView>
        <div className='flex justify-center md:justify-start'><Button primary onClick={() => history.push('/compliance/requirements/create')}><FormattedMessage id='compliance.requirements_list.create_template' /></Button></div>
    </PageContainer>
}
export default ComplianceRequirementTemplateList;
