import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import DatePicker3 from '../../../../base/components/basic/DatePicker3';
import { Sizes } from '../../../../base/components/types';
import { DEFAULT_DATE_FORMAT2 } from '../../../../base/config';
import Button, { ButtonTypes } from '../../../../base/ui/components/buttons/Button';
import Label from '../../../../base/ui/components/labels/Label';
import { ListItem, ListItems, MainActionEnum } from '../../../../base/ui/components/listitems/ListItem';
import QModal, { ActionTypes } from '../../../../base/ui/components/modals/Modal';
import { getPrettyDate, isUndefinedOrNull, twMerge } from '../../../../base/utils';
import { DetailedDocumentViewApprovalEnum, DetailedDocumentViewTimeframe } from '../../../../compliance_api/models';
import { useAppDispatch } from '../../../../store';
import { complianceSetDocumentExpiration, complianceSetDocumentStartDate } from '../../complianceSlice';

type Props = {
    className?: string;
    timeframe: DetailedDocumentViewTimeframe;
    documentId: string;
    orderName?: string;
    state: DetailedDocumentViewApprovalEnum;
    displayDatesAreNotValid?: boolean;
    onValidityUpdated(valid: boolean): void;
    onClose(): void;

};

const ComplianceDocumentValidity = (props: Props): React.ReactElement => {
    const { className, timeframe, documentId, orderName, onValidityUpdated, state, onClose, displayDatesAreNotValid = false } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const hasDates = () => {
        if (timeframe.type === 'no_expiration') {
            if (isUndefinedOrNull(timeframe.valid_after)) {
                return false;
            }
        }

        if (timeframe.type === 'interval') {
            if (isUndefinedOrNull(timeframe.valid_after) && isUndefinedOrNull(timeframe.valid_before)) {
                return false;
            };
        }

        return true;
    }
    const datesAreValid = () => {
        if (timeframe.type === 'order') {
            return true;
        }
        if (timeframe.type === 'no_expiration') {
            return !isUndefinedOrNull(timeframe.valid_after);
        }

        if (timeframe.type === 'interval') {
            return ((!isUndefinedOrNull(timeframe.valid_after) && !isUndefinedOrNull(timeframe.valid_before)));
        }
        return false;
    }

    const setExpirationDate = () => {
        // It is not possible to set expiration date, if timeframe.type is "no_expiration" or "order"
        dispatch(complianceSetDocumentExpiration({ document_id: documentId, expiration_date: validBefore }));
    }
    const setStartDate = () => {
        // It is not possible to set start date, if timeframe.type is "order"
        dispatch(complianceSetDocumentStartDate({ document_id: documentId, start_date: validAfter }))
    }
    const [showPickers, setShowPickers] = useState(!hasDates());
    const [validAfter, setValidAfter] = useState(null);
    const [validBefore, setValidBefore] = useState(null);
    const [displayNotValid, setDisplayNotValid] = useState(displayDatesAreNotValid || false);
    const [currentDateType, setCurrentDateType] = useState<'expiry' | 'start'>(null);
    const [resetApprovalModal, showResetApprovalModal] = useState(false);
    const setDates = () => {
        if (timeframe.type === 'no_expiration') {
            if (validAfter !== timeframe.valid_after) {
                setStartDate();
            }
        }

        if (timeframe.type === 'interval') {
            if (validBefore !== timeframe.valid_before) {
                setExpirationDate();
            }
            if (validAfter !== timeframe.valid_after) {
                setStartDate();
            }
        }
    }
    const getExpiryTypeLabel = () => {

        if (timeframe.type === 'no_expiration') {
            return "Validity";
        }
        if (timeframe.type === 'interval') {
            return "Validity"
        }
        if (timeframe.type === 'order') {
            return <span>Order</span>
        }
        return 'Unsupported Timeframe'
    }

    useEffect(() => {
        setDisplayNotValid(displayDatesAreNotValid);
    }, [displayDatesAreNotValid]);
    useEffect(() => {
        if (timeframe.type === 'interval') {
            setValidAfter(timeframe.valid_after);
            setValidBefore(timeframe.valid_before);
        }
        if (timeframe.type === 'no_expiration') {
            setValidAfter(timeframe.valid_after)
        }
        onValidityUpdated(datesAreValid());
    }, [timeframe]);


    const showNoExpirationDatePicker = timeframe && timeframe.type === 'no_expiration';

    const showIntervalDatePicker = timeframe && timeframe.type === 'interval';

    return <div className={twMerge('', className)}>
        {/* {JSON.stringify(validAfter)}
        {JSON.stringify(validBefore)}<br />
        hasDates: {JSON.stringify(hasDates())}<br />
        state: {JSON.stringify(state)} */}
        {/* displayDatesAreNotValid v: {JSON.stringify(displayDatesAreNotValid)}<br/>
        displayNotValid v: {JSON.stringify(displayNotValid)} */}

        <h4 className='mb-2'>{getExpiryTypeLabel()}</h4>

        <ListItems className={'border bg-default border-b-0 border-default items-center w-full rounded-md'}>
            {(displayNotValid && !datesAreValid()) && <ListItem className='bg-surface-attention'>
                <div className=''>
                    Please select a validity period for the document
                </div>
            </ListItem>}
            {timeframe.type === 'order' && <ListItem
                icon={'expiry_by-order'}
            ><span className='font-bold'>{orderName}</span>{getPrettyDate(timeframe.date)}</ListItem>}


            {timeframe.type === 'no_expiration' && <ListItem
                icon={'operation_pick-date'}
                className='flex'
                mainAction={!showPickers && { type: MainActionEnum.Edit, onMainActionClick: () => setShowPickers(true) }}
                inlineButton={showPickers && <Button size={Sizes.Small} primary onPress={() => {
                    setShowPickers(false);
                    if (state === DetailedDocumentViewApprovalEnum.Approved) {
                        showResetApprovalModal(true);
                    } else {
                        if (validAfter !== timeframe.valid_after) {
                            setStartDate();
                        }
                    }
                }}>Ok</Button>}
            >
                {showNoExpirationDatePicker && <>
                    {showPickers ? <div className='flex flex-col 2xl:flex-row gap-4'>
                        <div className='flex flex-col'>
                            <Label text='From' />
                            <DatePicker3
                                showClose={false}
                                maxDate={validBefore && new Date(validBefore)}

                                date={timeframe.valid_after} handleChange={(d) => {
                                    setValidAfter(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                    setCurrentDateType('start');
                                }} />
                        </div>
                    </div> : <div className='gap-2 flex'><span>{getPrettyDate(timeframe.valid_after)}</span></div>}
                </>}
            </ListItem>}

            {timeframe.type === 'interval' && <ListItem
                icon={'operation_pick-date'}
                className='flex'
                mainAction={!showPickers && { type: MainActionEnum.Edit, onMainActionClick: () => setShowPickers(true) }}
            /* inlineButton={showPickers && <div className='flex h-full items-end py-4'><Button 
                className='px-4'
                primary onPress={() => {
                setShowPickers(false);
                if (state === DetailedDocumentViewApprovalEnum.Approved) {
                    showResetApprovalModal(true);
                } else {
                    if (validBefore !== timeframe.valid_before) {
                        setExpirationDate();
                    }
                    if (validAfter !== timeframe.valid_after) {
                        setStartDate();
                    }
                }
            }}>Ok</Button></div>} */
            >
                {showIntervalDatePicker && <>
                    {showPickers ? <div className='flex flex-col 2xl:flex-row gap-4 items-end'>
                        <div className='flex flex-col'>
                            <Label text='From' />
                            <DatePicker3
                                showClose={false}
                                maxDate={validBefore && new Date(validBefore)}
                                
                                date={timeframe.valid_after} handleChange={(d) => {
                                    setValidAfter(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                    setCurrentDateType('start');
                                }} />
                        </div>
                        <div className='flex flex-col'>
                            <Label text='To' />
                            <DatePicker3
                                showClose={false}
                                minDate={validAfter && new Date(validAfter)}
                                date={timeframe.valid_before} handleChange={(d) => {
                                    setValidBefore(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                    setCurrentDateType('expiry');
                                }} /></div>
                        <Button
                            className='px-4'
                            primary onPress={() => {
                                setShowPickers(false);
                                if (state === DetailedDocumentViewApprovalEnum.Approved) {
                                    showResetApprovalModal(true);
                                } else {
                                    if (validBefore !== timeframe.valid_before) {
                                        setExpirationDate();
                                    }
                                    if (validAfter !== timeframe.valid_after) {
                                        setStartDate();
                                    }
                                }
                            }}>Ok</Button>
                    </div> : <div className='gap-2 flex'><FormattedMessage id='compliance.document.validity.document_valid' /> <span className='font-bold'>{getPrettyDate(timeframe.valid_after)}</span>-<span className='font-bold'>{getPrettyDate(timeframe.valid_before)}</span></div>}
                </>}
                {/* showPickers && <div className='flex h-full items-end py-4'><Button
                    className='px-4'
                    primary onPress={() => {
                        setShowPickers(false);
                        if (state === DetailedDocumentViewApprovalEnum.Approved) {
                            showResetApprovalModal(true);
                        } else {
                            if (validBefore !== timeframe.valid_before) {
                                setExpirationDate();
                            }
                            if (validAfter !== timeframe.valid_after) {
                                setStartDate();
                            }
                        }
                    }}>Ok</Button></div> */}
            </ListItem>}

        </ListItems>
        <QModal
            width={500}
            isOpen={resetApprovalModal}
            header='Reset approval'
            content={<>
                <FormattedMessage id='compliance.document.validity.change_date_and_remove_approval' />
            </>}
            actionItems={[
                {
                    type: ActionTypes.Action, text: 'Yes, reset approval', buttonType: ButtonTypes.Primary, event: () => {
                        setDates();
                        onClose();
                    }
                },
                { type: ActionTypes.CancelAction, text: 'Cancel', buttonType: ButtonTypes.Plain, event: () => showResetApprovalModal(false) },
            ]}
        />
    </div>
}
export default ComplianceDocumentValidity;
