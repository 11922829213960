import { UploadRequest } from '@navjobs/upload';
import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from 'typesafe-actions';
import uuidv1 from 'uuid/v1';
import { request } from '../../base/api';
import { SERVICES_PREFIX } from '../../base/config';
import { AppThunk } from '../../base/types';
import { objectHasKeys } from '../../base/utils';
import attachments from '../attachments';
import errorHandling from '../errorHandling';
import messages from '../messages/index';
import { updateUserPreferences } from '../users/actions/actions';
import { getComments as getCommentsSelector } from './selectors';
import * as types from './types';
import { CommentTarget } from './types';

const setAttachmentUploadProgress = attachments.actions.setAttachmentUploadProgress;
const addAttachmentSuccess = attachments.actions.addAttachmentSuccess;
const getAttachmentsSelector = attachments.selectors.getAttachments;
const clearUploads = attachments.actions.clearUploads;


const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;
const clearAllMessages = messages.actions.clearAllMessages;
const catchException = errorHandling.handler.catchException;

// Get all comments on the inspection
export const getComments = (inspectionId: string): AppThunk => {
    return (dispatch): Promise<void> => {
        dispatch(getCommentsRequest(true, inspectionId));
        return request('inspections/' + inspectionId + '/comments', {})
            .then((comments: { [commentId: string]: Comment[] }) => {
                dispatch(getCommentsSuccess(false, inspectionId, comments));
            })
    };
};

export type AddCommentFn = (config: AddCommentConfig) => Promise<any>
export interface AddCommentConfig {
    comment: string;
    id: string;
    type: CommentTarget;
    parentId: string;
    commentId?: string;
    imageUrl?: string;
    sendNotification: boolean;
}
export const addComment = (config: AddCommentConfig): any => {

    const comment = config.comment;
    const id = config.id;
    let type = config.type;
    const parentId = config.parentId;
    const commentId = config.commentId;
    const imageUrl = config.imageUrl;
    const sendNotification = config.sendNotification;


    return (dispatch, getState): Promise<void> => {
        if (type === 'defect_image') {
            type = 'defect';
        }
        if (type === 'checkpoint_image') {
            type = 'checkpoint';
        }
        const body: any = {
            comment,
            parent_id: parentId,
            send_notification: sendNotification,
        };
        const uploadedItems = getAttachmentsSelector(getState(), commentId, 'comments');
        const attachItems = [];
        if (uploadedItems.length > 0) {
            uploadedItems.forEach((item) => {
                if (typeof (item) === 'object' && item.url) {
                    attachItems.push(item);
                }
            });
            if (attachItems.length > 0) {
                body.attachments = attachItems;
            }
        }
        if (imageUrl) {
            body.image_url = imageUrl;
        }
        dispatch(addCommentRequest(id));
        return request(type + 's/' + id + '/comments', {
            method: 'post',
            body: JSON.stringify(body),
        }).then((data: { [commentId: string]: Comment[] }) => {
            dispatch(clearAllMessages());
            dispatch(sendStatusMessage(['status_message.comment_was_added_succesfully'], 5000));
            dispatch(clearUploads(commentId, 'comments', true));
            switch (type) {
                case 'inspection':
                    dispatch(addCommentSuccess(data));
                    break;
                case 'defect':
                    dispatch(addDefectCommentSuccess(data));
                    dispatch(addCommentSuccess(data));
                    break;
                default:
                    dispatch(addCommentSuccess(data));
            }
        }).catch((error) => {
            dispatch(addCommentFailure());
            dispatch(clearAllMessages());
            dispatch(sendErrorMessage(['error_message.error_the_comment_was_not_added']));
            catchException('addComment', {
                endpoint: type + 's/[id]/comments',
                request: type + 's/' + id + '/comments',
                status: error.status,
            }, { error, id, imageUrl, body: comment, method: 'POST' });
        });
    };
};

export function sendNotificationForUnsentCommentsWrapper(inspectionId: string) {
    return (dispatch): Promise<void> => sendNotificationForUnsentComments(dispatch, inspectionId)
}
export function sendNotificationForUnsentComments(dispatch: Dispatch, inspectionId: string): Promise<void> {
    const path = 'inspections/' + inspectionId + '/send_notification_for_unsent_comments';
    const config = {
        method: 'post',
        body: JSON.stringify({}),
    };

    return request(path, config)
        .then((data: { [commentId: string]: Comment[] }) => {
            console.log('sendNotificationForUnsentComments response:', data);
            dispatch(addCommentSuccess(data));
        })
        .catch((error) => {
            dispatch(sendErrorMessage(['error_message.error_could_not_notify_about_comments']));
            catchException('send_notification_for_unsent_comments', {
                endpoint: 'inspections/[id]/send_notification_for_unsent_comments',
                request: path,
                status: error.status,
            }, { error, inspectionId, body: {}, method: 'POST' });
        })
}

// Remove all comments from props - e.g. when leaving report page
export const clearComments = () => {
    return (dispatch): void => {
        dispatch(clearCommentsRequest());
    };
};

export const addCommentAttachment = (attachToType: string, attachedId: string, file: any) => {
    return (dispatch): void => {
        const uuid = uuidv1();
        const endpoint = SERVICES_PREFIX + '/upload/file/' + encodeURIComponent(file.name);
        const token = sessionStorage.getItem('id_token') || sessionStorage.getItem('url_token');
        const { error } = UploadRequest(
            {
                request: {
                    url: endpoint,
                    headers: {
                        'Content-Type': file.type,
                        'Authorization': 'Bearer ' + token,
                    },
                },
                files: [file],
                progress: (progress) => {
                    dispatch(setAttachmentUploadProgress(attachedId, attachToType, file, uuid, progress, 'ok'));
                },
                error: () => {
                    catchException('addCommentAttachment', {
                        endpoint: '/upload/file[file.name]',
                        request: '/upload/file/' + file.name,
                        status: error.status,
                    }, { error, attachedId, attachToType, file, uuid });
                },
                instance: () => null,
            },
        )
            .then((data: any) => {
                dispatch(addAttachmentSuccess(false, attachedId, attachToType, data.response));
            })
    };
};

export const removeCommentAttachment = (commentId: string, attachmentURL: string) => {
    return (dispatch): void => {
        dispatch(removeCommentAttachmentRequest(commentId, attachmentURL));
    };
};

export const showTranslatedComments = (show: boolean) => {
    return (dispatch: Dispatch<any>): void => {
        dispatch(setShowTranslatedCommentsRequest(show));
    };
};

export const translatedCommentsAvailable = () => {
    return (dispatch: Dispatch<any>, getState): void => {
        let hasTranslatedComment = false;
        const comments = getCommentsSelector(getState());
        if (objectHasKeys(comments)) {
            comments.root.forEach((comment: types.Comment) => {
                hasTranslatedComment = comment.translated_comment !== undefined;
                if (hasTranslatedComment) {
                    return;
                }
                if (comments[comment.id]) {
                    comments[comment.id].map((childComment: types.Comment) => {
                        hasTranslatedComment = childComment.translated_comment !== undefined;
                        if (hasTranslatedComment) {
                            return;
                        }
                    });
                }
            });
        }
        dispatch(hasTranslatedCommentsRequest(hasTranslatedComment));

    };
};
export const showTranslatedCommentsAsked = () => {
    return (dispatch: Dispatch<any>): void => {
        // TODO: When data is collected from endpoint requests show be wrapped in Promise.all
        const asked = localStorage.getItem('showTranslatedCommentsAsked');
        dispatch(setShowTranslatedCommentsRequest(Boolean(asked).valueOf()));
        const show = localStorage.getItem('showTranslatedComments');
        dispatch(showTranslatedCommentsAskedRequest(Boolean(show).valueOf()));
    };
};

export const storeShowTranslateOption = (show: boolean) => {
    return (dispatch: Dispatch<any>): void => {
        localStorage.setItem('showTranslatedCommentsAsked', 'true');
        localStorage.setItem('showTranslatedComments', Boolean(show).toString());
        dispatch(showTranslatedCommentsAskedRequest(true));
        dispatch(setShowTranslatedCommentsRequest(show));
        dispatch(sendStatusMessage(['comments.translations_settings_updated'], 3000));
        const body = { commentsShowAllTranslations: show };
        dispatch(updateUserPreferences(body));
    };
};

////////////////////////////
// Only action creators below
////////////////////////////

const getCommentsRequest = createAction(types.GET_COMMENTS_REQUEST, (isFetching: boolean, inspectionId: string) => {
    return {
        type: types.GET_COMMENTS_REQUEST,
        payload: {
            inspectionId,
            isFetching,
        },
    };
});
const getCommentsSuccess = createAction(types.GET_COMMENTS_SUCCESS, (isFetching: boolean, inspectionId: string, comments: { [commentId: string]: Comment[] }) => {
    return {
        type: types.GET_COMMENTS_SUCCESS,
        payload: {
            inspectionId,
            comments,
        },
    };
});
const clearCommentsRequest = createAction(types.CLEAR_COMMENTS);

const addCommentRequest = createAction(types.ADD_COMMENT_REQUEST, (commentId: string) => {
    return {
        type: types.ADD_COMMENT_REQUEST,
        payload: {
            commentId,
        },
    };
});

const addCommentSuccess = createAction(types.ADD_COMMENT_SUCCESS, (comments: { [commentId: string]: Comment[] }) => {
    return {
        type: types.ADD_COMMENT_SUCCESS,
        payload: {
            comments,
        },
    };
});
const addCommentFailure = createAction(types.ADD_COMMENT_FAILURE);

const addDefectCommentSuccess = createAction(types.ADD_DEFECT_COMMENT_SUCCESS, (comments: { [commentId: string]: Comment[] }) => {
    return {
        type: types.ADD_DEFECT_COMMENT_SUCCESS,
        payload: {
            comments,
        },
    };
});

const hasTranslatedCommentsRequest = createAction(types.HAS_TRANSLATED_COMMENTS, (hasTranslatedComments: boolean) => {
    return {
        type: types.HAS_TRANSLATED_COMMENTS,
        payload: {
            hasTranslatedComments,
        },
    };
});

const setShowTranslatedCommentsRequest = createAction(types.SHOW_TRANSLATED_COMMENTS, (showTranslated: boolean) => {
    return {
        type: types.SHOW_TRANSLATED_COMMENTS,
        payload: {
            showTranslatedComments: showTranslated,
        },
    };
});

const showTranslatedCommentsAskedRequest = createAction(types.SHOW_TRANSLATED_COMMENTS_ASKED, (asked: boolean) => {
    return {
        type: types.SHOW_TRANSLATED_COMMENTS_ASKED,
        payload: {
            asked,
        },
    };
});

const removeCommentAttachmentRequest =
    createAction(types.REMOVE_COMMENT_ATTACHMENT_REQUEST, (commentId: string, attachmentURL: string) => {
        return {
            type: types.REMOVE_COMMENT_ATTACHMENT_REQUEST, payload: {
                commentId,
                attachmentURL,
            },
        };
    });
