import { Pie } from '@nivo/pie';
import React from 'react';

type Props = {
    className?: string;
    data: { id: string, value: number, color: string }[] | {}[];
    showEmptyStatus?: boolean;
    w?: number;
    h?: number;
};

const StatusPie = (props: Props): React.ReactElement => {

    const { className, data, w, h, showEmptyStatus } = props;

    return <Pie
        borderWidth={0}
        data={data}
        tooltip={() => null}
        isInteractive={false}
        height={h ? h : 35} width={w ? w : 35} innerRadius={.5} enableArcLabels={false} enableArcLinkLabels={false} layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
        colors={{ datum: 'data.color' }} />;
}
export default StatusPie;
