import React from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { TextTable as TextTableType } from '../../../../../backend_api/models/TextTable';
import { getLocaleLanguage } from '../../../../../base/utils';
import { TextInputCell } from '../../../../../backend_api/models/TextInputCell';

type TextTableProps = {
    table: TextTableType;
};

const TextTable = (props: TextTableProps): React.ReactElement => {
    const intl = useIntl();
    const lang = getLocaleLanguage(intl.locale);
    const { table } = props;
    const rows = table.rows;
    const cols = table.columns;
    const values = table.values;
    const getValue = (r: number, c: number): TextInputCell => {
        return values.filter((value) => value.c === c && value.r === r)[0];
    };

    return (<div className='textTable'><Table unstackable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                {cols.map((col, i) => {
                    return <Table.HeaderCell key={'th_' + i}>{col.name[lang] || col.name.C}</Table.HeaderCell>
                })}
            </Table.Row>
        </Table.Header>
        {rows.map((row, r) => {
            return <Table.Row key={'row_' + r}>
                <Table.Cell>{row.name[lang] || row.name.C}</Table.Cell>
                {cols.map((col, c) => {
                    const val = getValue(r, c);
                    return <Table.Cell key={'value_' + r + c}>{val && val.value || '-'}</Table.Cell>
                })}
            </Table.Row>
        })}
    </Table></div>);
};

export default TextTable;
