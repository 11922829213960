import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { Location } from 'react-router-dom';
import { createSelector } from 'reselect';
import { BasicUser, DetailedSupplier, InspectionType, OrderAccessData, OrderListInspection, OrderListOrder, OrderListResponse, OrderListUser, Supplier } from '../../../backend_api/models';
import { request2 } from '../../../base/api';
import { setContextData } from '../../../base/baseSlice';
import { AppState, AppThunk, byId, Context } from '../../../base/types';
import { getLocationEntries } from '../../../base/utils';
import history from '../../../store/history';
import { catchException } from '../../errorHandling/handler';
import { Filter } from '../../filters/types';
import { parseFiltersObj } from '../../filters/util';
import { sendErrorMessage } from '../../messages/actions';
import { ProductionUnit } from '../../suppliers/types';
import { Inspection, InspectionsListMeta, InspectionsListState } from '../types';

let currentReqId = 'none';
export enum ListType {
    Inspections = 'inspections',
    DuplicateItems = 'duplicateItems',
}
type ListParams = { start: number; prPage: number; filters: Filter; listType?: ListType };
const defaultType = ListType.Inspections;

const getBody = (filters) => {
    let body;
    if (filters && filters.custom_field_values) {
        const f = filters.custom_field_values;
        const df = decodeURIComponent(f.toString());
        body = JSON.stringify({ custom_field_values: JSON.parse(df) });
    } else {
        body = null;
    }
    return body;
}
export const reloadFilteredList = (): AppThunk => {
    const location: Location = history.location;
    return async (dispatch, getState): Promise<void> => {
        const meta = getInspectionsListMetaDataSelector(getState());
        const filters = getLocationEntries(location);
        dispatch(reloadFilteredListDo({ start: meta.start, prPage: meta.per_page, filters }));
    }
}
export const reloadFilteredListDo = createAsyncThunk<OrderListResponse, ListParams>(
    'reloadFilteredListDo',
    async ({ start = 0, prPage = 20, filters }, { dispatch, rejectWithValue }) => {
        const url = getUrl(filters, start, prPage, true);
        const body = getBody(filters);
        const rq = await request2(url, { method: 'put', body });
        if (!rq.ok) {
            console.log('There was an error fetching the inspections list data...');
            dispatch(sendErrorMessage(['error_message.the_inspections_list_data_could_not_be_fetched'], 3000));
            catchException('getInspectionsList', {
                endpoint: 'orders?status|start|per_page|isFirst',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, url });
            rejectWithValue(rq as Response);
        }
        const done = await rq.json();
        return done;
    });
export const getFilteredListNew = createAsyncThunk<OrderListResponse, ListParams, {
    dispatch: Dispatch;
    state: OrderListResponse;
    rejectWithValue: Response;
}>(
    'getFilteredListNew',
    async ({ start = 0, prPage = 20, filters }, { dispatch, rejectWithValue }) => {
        const url = getUrl(filters, start, prPage, true);
        const body = getBody(filters);
        const rq = await request2(url, { method: 'put', body });
        if (!rq.ok) {
            console.log('There was an error fetching the new inspections list data...');
            if (rq.status === 414) {
                console.log('Error 414...')
                dispatch(sendErrorMessage(['error_message.the_inspections_list_data_could_not_be_fetched_too_many_filters_414']));
            }
            else {
                dispatch(sendErrorMessage(['error_message.the_inspections_list_data_could_not_be_fetched']));
            }

            catchException('getInspectionsList', {
                endpoint: 'orders?status|start|per_page|isFirst',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq.json(), url });
            rejectWithValue(rq as Response);
        }
        const data = await rq.json() as OrderListResponse;
        dispatch(setContextData({ context: Context.Inspections, metaData: { list: { total: data.actual_orders_count + data.start_position } } }))
        return data;
    });
export const getFilteredListAccessData = createAsyncThunk<OrderAccessData, { orderId: string }, {
    dispatch: Dispatch;
    state: OrderAccessData;
    rejectWithValue: Response;
}>(
    'getFilteredListAccessData',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'new_orders/' + params.orderId + '/access_data';
        const rq = await request2(url, {});
        if (!rq.ok) {
            console.log('There was an error fetching the new inspections list access data...');
            dispatch(sendErrorMessage(['error_message.the_inspections_list_access_data_could_not_be_fetched']));
            catchException('getFilteredListAccessData', {
                endpoint: '/orders_new/:orderId:/access_data',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq.json(), url });
            rejectWithValue(rq as Response);
        }
        return await rq.json();
    });

const initialState: InspectionsListState = {};
Object.values(ListType).forEach((type) => {
    initialState[type] = {
        accessData: undefined,
        status: '',
        requestId: '',
        filters: undefined,
        elements: [],
        per_page: 0,
        returned_for_page: 0,
        start: 0,
        total: 0,
        isFetching: false,
        error: false,
        defaultDate: undefined,
        scrollPosition: undefined,
        showCombineCollector: false,
        inspectionsForCombine: [],
        inspectionTypeSelectedForCombine: null,
        addInspectionForCombine: null,
        setShowCombineCollector: null,
        setInspectionTypeForCombine: null,
        removeInspectionForCombine: null,
        setListViewDefaultDate: null,
    }
});
const getUrl = (filters: any, start: number, prPage: number, isNew = false): string => {
    const params: any = {};
    const isFirst = start === 0;
    params.start = start;
    if (filters.search) {
        params.search = filters.search[0];
    }
    if (filters.status) {
        params.status = filters.status[0];
    }
    if (filters.date) {
        params.date = filters.date[0];
    }
    if (prPage) {
        params.per_page = prPage;
    }
    const query = parseFiltersObj(params, null);
    const filtersQuery: string = parseFiltersObj(filters, 'bracket', ['date', 'search', 'status', 'custom_field_values']);
    let url = isNew ? 'new_orders?' + query + '&isFirst=' + isFirst : 'orders?' + query + '&isFirst=' + isFirst;
    if (filtersQuery) {
        url += '&' + filtersQuery;
    }
    return url;
};

export const inspectionsListSlice = createSlice({
    name: 'inspectionsList',
    initialState,
    reducers: {
        // Plain reducer here:
        setListViewDefaultDate: (state, action: { payload: string }): void => { state[defaultType].defaultDate = action.payload },
        storeScrollPosition: (state, action: { payload: { pos: number; type?: ListType } }): void => { state[action.payload.type || defaultType].scrollPosition = action.payload.pos },
        setShowCombineCollector: (state, action: { payload: boolean }): void => { state[defaultType].showCombineCollector = action.payload },
        setInspectionTypeForCombine: (state, action: { payload: InspectionType }): void => { state[defaultType].inspectionTypeSelectedForCombine = action.payload },
        setInspectionsForCombine: (state, action: { payload: Inspection[] }): void => { state[defaultType].inspectionsForCombine = action.payload },
        addInspectionForCombine: (state, action: { payload: Inspection }): void => { state[defaultType].inspectionsForCombine.push(action.payload) },
        removeInspectionForCombine: (state, action: { payload: Inspection }): void => {
            state[defaultType].inspectionsForCombine = state[defaultType].inspectionsForCombine.filter(inspection => inspection.inspection_id !== action.payload.inspection_id)
        }
    },
    extraReducers: builder => {
        // Reducer responding to createAsyncThunk's:
        builder.addCase(reloadFilteredListDo.pending, (state, action) => {
            currentReqId = action.meta.requestId;
        });
        builder.addCase(getFilteredListNew.pending, (state, action) => {
            currentReqId = action.meta.requestId;
            const type = action.meta.arg.listType || ListType.Inspections;
            state[type].isFetching = true;
            state[type].error = false;
        });
        builder.addCase(getFilteredListNew.fulfilled, (state, action) => {
            getFilteredListFulfilledNew(state, action);
        });
        builder.addCase(reloadFilteredListDo.fulfilled, (state, action) => {
            getFilteredListFulfilledNew(state, action);
        });
        builder.addCase(getFilteredListAccessData.fulfilled, (state, action) => {
            const ac = state[ListType.Inspections].accessData;
            state[ListType.Inspections].accessData = Object.assign({}, ac, { [action.payload.order_id]: action.payload });
        });
    }
});
export type OrderListOrderExtended = OrderListOrder
    /* & { statusInspections: OrderListInspection[] } */
    & { inspections: OrderListInspectionExtended[] }
    & { inspectionsMap: byId<OrderListInspection[]> }
    & { inspectionsGrouped: byId<OrderListInspection[]> }
    & { inspectionsNoTypes: OrderListInspection[] }
    & { cnt: number }
    ;

export type OrderListInspectionExtended = OrderListInspection & Inspection
    & { supplier: Supplier }
    & { linked_production_unit: ProductionUnit }
    & { assigned_user: OrderListUser }
    & { inspection_type: InspectionType }
    & { locked: boolean }
    & { readonly: string[] }
    & { inspection_readonly_fields: string[] }
    ;
const getFilteredListFulfilledNew = (state: WritableDraft<InspectionsListState>, action: PayloadAction<OrderListResponse, string, { arg: ListParams; requestId: string }, never>): void => {
    const filters = action.meta.arg.filters;
    const status: string = filters['status'] && filters['status'][0] || '';
    const type = action.meta.arg.listType || ListType.Inspections;
    let goOn = true;
    if (currentReqId === 'none' || action.meta.requestId === currentReqId) {
        goOn = true;
    } else {
        goOn = false;
    }
    // If the type of order has changed, the results view should be cleared
    // - i.e. current elements shouldn't be merged with previous elements

    const data = action.payload;
    const orders: OrderListOrderExtended[] = data.orders as OrderListOrderExtended[];
    orders.forEach((order) => {
        const inspectionsGrouped = {};
        order.inspectionsNoTypes = [];
        const inspectionsMap: byId<OrderListInspection[]> = {};
        order.inspectionsMap = inspectionsMap;

        order.inspectionsGrouped = inspectionsGrouped;
        order.inspections.forEach((inspection: OrderListInspectionExtended) => {
            inspectionsMap[inspection.inspection_id] ? inspectionsMap[inspection.inspection_id].push(inspection) : inspectionsMap[inspection.inspection_id] = [inspection];
            inspection.conclusion = data.conclusions[inspection.conclusion_id];
            inspection.inspector_conclusion = data.conclusions[inspection.inspector_conclusion_id];
            inspection.assigned_user = data.inspectors[inspection.inspector_id] as BasicUser;
            inspection.assigned_user_id = inspection.inspector_id;
            inspection.inspection_type = data.inspection_types[inspection.inspection_type_id];
            inspection.supplier_entity = (data.suppliers[inspection.supplier_id] || null) as DetailedSupplier;
            inspection.linked_production_unit = data.production_units[inspection.linked_production_unit_id] as ProductionUnit;
            if (inspection.master_inspection) {
                inspection.master_inspection.id = inspection.master_inspection.inspection_id;
            }
            0
        })
        Object.keys(order.grouped_inspection_ids).map((id) => {
            inspectionsGrouped[id] = [];
            order.grouped_inspection_ids[id].map((groupId) => {
                inspectionsGrouped[id] = inspectionsGrouped[id].concat(inspectionsMap[groupId]);
            });
        });

        order.inspection_without_type_ids.map((id) => {
            order.inspectionsNoTypes = order.inspectionsNoTypes.concat(inspectionsMap[id]);
        })
        const cnt = Object.values(order.inspectionsGrouped).filter((v) => v.length > 0).length;
        order.cnt = cnt + order.inspectionsNoTypes.length;
    })
    if (goOn) {
        const typeChanged = action.type === 'reloadFilteredListDo/fulfilled' || state[type].status !== status || (filters['search'] !== undefined && action.payload.start_position === 0) || action.payload.start_position === 0;
        if (!typeChanged) {
            state[type].elements = state[type].elements.concat(orders)
        } else {
            state[type].elements = orders;
        }
        state[type].per_page = action.payload.requested_orders_count;
        state[type].start = action.payload.start_position;
        state[type].filters = filters;
        state[type].isFetching = false;
        state[type].status = status;
        state[type].total = (action.payload.start_position + action.payload.actual_orders_count) || 0;
        state[type].returned_for_page = action.payload.actual_orders_count || 0;
        state[type].error = false;
        state[type].requestId = action.meta.requestId;
    }


}

// Selectors
export const inspectionsListFetchingSelector = createSelector(
    [(state: AppState, listType?: ListType): boolean => state.app.inspectionsList[listType || defaultType].isFetching],
    (isFetching) => isFetching,
);
export const showCombineCollectorSelector = createSelector(
    [(state: AppState, listType?: ListType): boolean => state.app.inspectionsList[listType || defaultType].showCombineCollector],
    (show) => show,
);
export const getInspectionsSelectedForCombineSelector = createSelector(
    [(state: AppState, listType?: ListType): Inspection[] => state.app.inspectionsList[listType || defaultType].inspectionsForCombine],
    (inspections) => inspections,
);
export const getTypeSelectedForCombineSelector = createSelector(
    [(state: AppState, listType?: ListType): InspectionType => state.app.inspectionsList[listType || defaultType].inspectionTypeSelectedForCombine],
    (type) => type,
);
export const getInspectionsListDataSelector = createSelector(
    [(state: AppState, listType: ListType): InspectionsListState[string] => state.app.inspectionsList[listType || defaultType]],
    (data) => data,
);
export const getInspectionsListMetaDataSelector = createSelector(
    [(state: AppState, listType?: ListType): InspectionsListMeta => {
        const data = state.app.inspectionsList[listType || defaultType];
        return { start: data.start, per_page: data.per_page, total: data.total, isFetching: data.isFetching };
    }],
    (data) => data,
);

export const getListViewDefaultDateSelector = createSelector(
    [(state: AppState, listType?: ListType): string => state.app.inspectionsList[listType || defaultType].defaultDate],
    (defaultDate) => defaultDate,
);
export const getListViewScrollPositionSelector = createSelector(
    [(state: AppState, listType?: ListType): number => state.app.inspectionsList[listType || defaultType].scrollPosition],
    (number) => number,
);
export const getOrderAccessDataSelector = createSelector(
    [(state: AppState, orderId: string) => state.app.inspectionsList[defaultType].accessData && state.app.inspectionsList[defaultType].accessData[orderId]],
    (accessData) => accessData,
);
const { actions, reducer } = inspectionsListSlice;
export const { setListViewDefaultDate, setShowCombineCollector, setInspectionTypeForCombine, setInspectionsForCombine, addInspectionForCombine, removeInspectionForCombine, storeScrollPosition } = actions;
export default reducer;
