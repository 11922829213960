import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Popup } from 'semantic-ui-react';
import { Audit } from '../../../backend_api/models';
import { useAppDispatch } from '../../../store';
import { InfoIcon } from '../../globals/components/Utils';
import { isAuditUnlockable } from '../../inspections/Utils';
import { InspectorSelector } from '../../users/components';
import { unlockAudit, updateAuditAuditor } from '../slices/auditSlice';

type UnlockType = 'unlock' | 'unlockOrReassign' | 'reassign';
type UnlockAuditProps = {
    audit: Audit;
    type?: UnlockType;
    children?: React.ReactElement;
};

const UnlockAudit = (props: UnlockAuditProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { type, children, audit } = props;
    const [show, setShow] = useState(false);
    const [reassignInspector, setReassignInspector] = useState(null);
    const canReassign = true;
    const canUnlock = true;
    const cancel = (): void => {
        setReassignInspector(null);
        setShow(false);
    };
    const unlock = (): void => {
        dispatch(unlockAudit({ auditId: audit.id }));
    };
    const reassign = (reassignToUserId: string): void => {
        dispatch(updateAuditAuditor({ audit_id: audit.id, user_id: reassignToUserId }))
    };
    const getPopup = (): React.ReactElement => {
        let content = { unlock: false, reassign: false, headerText: '', mainText: undefined };
        if (type === 'reassign') {
            content = {
                unlock: false,
                reassign: true,
                headerText: intl.formatMessage({ id: 'audits.unlock.audit_inprogress' }),
                mainText: <p><FormattedMessage id='audits.unlock.reassign_audit' /></p>
            };
        }
        if (type === 'unlockOrReassign') {
            content = {
                unlock: true,
                reassign: true,
                headerText: intl.formatMessage({ id: 'audits.unlock.audit_downloaded' }),
                mainText: <p><InfoIcon inline compact noMargins /><FormattedMessage id='audits.unlock.unlock_or_reassign_audit' /></p>
            };
        }


        return <Popup
            on='click'
            open={show}
            onOpen={(): void => setShow(true)}
            onClose={(): void => setShow(false)}
            trigger={children}
            hoverable={true}
            position='top center'
            hideOnScroll={false}
            closeOnDimmerClick={false}
            closeOnPortalMouseLeave={false}
            closeOnTriggerMouseLeave={false}
            closeOnDocumentClick={true}

        >
            <Popup.Header>
                <h3>{content.headerText}</h3>
            </Popup.Header>
            <Popup.Content>
                <div className='mb-4'>
                    {content.mainText}
                </div>
                {content.unlock && <div className='mb-4'>
                    <Button className='' size='mini' onClick={(): void => unlock()}><FormattedMessage id='audits.reassign.label_unlock' /></Button>
                </div>
                }
                {content.reassign && <Fragment>
                    <div className='mb-4'>
                        <div className='text-secondary text-sm mb-1'><FormattedMessage id='audits.reassign.label_current_inspector' /></div>
                        <InspectorSelector
                            selectedInspector={reassignInspector || audit.assigned_user && audit.assigned_user.id}
                            id={audit.id}
                            handleChange={(item): void => {
                                setReassignInspector(item.value);
                            }}
                            showEmailInSelector={false}
                            showExpanded={false}
                        />
                    </div>
                    <Button size='mini' disabled={!reassignInspector} onClick={(): void => reassign(reassignInspector)}><FormattedMessage id='audits.reassign.label_reassign' /></Button>
                    <Button size='mini' onClick={(): void => cancel()}><FormattedMessage id='globals.cancel' /></Button></Fragment>}
            </Popup.Content>
        </Popup>;
    }
    if (isAuditUnlockable(audit) && type === 'unlockOrReassign' && canUnlock) {
        return getPopup();
    }
    if (audit.status === 'ongoing' && type === 'reassign' && canReassign) {
        return getPopup();
    }
    return children;
}
export default UnlockAudit;
