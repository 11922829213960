import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { FileResource } from '../../../backend_api/models';
import { useAppDispatch } from '../../../store';
import AttachmentItemList from '../../attachments/components/AttachmentItemList';
import FileUpload from '../../files/components/FileUpload';
import { addAttachmentToAudit, addAttachmentToAuditReport, removeAuditAttachment } from '../slices/auditSlice';

type Props = {
    className?: string;
    disabled?: boolean;
    auditId: string;
    attachments: FileResource[];
    isReport?: boolean;
    noHeading?: boolean;
};

const AuditAttachments = (props: Props): React.ReactElement => {
    const { className, auditId, attachments, isReport, noHeading, disabled } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    return <div className={twMerge('w-full', className)}>
        {!noHeading && <h4><FormattedMessage id='audit.attachments.heading' /> ({attachments.length})</h4 >}
        <FileUpload
            disabled={disabled}
            autoClearAfterUpload={true}
            returnMultipleWhenAllDone
            inline={true}
            fileSelectorShowIcon={true}
            compact={false}
            showCompleteMessage={true}
            fileSelectorLabelText={intl.formatMessage({ id: 'audit.attachments.click_to_add_or_drop' })}
            fileSelectorLabelClass='py-4 flex justify-center cursor-pointer'
            uploadComplete={(f: any, files) => dispatch(isReport ? addAttachmentToAuditReport({ auditId, attachments: files as FileResource[] }) : addAttachmentToAudit({ auditId, attachments: files as FileResource[] }))}
        >
            {attachments.length === 0 && <div className='flex w-full'><FormattedMessage id='audit.attachments.no_attachments' /></div>}
            <AttachmentItemList disabled={disabled} attachments={attachments} deleteFunction={(id) => dispatch(removeAuditAttachment({ auditId, attachmentId: id, isReport }))} className='space-y-4' />
        </FileUpload >
    </div>
}
export default AuditAttachments;
