import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListItem, ListItems } from '../../../../base/ui/components/listitems/ListItem';
import { twMerge } from '../../../../base/utils';
import { DetailedDocumentAppliesToProduct, DetailedDocumentAppliesToSupplier, DetailedDocumentViewAppliesTo } from '../../../../compliance_api/models';

type Props = {
    className?: string;
    appliesTo: DetailedDocumentViewAppliesTo;
    products: DetailedDocumentAppliesToProduct[];
    suppliers: DetailedDocumentAppliesToSupplier[];
};

const ComplianceDocumentCoveredProducts = (props: Props): React.ReactElement => {
    const { className, products, suppliers, appliesTo } = props;

    return <div className={twMerge('', className)}>
        <h4 className='mb-2'><FormattedMessage id={appliesTo.type === 'applies_to_products' ? 'compliance.document_viewer.covered_products': 'compliance.document_viewer.covered_suppliers'}/></h4>
        <div className={'bg-default'}>
            {products && products.length > 0 && <ListItems className={'border border-default border-b-0 rounded-md'}>
                {products.map((product) => {
                    return <ListItem className='' thumbnail={product.image} >{product.name}</ListItem>
                })}
            </ListItems>}
            {appliesTo.type === 'applies_to_supplier' &&  <ListItems className={'border border-default border-b-0  rounded-md'}>
                <ListItem icon={'user_supplier'} >{appliesTo.supplier_name}</ListItem>                
            </ListItems>}
        </div>
    </div>
}
export default ComplianceDocumentCoveredProducts;
