import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Comment, Divider } from 'semantic-ui-react';
import CommentInput from '../../../base/components/comments/CommentInput';
import { useAppDispatch } from '../../../store';
import { addCommentToCorrectiveAction, getCorrectiveActionHistory } from '../correctiveActionsSlice';
import { getHistorySelector, isFetchingHistorySelector } from '../selectors/correctiveActionSelectors';
import CorrectiveActionEvent from './CorrectionActionEvent';

type Props = {
    correctiveActionId: string
}

const CorrectiveActionHistory = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const isFetching = useSelector(isFetchingHistorySelector)
    const history = useSelector(getHistorySelector);
    useEffect(() => {
        props.correctiveActionId && dispatch(getCorrectiveActionHistory(props.correctiveActionId));
    }, [props.correctiveActionId])
    const newComment = (comment: string) => {
        dispatch(addCommentToCorrectiveAction({ correctiveActionId: props.correctiveActionId, comment: comment }))
    }
    return <Fragment>
        <Fragment>
            <CommentInput handleNewComment={newComment} showUpload={false} />
            <Comment.Group>
                {history.history.map((event) => {
                    return (<Fragment key={event.id}>
                        <Divider />
                        <CorrectiveActionEvent event={event} />
                    </Fragment>)
                })}
            </Comment.Group>
        </Fragment>
    </Fragment>
}

export default CorrectiveActionHistory;