import * as React from "react";
import uuidv1 from 'uuid/v1';
import { twMerge } from "tailwind-merge";
import Icon from "../../components/basic/Icon";

export enum TextAreaHeight {
    DEFAULT_FIXED = 'default-fixed',
    DEFAULT_AUTO_EXPAND = 'default-auto-expand',
    SINGLE_LINE_AUTO_EXPAND = 'single-line-auto-expand'
}

export enum FieldActionType {
    DEFAULT = 'default',
    DESTRUCTABLE = 'destructable'
}

export enum FieldActionSize {
    BASE = 'text-base',
    SMALL = 'text-sm',
    EXTRA_SMALL = 'text-xs'
}

type TextAreaProps = {
    value?: string;
    onChange: (newValue: string, element?) => void;
    onFocus?: () => void;
    onBlur?: (newValue: string) => void;
    autoFocus?: boolean;
    immutable?: boolean;
    className?: string;
    placeholder?: string;
    height: TextAreaHeight;
    label?: string;
    labelIcon?: string;
    action?: () => void;
    actionType?: FieldActionType;
    actionSize?: FieldActionSize;
    actionLabel?: string;
    actionIconRight?: string;
    actionIconLeft?: string;
    errorMessage?: string
};

export function TextArea(props: TextAreaProps) {
    const {value, onChange, onFocus, onBlur, immutable, autoFocus, className, placeholder, height, label, labelIcon, action, actionType, actionSize, actionLabel, actionIconRight, actionIconLeft, errorMessage} = props;

    const elementId = uuidv1();
    const elementRef = React.useRef<HTMLTextAreaElement>(null);
    const submittedValue = React.useRef(value);

    const defaultHeight = height == TextAreaHeight.SINGLE_LINE_AUTO_EXPAND ? 37 : 74;
    const shouldAutoExpand = height == TextAreaHeight.DEFAULT_AUTO_EXPAND || height == TextAreaHeight.SINGLE_LINE_AUTO_EXPAND;


    React.useEffect(() => {
        const timer = requestAnimationFrame(() => {
            if(shouldAutoExpand) {
                adjustTextAreaHeight(elementRef.current);
            }
        });
        return () => {
            clearTimeout(timer);
        };
    }, [value]);

    function maybeAdjustHeightOnEvent(ev) {
        if(shouldAutoExpand) {
            adjustTextAreaHeight(ev.target);
        }
    }

    const shownValue = value === undefined ? "" : value === null ? "" : value;

    return (
        <div>
            <div className={`flex flex-row ${label ? 'justify-between' : 'justify-end'}`}>
                <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-start text-sm text-input-label pb-1">
                        { labelIcon && <Icon className='pr-1' name={labelIcon} />}
                        { label && <label className="mb-0" htmlFor={elementId}>{label}</label>}
                    </div>
                    {
                        errorMessage &&
                        <div className="flex flex-row text-danger">
                            <Icon className='pr-1 text-sm' name='error' />
                            <div className="text-sm">{errorMessage}</div>
                        </div>
                    }
                </div>
                {
                    action && 
                    <button onClick={() => action?.()} className={`flex flex-row shrink-0 items-end ${actionSize} ${actionType == FieldActionType.DESTRUCTABLE ? 'text-danger' : 'text-brand'}`}>
                        {actionIconLeft && <Icon className="pr-1 self-end" name={actionIconLeft} />}
                        <span>{actionLabel}</span>
                        {actionIconRight && <Icon className="pl-1 self-end" name={actionIconRight} />}
                    </button>
                }
            </div>
            
            <textarea
                rows={1}
                id={elementId}
                className={twMerge(className, "px-4 overflow-hidden border resize-none text-primary")}
                style={{height: defaultHeight + "px", maxHeight: '350px', minHeight: defaultHeight + "px"}}
                ref={elementRef}
                disabled={immutable}
                autoFocus={autoFocus}
                onChange={(ev) => {
                    const text = ev.target.value;
                    if(!hasNonPrintableCharacters(text)) {
                        onChange?.(text);
                    }
                }}
                onClick={() => elementRef?.current?.focus()}
                onFocus={(ev) => {
                    onFocus?.();
                    maybeAdjustHeightOnEvent(ev);
                }}
                onBlur={(ev) => {
                    if (!ev || !ev.target) {
                        return;
                    }

                    onBlur?.(ev.target.value);
                }}
                onKeyDown={maybeAdjustHeightOnEvent}
                onKeyUp={maybeAdjustHeightOnEvent}
                onInput={maybeAdjustHeightOnEvent}
                value={shownValue}
                placeholder={placeholder}
            />
        </div>
        
    );
}

function hasNonPrintableCharacters(text: string): boolean {
    // 0 to 31 are control characters
    // 32 is space
    // 127 - 159 are control characters
    // 160 is a non breaking space
    // 10 is a line break
    // 13 is CR - carriage return
    return text.split("").some(char => {
        const code = char.charCodeAt(0);
        if(code == 10 || code == 13) {
            return false;
        }
        if(code >= 0 && code < 31) {
            return true;
        }
        if(code >= 127 && code <= 160) {
            return true;
        }
        return false;
    }); 
}

function adjustTextAreaHeight(o) {
    if (!o) {
        return;
    }

    o.style.height = "";
    o.style.height = o.scrollHeight + "px";
}